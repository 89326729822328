import React, { useMemo } from "react";
import "../../assets/scss/terms/TermsComponent.scss";
import CustomCheckBox from "../customButton/CustomCheckBox";
import { TERMS } from "../../assets/js/TERMS/terms_v5";
const TermsComponent = ({
  checkboxChange,
  allAgree,
  termsOfUse,
  privacy,
  marketing_agree,
  privacyAllAgree,
  shinhan,
  withus,
  postMNC,
  ksjCompany,
  koreana,
  merchant,
  dbSonHae,
  oceanBeach
}) => {
  const terms = useMemo(() => TERMS.terms, []);
  return (
    <div className="agr_sec_all">
      <CustomCheckBox
        checked={allAgree}
        onChange={checkboxChange}
        id="0"
        name="전체동의 (선택 포함)"
      />
      <hr style={{ margin: "0px 0px 6px", border: "1px solid gray" }} />

      <CustomCheckBox
        checked={termsOfUse}
        onChange={checkboxChange}
        id="1"
        name={terms[0].title}
      />
      <div className="agr_title1">
        <div className="toggle_sec_all">
          <div
            className="term_container"
            dangerouslySetInnerHTML={{ __html: terms[0].description }}
          ></div>
        </div>
      </div>
      <CustomCheckBox
        checked={privacy}
        onChange={checkboxChange}
        id="2"
        name={terms[1].title}
      />
      <div className="agr_title1">
        <div className="toggle_sec_all">
          <div
            className="term_container"
            dangerouslySetInnerHTML={{ __html: terms[1].description }}
          ></div>
        </div>
      </div>
      <CustomCheckBox
        checked={marketing_agree}
        onChange={checkboxChange}
        id="3"
        name={terms[2].title}
      />
      <div className="agr_title1">
        <div className="toggle_sec_all">
          <div
            className="term_container"
            dangerouslySetInnerHTML={{ __html: terms[2].description }}
          ></div>
        </div>
      </div>
      <CustomCheckBox
        checked={privacyAllAgree}
        onChange={checkboxChange}
        id="4"
        name="개인정보 제3자 제공(유상) 전체동의 (선택)"
      />

      <div className="agr_title1">
        <div className="toggle_sec_all">
          <div className="term_container">
            <CustomCheckBox
              checked={shinhan}
              onChange={checkboxChange}
              id="5"
              name={terms[3].title + " (선택)"}
            />
            <div
              className="term_container"
              dangerouslySetInnerHTML={{ __html: terms[3].description }}
            ></div>
            <CustomCheckBox
              checked={withus}
              onChange={checkboxChange}
              id="6"
              name={terms[4].title + " (선택)"}
            />
            <div
              className="term_container"
              dangerouslySetInnerHTML={{ __html: terms[4].description }}
            ></div>
            <CustomCheckBox
              checked={postMNC}
              onChange={checkboxChange}
              id="7"
              name={terms[5].title + " (선택)"}
            />
            <div
              className="term_container"
              dangerouslySetInnerHTML={{ __html: terms[5].description }}
            ></div>
            <CustomCheckBox
              checked={ksjCompany}
              onChange={checkboxChange}
              id="8"
              name={terms[6].title + " (선택)"}
            />
            <div
              className="term_container"
              dangerouslySetInnerHTML={{ __html: terms[6].description }}
            ></div>
            <CustomCheckBox
              checked={koreana}
              onChange={checkboxChange}
              id="9"
              name={terms[7].title + " (선택)"}
            />
            <div
              className="term_container"
              dangerouslySetInnerHTML={{ __html: terms[7].description }}
            ></div>
            <CustomCheckBox
              checked={merchant}
              onChange={checkboxChange}
              id="10"
              name={terms[8].title + " (선택)"}
            />
            <div
              className="term_container"
              dangerouslySetInnerHTML={{ __html: terms[8].description }}
            ></div>
            <CustomCheckBox
              checked={dbSonHae}
              onChange={checkboxChange}
              id="11"
              name={terms[9].title + " (선택)"}
            />
            <div
              className="term_container"
              dangerouslySetInnerHTML={{ __html: terms[9].description }}
            ></div>
            <CustomCheckBox
              checked={oceanBeach}
              onChange={checkboxChange}
              id="12"
              name={terms[10].title + " (선택)"}
            />
            <div
              className="term_container"
              dangerouslySetInnerHTML={{ __html: terms[10].description }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(TermsComponent);
