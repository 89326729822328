import React from "react";
import "../../assets/scss/custom/CustomCheckBox.scss";
const CustomCheckBox = ({ name, id, onChange, checked }) => {
  return (
    <div className="check__line">
      <input
        id={id}
        type="checkbox"
        onChange={e => onChange(e, id, name)}
        checked={checked}
      />
      <label htmlFor={id}>
        <span>{name}</span>
      </label>
    </div>
  );
};
export default React.memo(CustomCheckBox);
