// 함수형 프로그래밍
import { AES, enc } from "crypto-js";
// _isObject
export const _isObject = obj => {
  return typeof obj === "object" && !!obj;
};

// _keys,  객체의 key값 배열로 반환
export const _keys = obj => {
  return _isObject(obj) ? Object.keys(obj) : [];
};

// _values, 객체의 value값을 배열로 반환
export const _values = obj => {
  return _isObject(obj) ? Object.values(obj) : [];
};

export const makeObj = (obj, ...props) => {
  let _obj = {};
  for (let i = 0; i < props.length; i++) {
    _obj[props[i]] = obj[props[i]];
  }
  return _obj;
};

// 반복문
export const _each = (list, iter) => {
  let _list = [];
  let keys = _keys(list);
  for (let i = 0, len = keys.length; i < len; i++) {
    iter(list[keys[i]]);
  }
  return _list;
};

// 마지막 문자열 공백제거
export const _finalTrim = str => {
  return str.trim();
};

// type과 compare type이 같은 경우 true
export const typeCheck = (type, compare) => {
  if (type == compare) {
    return true;
  }
  return false;
};

// props가 null or undefined인 경우 = false 반환,
export const checkNullOrUndefined = props => {
  if (props == undefined || props == null) {
    return false;
  }
  return true;
};

// type이 String인 문자열이 들어왔을 경우 빈문자열이면 false반환
export const checkEmptyStr = str => {
  if (str.length === 0) {
    return false;
  }
  return true;
};

// 값이 같으면 true , 아니면 false
export const compareValue = (prev, next) => {
  if (prev === next) {
    return true;
  }
  return false;
};

//날짜변환
export const toChange = data => {
  const inputDate = new Date(data);

  const year = inputDate.getFullYear();
  const month = inputDate.getMonth() + 1;
  const date = inputDate.getDate();
  const hours = inputDate.getHours();
  const minutes = inputDate.getMinutes();
  const seconds = inputDate.getSeconds();

  const outputDate = `${year}-${month.toString().padStart(2, "0")}-${date
    .toString()
    .padStart(2, "0")} ${hours.toString().padStart(2, "0")}시${minutes
    .toString()
    .padStart(2, "0")}분${seconds.toString().padStart(2, "0")}초`;

  return outputDate;
};

export const toChange2 = data => {
  const inputDate = new Date(data);

  const year = inputDate.getFullYear();
  const month = inputDate.getMonth() + 1;
  const date = inputDate.getDate();

  const outputDate = `${year}-${month.toString().padStart(2, "0")}-${date
    .toString()
    .padStart(2, "0")}`;

  return outputDate;
};

const curry =
  f =>
  (a, ..._) =>
    _.length ? f(a, ..._) : (..._) => f(a, ..._);

const reduce = curry((f, acc, iter) => {
  if (!iter) {
    iter = acc[Symbol.iterator]();
    acc = iter.next().value;
  }
  for (const a of iter) {
    acc = f(acc, a);
  }
  return acc;
});

export const _map = curry((f, iter) => {
  let res = [];
  for (const a of iter) {
    res.push(f(a));
  }
  return res;
});

export const go = (...args) => reduce((a, f) => f(a), args);
export const queryStr = obj => {
  return go(
    obj,
    Object.entries,
    _map(([k, v]) => `${k}=${v}`),
    reduce((a, b) => `${a}&${b}`)
  );
};

export const isEmpty = obj => Object.keys(obj).length === 0;

export function encrypt(data, key) {
  const dataWA = enc.Utf8.parse(data);
  const keyWA = enc.Utf8.parse(key);
  const ivWA = enc.Utf8.parse(key.substring(0, 16));

  const cipher = AES.encrypt(dataWA, keyWA, { iv: ivWA });
  return cipher.ciphertext.toString(enc.Base64url); // url query 파라미터 대응하기 위해 일반 base64 가 아닌 base64url 로 변환
}

export function decrypt(encData, key) {
  try {
    const keyWA = enc.Utf8.parse(key);
    const ivWA = enc.Utf8.parse(key.substring(0, 16));

    const cipher = AES.decrypt(
      enc.Base64url.parse(encData.replace(/=/gi, "")).toString(enc.Base64),
      keyWA,
      { iv: ivWA }
    ); // 해쉬된 값이 url encode 일 수도 있으므로 base64url 로 파싱하고 다시 base64 로 인코딩
    return cipher.toString(enc.Utf8);
  } catch (err) {
    console.log(err);
    alert("복호화중 오류가 발생했습니다. \n 다시시도해주세요.");
    navigate("/");
  }
}
