import React from "react";
import SwiperSlider from "./SwiperSlider";

function App() {
  return (
    <div>
      <SwiperSlider />
    </div>
  );
}

export default React.memo(App);
