import React, { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/scss/AdPopCornSignForm.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import withAuth from "../../hoc/auth";
import { useDispatch } from "react-redux";
import { UserService } from "../../service/UserService";
import InputComponent from "../customButton/InputComponent";
import {
  confirmPasswordCheck,
  emailCheck,
  idCheck,
  passwordCheck,
} from "../../assets/js/util/check";
import { _finalTrim, _keys, encrypt } from "../../assets/js/util/util";
import SignPopup from "../login/SignPopup";
import axios from "axios";
import Loader from "../loader/LoaderComponent";
import HelmetComponent from "../header/HelmetComponent";
import PostCodeModal from "../login/PostCodeModal";
import TermsComponent from "../Terms/TermsComponent";
import { curVersion } from "../../assets/js/TERMS/terms_version";

function AdPopCornSignForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [tid, setTid] = useState("");
  const [campaignkey, setCampaignKey] = useState("");

  const [aesKey, setAesKey] = useState("");
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setconfirmPassword] = useState("");
  const [email, setEmail] = useState("");

  const [allAgree, setAllAgree] = useState(false);
  const [termsOfUse, setTermOfUse] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [marketing_agree, setMarketingAgree] = useState(false);

  const [showPopup, setShowPopup] = useState(false);

  const [privacyAllAgree, setPrivacyAllAgree] = useState(false);
  const [shinhan, setShinHan] = useState(false);
  const [withus, setWithUs] = useState(false);
  const [postMNC, setPostMNC] = useState(false);
  const [ksjCompany, setKSJCompany] = useState(false);
  const [koreana, setKoreana] = useState(false);
  const [merchant, setMerchant] = useState(false);
  const [dbSonHae, setDbSonHae] = useState(false);

  const [authType, setAuthType] = useState("");
  const [name, setName] = useState("");
  const [birthday, setBirthDay] = useState("");
  const [di, setDi] = useState("");
  const [nationalinfo, setNationalInfo] = useState("0");
  const [mobileno, setMobileno] = useState("");
  const [mobileco, setMobileco] = useState("");
  const [gender, setGender] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [clickStatus, setClickStatus] = useState(false);
  const [showAddrModal, setShowAddrModal] = useState(false);

  const [selectAddr, setSelectAddr] = useState({});

  const idRef = useRef();
  const passwordRef = useRef();
  const confirmpasswordRef = useRef();
  const emailRef = useRef();

  useEffect(() => {
    // check if user is already authenticated
    const checkUserAuth = async (di, key, name, mobile) => {
      let body = { di, key };
      let body2 = {
        name,
        mobile,
        key,
      };
      try {
        //인증한 고객인지 확인
        const res = await axios.post("/api/users/checkdi", body);
        if (res.data.success === false) {
          alert("이미 인증하신 고객님입니다. 아이디를 조회해주세요.");
          navigate("/Login");
        }
        const res2 = await axios.post("/api/users/checkResignPeriod", body2);
        if (res2.data.success === false) {
          alert(`${res2.data.message}`);
          navigate("/");
        }
      } catch (err) {
        console.error(err);
        alert("알수없는 에러가 발생했습니다. \n 관리자에게 문의해주세요.");
        navigate("/");
      }
    };
    const getData = async () => {
      axios
        .get("/api/oAuth/signFormAuth", {
          headers: {
            Authorization: `312839dsajkdaldjdbh`,
          },
        })
        .then(res => {
          if (!res.data.success) {
            alert(`${res.data.message}`);
            navigate("/");
          } else {
            const {
              key,
              authtype,
              birthdate,
              dupInfo,
              mobile,
              name,
              gender,
              nationalInfo,
              tid,
              mobileCo,
              campaignkey,
            } = res.data.data;
            setAesKey(key);
            setAuthType(authtype); // 암호화 안되어있음
            setBirthDay(birthdate);
            setName(name);
            setDi(dupInfo);
            setBirthDay(birthdate);
            setMobileno(mobile);
            setMobileco(mobileCo);
            setGender(gender);
            setNationalInfo(nationalInfo);
            setTid(tid);
            setCampaignKey(campaignkey);
            checkUserAuth(dupInfo, key, name, mobile);
          }
        })
        .catch(err => {
          console.log(err);
          alert("알수없는 에러발생했습니다.");
          navigate("/");
        });
    };

    getData();
  }, []);

  // import 받는거
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "//webapi.adpopcorn.com/offerwall/postback/js/adpopcorn-cpa.1.2.js";
    script.async = true;

    document.body.appendChild(script);

    // 스크립트 로딩 실패 시 처리
    script.onerror = () => {
      alert("스크립트 로딩에 실패했습니다. 메인 페이지로 이동합니다.");
      // 여기서는 예시로 alert를 사용했지만 필요에 따라 제거하실 수 있습니다.
      window.location.href = "/"; // 메인 페이지로 리디렉션
    };

    // 컴포넌트가 언마운트될 때 스크립트 제거
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (!allAgree) {
      if (
        termsOfUse &&
        privacy &&
        marketing_agree &&
        privacyAllAgree &&
        shinhan &&
        withus &&
        postMNC &&
        ksjCompany &&
        koreana &&
        merchant &&
        dbSonHae
      ) {
        setAllAgree(true);
      }
    } else {
      if (
        !termsOfUse ||
        !privacy ||
        !marketing_agree ||
        !privacyAllAgree ||
        !shinhan ||
        !withus ||
        !postMNC ||
        !ksjCompany ||
        !koreana ||
        !merchant ||
        !dbSonHae
      ) {
        setAllAgree(false);
      }
    }
  }, [
    termsOfUse,
    privacy,
    marketing_agree,
    privacyAllAgree,
    shinhan,
    withus,
    postMNC,
    ksjCompany,
    koreana,
    merchant,
    dbSonHae,
  ]);

  // 개인정보 제3자 제공(유상) 전체동의(선택) 상태값 observer
  useEffect(() => {
    // console.log("개인정보 제3자 제공(유상) 전체동의(선택) 상태값 observer");
    if (!privacyAllAgree) {
      if (
        shinhan &&
        withus &&
        postMNC &&
        ksjCompany &&
        koreana &&
        merchant &&
        dbSonHae
      ) {
        setPrivacyAllAgree(true);
      }
    } else {
      if (
        !shinhan ||
        !withus ||
        !postMNC ||
        !ksjCompany ||
        !koreana ||
        !merchant ||
        !dbSonHae
      ) {
        setPrivacyAllAgree(false);
      }
    }
  }, [shinhan, withus, postMNC, ksjCompany, koreana, merchant, dbSonHae]);

  useEffect(() => {
    if (clickStatus) {
      submitForm();
    }
  }, [clickStatus]);

  //바꿔야하고
  const register = async e => {
    setIsLoading(true);
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: "smooth" });

    let idCheckResult = idCheck(id);
    try {
      if (!idCheckResult.result) {
        toast(`${idCheckResult.message}`);
        idRef.current.focus();
        return;
      }

      let passwordCheckResult = passwordCheck(password);
      if (!passwordCheckResult.result) {
        toast(`비밀번호${passwordCheckResult.message}`);
        passwordRef.current.focus();
        return;
      }

      let confirmPasswordCheckResult = confirmPasswordCheck(
        password,
        confirmpassword
      );
      if (!confirmPasswordCheckResult.result) {
        toast(`${confirmPasswordCheckResult.message}`);
        confirmpasswordRef.current.focus();
        return;
      }

      let emailCheckResult = emailCheck(email);
      if (!emailCheckResult.result) {
        toast(`${emailCheckResult.message}`);
        emailRef.current.focus();
        return;
      }

      if (_keys(selectAddr).length === 0) {
        alert("주소를 먼저 선택해주세요.");
        return;
      }

      // 정보수집
      // '0'은 이용약관 동의 필수
      if (!termsOfUse) {
        toast("이용약관 동의는 필수입니다. 선택해주세요.");
        return;
      }

      // '1' 개인정보 수집 및 이용동의 필수
      if (!privacy) {
        toast("개인정보 수집 및 이용 동의는 필수입니다. 선택해주세요.");
        return;
      }

      if (!marketing_agree || !privacyAllAgree) {
        setShowPopup(true);
      } else {
        setClickStatus(true);
      }
    } catch (error) {
      console.error(error);
      alert("네트워크에러발생했습니다.");
    } finally {
      setIsLoading(false); // 로딩 상태를 false로 설정
    }
  };

  // '2' 마케팅 정보 제공동의(선택) , 굳이 선택안해도됨. -> 바꿔야하고
  const submitForm = () => {
    let eId = encrypt(id, aesKey); // 암호화하고
    let ePassword = encrypt(password, aesKey); //암호화하고
    let eEmail = encrypt(email, aesKey); // 암호화 필요

    let body = {
      id: eId,
      password: ePassword,
      authType,
      birthday,
      di,
      nationalinfo,
      name,
      phone: mobileno,
      mobileco,
      gender,
      email: eEmail,
      termsOfUse,
      privacy,
      marketing_agree,
      third_agree: privacyAllAgree,
      shinhan,
      withus,
      postMNC,
      ksjCompany,
      koreana,
      merchant,
      dbSonHae,
      terms_version: privacyAllAgree ? parseInt(curVersion) : 0,
      addr: selectAddr,
      key: aesKey,
      tid,
      campaignkey,
    };
    dispatch(UserService.renewalRegisterAdPop(body)).then(res => {
      if (res.payload.success) {
        AdPopcorn.cpaCompleted();
        alert(
          "환영합니다. 트래블링크 회원가입이 완료되었습니다.\n 로그인해주세요."
        );
        if (window.fbq) {
          window.fbq("trackCustom", "TvSignComplete", {});
        }
        if (window.gtag) {
          window.gtag("event", "TVSignComplete");
          window.gtag("event", "AdPopCornTVSignComplete");
        }
        navigate("/Login");
      } else {
        if (res.payload.status === "decrypt") {
          alert(`${res.payload.message}`);
          return;
        }
        if (res.payload.status === "admin") {
          alert("다시 시도 후 같은 증상 발생 시 관리자에게 문의해주세요.");
          return;
        }
        if (res.payload.status === "id") {
          toast(`${res.payload.message}`);
          idRef.current.focus();
          return;
        }
        if (res.payload.status === "email") {
          toast(`${res.payload.message}`);
          emailRef.current.focus();
          return;
        }
        if (res.payload.status === "di") {
          alert("이미 등록된 회원입니다. \n 아이디를 찾아주세요.");
          navigate("/Login");
          return;
        }
        if (res.payload.status === "resignPeriod") {
          alert(
            "탈퇴한지 180일 미만입니다. 탈퇴 후 180일이 지나야 다시 회원가입이 가능합니다."
          );
          return;
        }
      }
    });
  };

  const handleChange = useCallback((e, stateSetter) => {
    stateSetter(e.target.value);
  }, []);

  const popUpJustGo = () => {
    setShowPopup(false);
    setClickStatus(true);
  };

  const popUpAllAgree = () => {
    setShowPopup(false);
    setAllAgree(true);
    setTermOfUse(true);
    setPrivacy(true);
    setMarketingAgree(true);
    setPrivacyAllAgree(true);
    setShinHan(true);
    setWithUs(true);
    setPostMNC(true);
    setKSJCompany(true);
    setKoreana(true);
    setMerchant(true);
    setDbSonHae(true);
    setClickStatus(true);
  };

  const handleAllCheck = () => {
    if (allAgree === true) {
      setAllAgree(false);
      setTermOfUse(false);
      setPrivacy(false);
      setMarketingAgree(false);
      setPrivacyAllAgree(false);
      setShinHan(false);
      setWithUs(false);
      setPostMNC(false);
      setKSJCompany(false);
      setKoreana(false);
      setMerchant(false);
      setDbSonHae(false);
    } else {
      setAllAgree(true);
      setTermOfUse(true);
      setPrivacy(true);
      setMarketingAgree(true);
      setPrivacyAllAgree(true);
      setShinHan(true);
      setWithUs(true);
      setPostMNC(true);
      setKSJCompany(true);
      setKoreana(true);
      setMerchant(true);
      setDbSonHae(true);
    }
  };

  const handleSelectCheck = () => {
    if (privacyAllAgree === true) {
      setPrivacyAllAgree(false);
      setShinHan(false);
      setWithUs(false);
      setPostMNC(false);
      setKSJCompany(false);
      setKoreana(false);
      setMerchant(false);
      setDbSonHae(false);
    } else {
      setPrivacyAllAgree(true);
      setShinHan(true);
      setWithUs(true);
      setPostMNC(true);
      setKSJCompany(true);
      setKoreana(true);
      setMerchant(true);
      setDbSonHae(true);
    }
  };

  const checkboxChange = (e, id, name) => {
    switch (id) {
      case "0":
        handleAllCheck();
        break;
      case "1":
        setTermOfUse(!termsOfUse);
        break;
      case "2":
        setPrivacy(!privacy);
        break;
      case "3":
        setMarketingAgree(!marketing_agree);
        break;
      case "4":
        handleSelectCheck();
        break;
      case "5":
        setShinHan(!shinhan);
        break;
      case "6":
        setWithUs(!withus);
        break;
      case "7":
        setPostMNC(!postMNC);
        break;
      case "8":
        setKSJCompany(!ksjCompany);
        break;
      case "9":
        setKoreana(!koreana);
        break;
      case "10":
        setMerchant(!merchant);
        break;
      case "11":
        setDbSonHae(!dbSonHae);
        break;
      default:
        alert("예상치못한 에러");
        break;
    }
  };

  const showAddrModalHandler = e => {
    e.preventDefault();
    setShowAddrModal(!showAddrModal);
  };

  const getAddress = addr => {
    setSelectAddr(addr);
    setShowAddrModal(false);
  };

  return (
    <>
      <HelmetComponent title="회원가입" />
      <form className="adpopcorn_signform_form">
        <div className="adpopcorn_signform_step_sec">
          <p className="adpopcorn_signform_step1">01 본인인증</p>
          <p className="adpopcorn_signform_step4">&gt;</p>
          <p className="adpopcorn_signform_step2">02 정보입력 및 약관동의</p>
          <p className="adpopcorn_signform_step4">&gt;</p>
          <p className="adpopcorn_signform_step3">03 가입완료</p>
        </div>
        <div className="adpopcorn_signform_bg2">
          <p className="adpopcorn_signform_title">02 정보입력 및 약관동의</p>
          <div className="adpopcorn_signform_table_area">
            <table className="adpopcorn_signform_left_table">
              <tbody id="table_body">
                <tr>
                  <td className="adpopcorn_signform_id_input">
                    <label
                      className="adpopcorn_signform_subtitle"
                      htmlFor="username"
                    >
                      아이디
                    </label>
                    <InputComponent
                      type={"text"}
                      id={"username"}
                      name={"username"}
                      required={"required"}
                      placeholder={"영문소문자/숫자 4~16글자"}
                      value={id}
                      onChange={e => handleChange(e, setId)}
                      maxLength={"16"}
                      ref={idRef}
                      className="adpopcorn_signform_left_input"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="adpopcorn_signform_pw_input">
                    <label
                      className="adpopcorn_signform_subtitle"
                      htmlFor="password"
                    >
                      비밀번호
                    </label>
                    <InputComponent
                      type={"password"}
                      id={"password"}
                      name={"password"}
                      required={"required"}
                      placeholder={
                        "영문, 숫자, 특수문자 포함 8자 이상 16자이하"
                      }
                      value={password}
                      onChange={e => handleChange(e, setPassword)}
                      maxLength={"16"}
                      ref={passwordRef}
                      className="adpopcorn_signform_left_input"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="adpopcorn_signform_pw2_input">
                    <label
                      className="adpopcorn_signform_subtitle"
                      htmlFor="confirm-password"
                    >
                      비밀번호 확인
                    </label>
                    <InputComponent
                      type={"password"}
                      id={"confirm-password"}
                      name={"confirm-password"}
                      required={"required"}
                      placeholder={"비밀번호를 다시 입력해주세요"}
                      value={confirmpassword}
                      onChange={e => handleChange(e, setconfirmPassword)}
                      maxLength={"16"}
                      ref={confirmpasswordRef}
                      className="adpopcorn_signform_left_input"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="adpopcorn_signform_email_input">
                    <label
                      className="adpopcorn_signform_subtitle"
                      htmlFor="email"
                    >
                      이메일
                    </label>
                    <InputComponent
                      type={"email"}
                      name={"email"}
                      id={"email"}
                      required={"required"}
                      placeholder={"이메일을 입력해주세요."}
                      value={email}
                      onChange={e => handleChange(e, setEmail)}
                      maxLength={"30"}
                      ref={emailRef}
                      className="adpopcorn_signform_left_input"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="signform_input">
                    <label className="signform_subtitle">주소</label>
                    <div style={{ display: "flex" }}>
                      <input
                        type="text"
                        readOnly
                        required={true}
                        id="zipCode"
                        placeholder="우편번호 버튼을 클릭해주세요"
                        value={selectAddr.zipNo ? selectAddr.zipNo : ""}
                        className="agr_readonly"
                      />
                      <button
                        onClick={showAddrModalHandler}
                        className="signformbtn_addr"
                        type="button"
                      >
                        우편번호
                      </button>
                    </div>
                    <input
                      value={selectAddr.roadAddr ? selectAddr.roadAddr : ""}
                      readOnly
                      className="agr_readonly"
                      placeholder="주소입력칸"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <TermsComponent
            checkboxChange={checkboxChange}
            allAgree={allAgree}
            termsOfUse={termsOfUse}
            privacy={privacy}
            marketing_agree={marketing_agree}
            privacyAllAgree={privacyAllAgree}
            shinhan={shinhan}
            withus={withus}
            postMNC={postMNC}
            ksjCompany={ksjCompany}
            koreana={koreana}
            merchant={merchant}
            dbSonHae={dbSonHae}
          />
          <button
            type="submit"
            className={`adpopcorn_signformbtn${
              isLoading ? " loader-visible" : ""
            }`}
            onClick={register}
          >
            {isLoading ? <Loader /> : "가입하기"}
          </button>
          {showPopup && (
            <SignPopup
              setShowPopup={setShowPopup}
              popUpJustGo={popUpJustGo}
              popUpAllAgree={popUpAllAgree}
            />
          )}
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      </form>
      {showAddrModal && (
        <PostCodeModal
          setShowAddrModal={setShowAddrModal}
          getAddress={getAddress}
        />
      )}
    </>
  );
}
export default withAuth(AdPopCornSignForm, true);
