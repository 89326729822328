import React, { useEffect, useState } from "react";
import "../../assets/scss/MemoryComponent.scss";
import axios from "axios";
import withAuth from "../../hoc/auth";
import DomMap from "./Dom_map";
import OverMap from "./Over_map";
import HelmetComponent from "../header/HelmetComponent";
import { _each, _returneach, toChange2 } from "../../assets/js/util/util";
import MainTitleComponent from "../main/MainTitleComponent";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import { triangleIcon } from "../../assets/js/util/apiConstant";
import CategoryCustomButtonGroup from "../customButton/CategoryCustomButtonGroup";

const MemoryComponent = () => {
  const [activeContent, setActiveContent] = useState("OVER");
  const [myMemoryList, setMyMemoryList] = useState([]);
  const [reMyMeoryData, setRemyMemoryData] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const openImageModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
    setIsImageModalOpen(false);
  };

  useEffect(() => {
    try {
      axios.get("/api/memory/getMyMemoryList").then((res) => {
        setMyMemoryList(res.data.data);
        setSelectedImage;
      });
    } catch (err) {
      alert(err);
      window.location.reload();
    }
  }, [reMyMeoryData]);

  const handleClick = (content) => {
    setActiveContent(content);
  };
  const toggleContent = (index) => {
    setMyMemoryList((prev) =>
      prev.map((memory, idx) =>
        idx === index ? { ...memory, showContent: !memory.showContent } : memory
      )
    );
  };

  const deleteMemeoryHandler = (e, data) => {
    e.stopPropagation();
    axios.post("/api/memory/delete", data).then((res) => {
      alert(res.data.message);
      if (res.data.success) {
        setRemyMemoryData(!reMyMeoryData);
      } else {
        window.location.reload();
      }
    });
  };

  const memoryConfig = {
    containerClass: "memory_subcategory_sec",
    buttons: [
      {
        title: "해외",
        compareContent: "OVER",
        baseClassName: "memory_sub_button",
      },
      {
        title: "국내",
        compareContent: "DOM",
        baseClassName: "memory_sub_button",
      },
    ],
    components: {
      DOM: <DomMap />,
      OVER: (
        <OverMap
          reMyMeoryData={reMyMeoryData}
          myMemoryList={myMemoryList}
          setRemyMemoryData={setRemyMemoryData}
        />
      ),
    },
  };

  return (
    <>
      <HelmetComponent title="기록하기" />
      {isImageModalOpen && (
        <div className="memory_img_modal" onClick={closeImageModal}>
          <div className="memory_img_modal_content">
            <img
              src={convertS3toCloudFront(selectedImage)}
              alt="Selected Memory"
            />
          </div>
        </div>
      )}
      <div className="memory_sec">
        <CategoryCustomButtonGroup
          configurations={memoryConfig}
          activeContent={activeContent}
          handleClick={handleClick}
        />
        <div className="infoone_FL_sec">
          <MainTitleComponent
            title="내 여행기 List"
            divClass="infoone_FL_title_sec"
            pClass="infoone_FL_title"
          />
          {myMemoryList.length === 0 ? (
            <div className="memory_nodata">등록한 기록이 없습니다.</div>
          ) : (
            myMemoryList.map((memory, index) => (
              <React.Fragment key={index}>
                <div
                  className="memory_my"
                  key={index}
                  onClick={() => toggleContent(index)}
                >
                  <div className="memory_left">
                    <div className="memory_left_title">
                      <p className="memory_left_title_p">
                        {memory.TRAVEL_COUNTRY || "데이터X"}
                      </p>
                      <div className="memory_right">
                        <p>{toChange2(memory.CREATED_AT)}</p>
                        <img
                          style={{
                            transform: memory.showContent
                              ? "rotate(0deg)"
                              : "rotate(180deg)",
                          }}
                          src={convertS3toCloudFront(triangleIcon)}
                        />
                      </div>
                    </div>
                    <div className="memory_left_sub_title">
                      <p>{memory.TITLE || "데이터X"}</p>
                    </div>
                  </div>
                  <div className="memory_right">
                    <button>공유하기</button>
                  </div>
                  {memory.showContent && (
                    <div>
                      <hr />
                      <div className="memory_showcontent_img">
                        {memory.URL === "" ? (
                          <p>이미지를 불러오는데 실패했습니다.</p>
                        ) : (
                          <>
                            <img
                              onClick={(e) => {
                                e.stopPropagation();
                                openImageModal(memory.URL);
                              }}
                              src={convertS3toCloudFront(memory.URL)}
                            />
                            <p>클릭하시면 원본사이즈로 볼 수 있습니다. </p>
                          </>
                        )}
                      </div>
                      <div className="memory_showcontent_p">
                        <p>내 메모</p>
                        <p>
                          {memory.DESCRIPTION
                            ? memory.DESCRIPTION
                            : "등록한 메모글이 없습니다."}
                        </p>
                      </div>
                      <div
                        onClick={(e) => deleteMemeoryHandler(e, memory)}
                        className="memory_delete_sec"
                      >
                        <button>삭제하기</button>
                      </div>
                    </div>
                  )}
                </div>
              </React.Fragment>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default withAuth(MemoryComponent, false);
