import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../../assets/scss/TravelCoupon_detail.scss";
import axios from "axios";
import { toChange2 } from "../../assets/js/util/util";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import { useParams } from "react-router-dom";
import withAuth from "../../hoc/auth";
import { useNavigate } from "react-router-dom";
import HelmetComponent from "../header/HelmetComponent";
import Button from "../customButton/Button";
import { useDeviceScreen } from "../../assets/js/util/useDeviceScreen";

const TravelCouponDetail = () => {
  const { couponId } = useParams();
  const [detailData, setDetailData] = useState([]);
  const [couponRegistered, setCouponRegistered] = useState(false);
  const [dueDate, setdueDate] = useState(0);
  const user = useSelector((state) => state.users.user);
  const navigate = useNavigate();
  const device = useDeviceScreen();

  useEffect(() => {
    if (detailData.length > 0 && user && user.id) {
      axios
        .get(`/api/coupon/readForUser/${user.id}`)
        .then((res) => {
          if (res.data.length > 0) {
            const isRegistered = res.data.some(
              (coupon) => coupon.coupon_name === detailData[0]?.coupon_name
            );
            setCouponRegistered(isRegistered);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [detailData, user, window.location.pathname]);

  const toggleCouponRegistration = () => {
    if (couponRegistered) {
      const confirmUnregister = window.confirm("쿠폰 사용을 취소하시겠습니까?");
      if (confirmUnregister) {
        axios
          .post("/api/coupon/couponRegisterCancel", {
            id: user.id,
            coupon_name: detailData[0].coupon_name,
          })
          .then((res) => {
            if (res.data.success) {
              alert("쿠폰 사용이 취소되었습니다.");
              setCouponRegistered(false);
            } else {
              alert(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      if (!user.id) {
        alert("로그인 해주시기 바랍니다.");
        navigate("/Login");
      } else {
        const confirmRegister = window.confirm("쿠폰을 사용 설정하시겠습니까?");
        if (confirmRegister) {
          axios
            .post("/api/coupon/couponRegister", {
              id: user.id,
              coupon_name: detailData[0].coupon_name,
            })
            .then((res) => {
              if (res.data.success) {
                alert("쿠폰이 성공적으로 등록되었습니다.");
                setCouponRegistered(true);
              } else {
                alert(res.data.message);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    }
  };

  useEffect(() => {
    axios
      .get(`/api/coupon/couponDetail/${couponId}`)
      .then((res) => {
        if (res.data.success) {
          const { couponData, dueDate } = res.data.data;
          setDetailData([couponData]);
          setdueDate(dueDate);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const moveMypage = () => {
    navigate("/NewMypage");
  };

  return (
    <>
      <HelmetComponent title="Coupon 상세보기" />
      <div className="coupon_detail_sec">
        {detailData[0] && (
          <div>
            <div>
              <div className="coupon_up_sec">
                <div className="coupon_main_img">
                  <img
                    src={convertS3toCloudFront(detailData[0].coupon_img)}
                  ></img>
                </div>
                <div className="coupon_main_right">
                  <div>
                    <p className="coupon_main_right_title">
                      {detailData[0].coupon_name}
                    </p>
                    <p className="coupon_main_right_expl">
                      {detailData[0].coupon_expl}
                    </p>
                  </div>
                  <table className="coupon_table">
                    <thead>
                      <tr className="coupon_tr">
                        <td>쿠폰 유효기간</td>
                        <td>
                          ~{" "}
                          {dueDate !== null
                            ? toChange2(dueDate)
                            : "별도 공지시까지"}
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="coupon_tr2">
                        <td>대표 사용처</td>
                        <td>{detailData[0].coupon_use}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr className="coupon_tr3">
                        <td>쿠폰 혜택</td>
                        <td>{detailData[0].coupon_benefit}</td>
                      </tr>
                    </tfoot>
                  </table>
                  <div className="coupon_use_btn_sec">
                    {detailData[0].coupon_warranty !== "1" && (
                      <a
                        href={detailData[0].coupon_warranty}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button
                          style={{ width: "100%", height: "40px" }}
                          text={"사용시 주의사항"}
                          color={"s"}
                          device={device}
                        />
                      </a>
                    )}
                    <Button
                      device={device}
                      onClick={toggleCouponRegistration}
                      text={
                        couponRegistered
                          ? "쿠폰 사용 취소하기"
                          : "쿠폰 사용 설정하기"
                      }
                      style={{ width: "100%", height: "40px" }}
                      color={couponRegistered ? "x" : "s"}
                    />
                  </div>
                  <div>
                    <p className="coupon_use_btn_sec_p">
                      버튼을 누르면 내 정보 수정하기에서 바코드를 확인할 수
                      있습니다.
                    </p>
                    <p className="coupon_use_btn_sec_p2" onClick={moveMypage}>
                      내 쿠폰 바로가기 &gt;
                    </p>
                  </div>
                </div>
              </div>
              <div className="coupon_page_boundary">쿠폰 상세 내용</div>
              <div className="coupon_page_img">
                <img
                  src={convertS3toCloudFront(detailData[0].coupon_page_img)}
                ></img>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default withAuth(TravelCouponDetail, null);
