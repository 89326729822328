import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/scss/FindUserId.scss";
import InputComponent from "../customButton/InputComponent";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserService } from "../../service/UserService";

// 이름,이메일

// 아이디, 휴대폰 번호 받거나
const FindUserId = ({ activeStateChangeHandler }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [showId, setShowId] = useState(false);
  const [userId, setUserId] = useState([]);

  const nameRef = useRef();
  const emailRef = useRef();

  const handleChange = (e, stateSetter) => {
    stateSetter(e.target.value);
  };

  useEffect(() => {
    nameRef.current.focus();
  }, []);

  const findIdHandler = (e) => {
    if (name.length === 0) {
      toast("이름을 입력해주세요");
      nameRef.current.focus();
      return;
    }
    if (email.length === 0) {
      toast("이메일 입력해주세요");
      emailRef.current.focus();
      return;
    }

    let body = { name, email };

    dispatch(UserService.findIdByEmail(body)).then((res) => {
      if (res.payload.success) {
        toast(`${res.payload.message}`);
        setName("");
        setEmail("");
        setUserId(...userId, res.payload.status);
        setShowId(true);
      } else {
        toast(`${res.payload.message}`);
      }
    });
  };

  return (
    <>
      <h1 className="logintext">아이디찾기</h1>
      <div className="loginarea">
        {showId ? (
          <div>ID : {userId.id}</div>
        ) : (
          <>
            <InputComponent
              type={"text"}
              id={"name"}
              name={"name"}
              required={"required"}
              placeholder={"이름을 입력해주세요"}
              value={name}
              onChange={(e) => handleChange(e, setName)}
              maxLength={"20"}
              ref={nameRef}
            />
            <InputComponent
              type={"email"}
              name={"email"}
              id={"email"}
              required={"required"}
              placeholder={"이메일을 입력해주세요."}
              value={email}
              onChange={(e) => handleChange(e, setEmail)}
              maxLength={"30"}
              ref={emailRef}
            />
            <button onClick={findIdHandler} className="loginbtn" type="submit">
              확인
            </button>
          </>
        )}
      </div>
      <div className="loginfind">
        <p
          onClick={() => activeStateChangeHandler("0")}
          className="loginfind_pw"
        >
          로그인 가기
        </p>
        <p
          onClick={() => activeStateChangeHandler("2")}
          className="loginfind_pw"
        >
          비밀번호 찾기
        </p>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};
export default FindUserId;
