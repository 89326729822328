import React from "react";
import DomSamSungModalForm from "./DomSamSungModalForm";
import "../../assets/scss/DomSamSungModal.scss";

function DomSamSungModal({ user, title, children }) {
  return (
    <div className="dom_samsung_modal">
      <div className="dom_samsung_modal_content">
        {children}
        <DomSamSungModalForm user={user} title={title}></DomSamSungModalForm>
      </div>
    </div>
  );
}

export default React.memo(DomSamSungModal);
