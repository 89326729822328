import React, { useState, useEffect } from "react";
import withAuth from "../../hoc/auth";
import { _each, _returneach } from "../../assets/js/util/util";
import Select from "react-select";
import overNationSorting from "../Memory/over_nation_sorting.json";
import TripSectionComponent from "./TripSectionComponent";
import axios from "axios";
import "../../assets/scss/NMPregisterModal.scss";

function NMPregisterModal({ onClose }) {
  const [countryOptions, setCountryOptions] = useState([]);

  const [dataSet, setDataSet] = useState([]);
  const [title, setTitle] = useState("");
  const [countryData, setCountryData] = useState(null);
  const [country, setCountry] = useState("");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [sectionCount, setSectionCount] = useState(1);

  useEffect(() => {
    const data = Object.keys(overNationSorting).map((country) => ({
      value: country,
      label: overNationSorting[country],
    }));

    setCountryOptions(data);
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1; // "+1" is for including both start and end date
      setSectionCount(diffDays);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    let dataLength = sectionCount;
    if (dataLength > 7) {
      dataLength = 7;
      alert("최대 7일까지 등록할 수 있습니다");
    }
    const newDataArray = Array.from({ length: dataLength }, () => ({
      places: [{ name: "", descriptions: [""] }],
    }));
    setDataSet(newDataArray);
  }, [sectionCount]);

  const titleHandler = (e) => {
    setTitle(e.target.value);
  };

  const countryChange = (obj) => {
    const name = obj.label;
    setCountryData(obj);
    setCountry(name);
  };

  const handleStartDateChange = (event) => {
    const selectedStartDate = new Date(event.target.value);

    if (endDate && selectedStartDate > endDate) {
      alert("시작일자가 종료일자보다 클 수 없습니다.");
      return;
    }

    setStartDate(selectedStartDate);
  };

  const handleEndDateChange = (event) => {
    const selectedEndDate = new Date(event.target.value);

    if (startDate && selectedEndDate < startDate) {
      alert("종료일자가 시작일자보다 이전일 수 없습니다.");
      return;
    }

    setEndDate(selectedEndDate);
  };
  const submitData = () => {
    if (!title) {
      alert("여행 제목을 입력해주세요..");
      return;
    }

    if (!startDate) {
      alert("여행시작날짜를 입력해주세요.");
      return;
    }
    if (!endDate) {
      alert("여행 종료일자 입력해주세요.");
      return;
    }

    // 여행 시작일자가 종료일자보다 큰 경우.
    let body = {
      title,
      startDate,
      endDate,
      dataSet,
      country,
    };
    try {
      axios.post("/api/plan/register", body).then((res) => {
        if (res.data) {
          alert("성공했습니다");
          window.location.reload();
        } else {
          alert("등록에 실패하였습니다.");
          window.location.reload();
        }
      });
    } catch (err) {
      console.error(err);
      alert("등록에 실패하였습니다.");
      window.location.reload();
    }
  };

  return (
    <>
      <div className="nmpresigter_background">
        <div className="nmpresigter_content">
          <div>
            <p>여행지 입력</p>
            <Select
              value={countryData}
              onChange={countryChange}
              options={countryOptions}
            />
          </div>
          <div className="nmpregister_tripdate">
            <input
              type="date"
              value={startDate ? startDate.toISOString().substr(0, 10) : ""}
              onChange={handleStartDateChange}
            />
            <input
              type="date"
              value={endDate ? endDate.toISOString().substr(0, 10) : ""}
              onChange={handleEndDateChange}
            />
          </div>
          <div className="nmpregister_triptitle">
            <p>여행 제목 입력</p>
            <input
              value={title}
              onChange={titleHandler}
              placeholder="여행제목"
            ></input>
          </div>
          <div className="nmpregister_tripschedule">
            <TripSectionComponent dataSet={dataSet} setDataSet={setDataSet} />
          </div>
          <div className="nmpregister_trip_savebtn">
            <button onClick={onClose}>닫기</button>
            <button onClick={submitData}>저장하기</button>
          </div>
        </div>
      </div>
    </>
  );
}
export default withAuth(NMPregisterModal, false);
