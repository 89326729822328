import "../../assets/scss/Footer.scss";
import React from "react";
import { Link } from "react-router-dom";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";

function Footer() {
  return (
    <footer id="footer">
      <div className="footer_all">
        <div className="footer_logo">
          <a href="/">
            <img
              src={convertS3toCloudFront(
                "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/RENEWAL/230822_mainlogo.png"
              )}
              alt="트래블링크 로고"
            />
          </a>
          <div>
            <Link to="/Terms">이용약관</Link>
          </div>
          <div>
            <Link to="/TermsPri">개인정보 처리방침</Link>
          </div>
          <div>
            <Link to="/Announ">공지사항</Link>
          </div>
        </div>
        <div className="footer_info">
          <div className="footer_info_list">
            <div>
              <span className="boldline">상호명</span>
              <span>(주) 에스비제이</span>
            </div>
            <div>
              <span className="boldline">대표자명</span>
              <span>정일웅</span>
            </div>
            <div>
              <span className="boldline">사업장 주소</span>
              <span>강남구 논현로149길 61 (논현동) 3층</span>
            </div>
            <div>
              <span className="boldline">대표전화</span>
              <span>07048143963</span>
            </div>
          </div>
          <div className="footer_info_list">
            <div>
              <span className="boldline">사업자등록번호</span>
              <span>894-86-01505</span>
            </div>
            <div>
              <span className="boldline">통신판매업 신고번호</span>
              <span>"2021-서울서초-0581"</span>
            </div>
            <div>
              <span className="boldline">E-mail</span>
              <span>info@sbjgroup.co.kr</span>
            </div>
          </div>
        </div>
        <div className="inner_b">
          <div className="bt_copyright">
            Copyright © 트래블링크. All Rights Reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}

export default React.memo(Footer);
