import React from "react";
import InfoOneContentDetail from "./InfoOneContentDetail";

const InfoOneDetailWrapper = ({ activeContent }) => {
  // const [activeRegion, setActiveRegion] = useState("DOM");

  // const handleClick = content => {
  //   setActiveRegion(content);
  // };
  return (
    <>
      {/* {activeContent === "exchange" ? null : (
        <div className="infoone_subcategory_sec">
          <button
            className={`infoone_sub_button ${
              activeRegion === "DOM" ? "clicked" : ""
            }`}
            onClick={() => handleClick("DOM")}
          >
            국내
          </button>
          <button
            className={`infoone_sub_button ${
              activeRegion === "OVER" ? "clicked" : ""
            }`}
            onClick={() => handleClick("OVER")}
          >
            해외
          </button>
        </div>
      )} */}
      <div>
        <InfoOneContentDetail
          activeRegion="DOM"
          activeContent={activeContent}
        />
      </div>
    </>
  );
};

export default React.memo(InfoOneDetailWrapper);
