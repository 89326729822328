import React, { useState, useEffect } from "react";
import "../../assets/scss/A2hsButton.scss";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import Button from "./Button";
const useA2HS = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/service-worker.js")
        .then(function (registration) {
          //   console.log("Service Worker 등록 성공:", registration);
        })
        .catch(function (error) {
          //   console.log("Service Worker 등록 실패:", error);
        });
    }

    const handler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };
    window.addEventListener("beforeinstallprompt", handler);
    return () => {
      window.removeEventListener("beforeinstallprompt", handler);
    };
  }, []);

  const installApp = () => {
    deferredPrompt?.prompt();
    deferredPrompt?.userChoice.then((choiceResult) => {
      clearPrompt();
    });
  };

  const clearPrompt = () => {
    setDeferredPrompt(null);
  };

  return { deferredPrompt, installApp, clearPrompt };
};
export default function A2HS() {
  const { deferredPrompt, installApp, clearPrompt } = useA2HS();

  return deferredPrompt ? (
    <div className="installApp_sec">
      <div className="installApp_img_sec">
        <img
          src={convertS3toCloudFront(
            "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/RENEWAL/logo512.png"
          )}
        />
        <div className="installApp_text_sec">
          <p>트래블링크 바로가기를</p>
          <p>추가하시겠습니까?</p>
        </div>
      </div>
      <div className="installApp_btn_sec">
        <Button
          text={"취소"}
          color={"t"}
          style={{ width: "120px", height: "35px", fontSize: "12px" }}
          onClick={clearPrompt}
        />
        <Button
          text={"홈 화면에 추가"}
          color={"s"}
          style={{ width: "120px", height: "35px", fontSize: "12px" }}
          onClick={installApp}
        />
      </div>
    </div>
  ) : null;
}
