import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import axios from "axios";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import { urlMove } from "../../assets/js/util/navigation";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PreventDiagonalScroll from "../Slider/PreventDiagonalScroll";
import "../../assets/scss/InfoOneMainBanner.scss";

function InfoOneMainBanner({ activeContent }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [data, setData] = useState([]);
  const [dragging, setDragging] = useState(false);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    try {
      axios.get(`/api/infoOne/getMainSlider/${activeContent}`).then((res) => {
        setData(res.data.data);
      });
    } catch (err) {
      alert(err);
    }
  }, [activeContent]);

  const handleOnClick = (item, e) => {
    const eventName = `TvInfo${item.CATEGORY2}${item.CATEGORY3}${item.TITLE}`;

    if (window.fbq) {
      window.fbq("trackCustom", eventName, {});
    }

    if (window.gtag) {
      window.gtag("event", eventName);
    }

    clickHandler(item.SOURCE_URL, e);
  };

  const clickHandler = (url, e) => {
    if (dragging) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      urlMove(url);
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    swipe: true,
    swipeToSlide: true,
    beforeChange: () => setDragging(true),
    afterChange: () => setDragging(false),
  };
  return (
    <div>
      <PreventDiagonalScroll />
      <div className="infoone_slider_container">
        <Slider {...settings}>
          {data.map((item, index) => {
            return (
              <div onClick={(e) => handleOnClick(item, e)} key={index}>
                <img src={convertS3toCloudFront(item.IMAGE_PATH)} alt="" />
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}

export default React.memo(InfoOneMainBanner);
