import React from "react";
import CustomCheckBox from "../customButton/CustomCheckBox";

const ThirdAgreeDelete = ({ shinhanAgree, device }) => {
  if (device === "pc") {
    return (
      <>
        <p className="mypage_marketing">개인정보 제3자 제공(유상)</p>
        <CustomCheckBox
          checked={shinhanAgree}
          name="신한라이프생명보험㈜ 철회"
        />
        <p onClick style={{ fontSize: "12px", fontWeight: "600" }}>
          *신한라이프 생명보험 동의 철회는 080-700-3350 으로 전화 부탁드립니다.
        </p>
      </>
    );
  } else {
    return (
      <>
        <p className="mypage_marketing">개인정보 제3자 제공(유상)</p>
        <CustomCheckBox
          checked={shinhanAgree}
          name="신한라이프생명보험㈜ 철회"
        />
        <div style={{ fontSize: "12px", fontWeight: "600", lineHeight: "1.5" }}>
          <span style={{ marginRight: "5px" }}>*</span>
          <span>신한라이프 생명보험 동의 철회는</span>
          <a
            href="tel:080-700-3350"
            style={{ color: "#007bff", marginLeft: "5px" }}
          >
            080-700-3350
          </a>
          <span>으로 전화 부탁드립니다.</span>
        </div>
      </>
    );
  }
};

export default React.memo(ThirdAgreeDelete);
