import React, { useEffect, useState, useMemo } from "react";
import "../../assets/scss/Announ.scss";
import axios from "axios";
import LinkButtonAnnoun from "../customButton/LinkButtonAnnoun";
import Announ_table from "./Announ_table";
import { queryStr } from "../../assets/js/util/util";
import HelmetComponent from "../header/HelmetComponent";
import Button from "../customButton/Button";
import { useDeviceScreen } from "../../assets/js/util/useDeviceScreen";

function Announ() {
  const contentTypes = {
    All: "전체",
    Service: "서비스",
    Event: "이벤트",
    Etc: "기타",
  };

  // contentType state 추가
  const [contentType, setContentType] = useState("All");
  const device = useDeviceScreen();
  const [maxData, setMaxData] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const [entireData, setEntireData] = useState([]);

  const previosuButton = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 10);
    } else {
      alert("첫페이지입니다.");
    }
  };
  const nextButton = () => {
    if (currentPage + 10 < maxData) {
      setCurrentPage(currentPage + 10);
    } else {
      alert("마지막페이지입니다.");
    }
  };
  // handleContentClick 함수 생성
  const handleContentClick = type => {
    setContentType(type);
    setCurrentPage(0);
  };

  useEffect(() => {
    // 전체데이터
    if (contentType == "All") {
      const URL =
        "/api/announce/getAnnounceOffset" +
        "?" +
        queryStr({ limit: 10, currentPage });

      axios.get(URL).then(res => {
        setEntireData(prevData => [...res.data.data]);
      });
    } else if (contentType == "Service") {
      const URL =
        "/api/announce/getAnnounceOffsetService" +
        "?" +
        queryStr({ limit: 10, currentPage });

      axios.get(URL).then(res => {
        setEntireData(prevData => [...res.data.data]);
      });
    } else if (contentType == "Event") {
      const URL =
        "/api/announce/getAnnounceOffsetEvent" +
        "?" +
        queryStr({ limit: 10, currentPage });

      axios.get(URL).then(res => {
        setEntireData(prevData => [...res.data.data]);
      });
    } else {
      const URL =
        "/api/announce/getAnnounceOffsetEtc" +
        "?" +
        queryStr({ limit: 10, currentPage });

      axios.get(URL).then(res => {
        setEntireData(prevData => [...res.data.data]);
      });
    }
  }, [contentType, currentPage]);

  useEffect(() => {
    if (contentType == "All") {
      axios.get("/api/announce/getMaxData").then(res => {
        setMaxData(res.data.data);
      });
    } else if (contentType == "Service") {
      axios.get("/api/announce/getMaxDataService").then(res => {
        setMaxData(res.data.data);
      });
    } else if (contentType == "Event") {
      axios.get("/api/announce/getMaxDataEvent").then(res => {
        setMaxData(res.data.data);
      });
    } else {
      axios.get("/api/announce/getMaxDataEtc").then(res => {
        setMaxData(res.data.data);
      });
    }
  }, [contentType]);

  const renderLinkButtonAnnoun = useMemo(() => {
    return Object.entries(contentTypes).map(([type, text]) => (
      <LinkButtonAnnoun
        handleOtherClick={handleContentClick}
        key={type}
        text={text}
        type={type}
        isSelected={contentType === type}
      />
    ));
  }, [contentType]);
  return (
    <>
      <HelmetComponent title="공지사항" />
      <div className="announ_all">
        <div>
          <div className="announ_btn">{renderLinkButtonAnnoun}</div>
          <div className="announ_table_sec">
            <Announ_table contentType={contentType} entireData={entireData} />
          </div>
        </div>
        <div>
          {currentPage !== 0 ? (
            <Button
              onClick={previosuButton}
              text={"이전"}
              device={device}
              color={"s"}
            />
          ) : null}
          {currentPage + 10 < maxData ? (
            <Button
              onClick={nextButton}
              text={"다음"}
              device={device}
              color={"s"}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}
export default Announ;
