import React, { useState, useEffect, useRef, forwardRef } from "react";
import "../../assets/scss/InsuranceCert.scss";
import { useSelector } from "react-redux";
import SamSungWarranty from "../Event/SamSungWarranty";
import DomSamSungWarranty from "../Event/DomSamSungWarranty";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import { useDeviceScreen } from "../../assets/js/util/useDeviceScreen";

const InsuranceCert = forwardRef(
  (
    {
      eventCreatedAtProp,
      eventInsuranceCompanyProp,
      eventStartDateProp,
      eventEndDateProp,
      eventCountryProp,
      showModal,
      closeModal,
    },
    ref
  ) => {
    const user = useSelector((state) => state.users.user);
    const device = useDeviceScreen();
    const [eventCreatedAt, setEventCreatedAt] = useState(eventCreatedAtProp);
    const [eventInsuranceCompany, setEventInsuranceCompany] = useState(
      eventInsuranceCompanyProp
    );
    const [eventStartDate, setEventStartDate] = useState(eventStartDateProp);
    const [eventEndDate, setEventEndDate] = useState(eventEndDateProp);
    const [eventCountry, setEventCountry] = useState(eventCountryProp);

    useEffect(() => {
      setEventCreatedAt(eventCreatedAtProp);
      setEventInsuranceCompany(eventInsuranceCompanyProp);
      setEventStartDate(eventStartDateProp);
      setEventEndDate(eventEndDateProp);
      setEventCountry(eventCountryProp);
    }, [
      eventCreatedAtProp,
      eventInsuranceCompanyProp,
      eventStartDateProp,
      eventEndDateProp,
      eventCountryProp,
    ]);

    return (
      <div
        ref={ref}
        className={`insurance_cert_modal ${showModal ? "open" : ""}`}
      >
        <div className="insurance_modal_overlay" onClick={closeModal} />
        <div className="insurance_modal_content">
          <div className="insurance_modal_upright"></div>
          <div className="insurance_modal_downleft"></div>
          <div className="modal_cert_all">
            <div className="modal_cert_up">
              <div className="modal_cert_title">
                <img
                  className="modal_cert_logo"
                  src={convertS3toCloudFront(
                    "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/RENEWAL/230822_mainlogo.png"
                  )}
                />
                <p>삼성화재 여행자보험 가입확인서</p>
              </div>
              <div className="modal_cert_content">
                <p>
                  {device === "mobile" || device === "tablet"
                    ? [
                        <span key="line1">위 고객님께서는 트래블링크</span>,
                        <br key="br1" />,
                        <span key="line2">회원가입 이벤트를 통해</span>,
                        <br key="br2" />,
                        <span key="line3">{`${eventCreatedAt},`}</span>,
                      ]
                    : `위 고객님께서는 트래블링크 회원가입 이벤트를 통해 ${eventCreatedAt},`}
                </p>

                <p>
                  {device === "mobile" || device === "tablet"
                    ? [
                        <span key="line4">삼성화재 여행자 보험 가입이</span>,
                        <br key="br3" />,
                        <span key="line5">정상적으로 완료되었습니다.</span>,
                      ]
                    : `삼성화재 여행자 보험 가입이 정상적으로 완료되었습니다.`}
                </p>
              </div>
              <div className="modal_cert_name">
                <p>
                  보험계약자:<strong>{user.name}</strong>
                </p>
              </div>
            </div>
            <div className="modal_cert_boundary"></div>
            <div className="modal_cert_down">
              <table className="modal_cert_down_table">
                <tbody>
                  <tr className="modal_cert_down_tr1">
                    <td>보험명</td>
                    <td>
                      {eventInsuranceCompany}{" "}
                      {eventCountry ? "해외 여행자 보험" : "국내 여행자 보험"}
                    </td>
                  </tr>
                  <tr className="modal_cert_down_tr2">
                    <td>
                      {device === "mobile" || device === "tablet"
                        ? [
                            <span key="line6">보험</span>,
                            <br key="br4" />,
                            <span key="line7">기간</span>,
                          ]
                        : ["보험기간"]}
                    </td>

                    <td>
                      {device === "mobile" || device === "tablet"
                        ? [
                            <span key="startDate">{`${eventStartDate}`}</span>,
                            <span key="tilde"> ~ </span>,
                            <span key="endDate">{`${eventEndDate}`}</span>,
                            <br key="br6" />,
                            <span
                              style={{
                                width: "85%",
                                display: "flex",
                                justifyContent: "left",
                                marginTop: "2%",
                                fontSize: "12px",
                              }}
                              key="note"
                            >
                              (여행 1회에 한함)
                            </span>,
                          ]
                        : [
                            <span key="period">{`${eventStartDate} ~ ${eventEndDate}`}</span>,
                            <span key="note"> (여행 1회에 한함)</span>,
                          ]}
                    </td>
                  </tr>
                  <tr className="modal_cert_down_tr3">
                    <td>
                      {device === "mobile" || device === "tablet"
                        ? [
                            <span key="line8">보장</span>,
                            <br key="br7" />,
                            <span key="line9">내역</span>,
                          ]
                        : ["보장내역"]}
                    </td>
                    <td>
                      <div className="mypage_warranty_sec">
                        <div
                          className="mypage_warranty"
                          style={{ position: "relative", zIndex: "2" }}
                        >
                          {eventCountry ? (
                            <SamSungWarranty />
                          ) : (
                            <DomSamSungWarranty />
                          )}
                        </div>
                        <button className="mypage_terms_btn">
                          <a
                            href={
                              eventCountry
                                ? "/pdfs/OverseasInsuranceTerms.pdf"
                                : "/pdfs/DomesticInsuranceTerms.pdf"
                            }
                            download
                          >
                            {eventCountry
                              ? "해외 여행자 보험 약관"
                              : "국내 여행자 보험 약관"}
                          </a>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              {device === "mobile" || device === "tablet"
                ? [
                    <React.Fragment key="certSmall">
                      <div className="modal_cert_createat_small">
                        <p>{eventCreatedAt}</p>
                      </div>
                      <div className="modal_cert_createat">
                        <p>주식회사 에스비제이</p>
                        <p>대표: 정일웅</p>
                      </div>
                    </React.Fragment>,
                  ]
                : [
                    <div className="modal_cert_createat" key="cert">
                      <p>{eventCreatedAt}</p>
                      <p>주식회사 에스비제이</p>
                      <p>대표: 정일웅</p>
                    </div>,
                  ]}
            </div>
          </div>
          <div className="modal_cert_btn">
            <button onClick={closeModal}>
              <img
                src={convertS3toCloudFront(
                  "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/menu_close.svg"
                )}
              />
            </button>
          </div>
        </div>
      </div>
    );
  }
);

export default InsuranceCert;
