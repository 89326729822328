import React, { useEffect, useState } from "react";
import "../../assets/scss/MyPageCouponComponent.scss";
import axios from "axios";
import MypageCouponComponentModal from "./MypageCouponComponentModal";
import MypageCouponSlider from "./MypageCouponSlider";
import { shallowEqual, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MainTitleComponent from "../main/MainTitleComponent";

function MypageCouponComponent() {
  const [couponData, setCouponData] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideCount, setSlideCount] = useState(0);
  const user = useSelector((state) => state.users.user, shallowEqual);
  const navigate = useNavigate();
  useEffect(() => {
    if (user && user.id) {
      axios.get(`/api/coupon/readForUser/${user.id}`).then((res) => {
        setCouponData(res.data);
        setSlideCount(res.data.length);
      });
    }
  }, [user]);
  const toggleModal = () => {
    setModalOpen((prev) => {
      if (!prev) {
        document.body.classList.add("mypage-modal-open");
      } else {
        document.body.classList.remove("mypage-modal-open");
      }
      return !prev;
    });
  };
  const mainMove = () => {
    navigate("/Coupon");
  };
  return (
    <>
      {isModalOpen && (
        <div className="mypage-modal-background" onClick={toggleModal} />
      )}

      {couponData.length !== 0 && (
        <>
          <MypageCouponComponentModal
            isModalOpen={isModalOpen}
            toggleModal={toggleModal}
            selectedCoupon={selectedCoupon}
          />
          <div className="infoone_FL_sec">
            <MainTitleComponent
              title="내 쿠폰 리스트"
              pClass="infoone_FL_title"
              divClass="infoone_FL_title_sec"
            />
            <div>
              <div>
                <div className="mypage_coupon_img_sec">
                  <MypageCouponSlider
                    couponData={couponData}
                    toggleModal={toggleModal}
                    setSelectedCoupon={setSelectedCoupon}
                    setCurrentSlide={setCurrentSlide}
                    currentSlide={currentSlide}
                    slideCount={slideCount}
                  />
                </div>
                <p className="mypage_coupon_move" onClick={mainMove}>
                  지금 쿠폰 구경가기
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default React.memo(MypageCouponComponent);
