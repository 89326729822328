import React, { useState } from "react";
import Modal from "react-modal";
import "../../assets/scss/DomSamSungWarranty.scss";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import { useDeviceScreen } from "../../assets/js/util/useDeviceScreen";

function DomSamSungNotWarranty() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const device = useDeviceScreen();
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      <div className="warranty_detail_sec">
        <button onClick={openModal}>보상하지않는손해 별첨 확인</button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        appElement={document.getElementById("root")}
        style={{
          content: {
            margin: device === "mobile" ? "10% auto 0 auto" : "0 auto",
          },
        }}
      >
        <div className="warranty_detail_all">
          <button className="warranty_detail_closebtn_sec" onClick={closeModal}>
            <img
              className="warranty_detail_closebtn"
              src={convertS3toCloudFront(
                "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/menu_close.svg"
              )}
            />
          </button>

          <div>
            <h1>보상하지않는 사항 요약본</h1>
            <div>
              <span class="font14_600">
                아래 내용은 보상하지않는 사항 요약본으로 추가 및 자세한 사항은
                꼭 약관을 확인하시기 바랍니다.
              </span>
            </div>
            <br />
            <table>
              <tbody>
                <tr>
                  <td>보장명</td>
                  <td colSpan={2}>보상하지 않는 손해</td>
                </tr>
                <tr>
                  <td rowSpan={10}>공통</td>
                  <td className="left_align" colSpan={2}>
                    1. 피보험자가 고의로 자신을 해친 경우. 다만, 피보험자가
                    심신상실 등으로 자유로운 의사결정을 할 수 없는 상태에서
                    자신을 해친 경우에는 보험금을 지급합니다.
                  </td>
                </tr>
                <tr>
                  <td className="left_align" colSpan={2}>
                    2. 보험수익자가 고의로 피보험자를 해친 경우. 다만, 그
                    보험수익자가 일부 보험수익자인 경우에는 다른 보험수익자에
                    대한 보험금은 지급합니다.
                  </td>
                </tr>
                <tr>
                  <td className="left_align" colSpan={2}>
                    3. 계약자가 고의로 피보험자를 해친 경우
                  </td>
                </tr>
                <tr>
                  <td className="left_align" colSpan={2}>
                    4. 피보험자가 임신, 출산(제왕절개를 포함합니다), 산후기.
                    그러나, 회사가 보장하는 보험금 지급사유와 보장개시일로부터
                    2년이 지난 후에 발생한 습관성 유산, 불임 및 인공수정 관련
                    합병증으로 인한 경우에는 지급합니다.
                  </td>
                </tr>
                <tr>
                  <td className="left_align" colSpan={2}>
                    5. 전쟁, 외국의 무력행사, 혁명, 내란, 사변, 폭동
                  </td>
                </tr>
                <tr>
                  <td className="left_align" colSpan={2}>
                    6. 회사는 다른 약정이 없으면 피보험자가 직업, 직무 또는
                    동호회 활동목적으로 아래에 열거된 행위로 인하여
                    제3조(보험금의 지급사유)의 상해 관련 보험금 지급사유가
                    발생한 때에는 해당 보험금을 지급하지 않습니다.
                  </td>
                </tr>
                <tr>
                  <td className="left_align" colSpan={2}>
                    &nbsp;&nbsp; ① 전문등반(전문적인 등산용구를 사용하여 암벽
                    또는 빙벽을 오르내리거나 특수한 기술, 경험, 사전훈련을
                    필요로 하는 등반을 말합니다), 글라이더 조종, 스카이다이빙,
                    스쿠버다이빙, 행글라이딩, 수상보트, 패러글라이딩
                  </td>
                </tr>
                <tr>
                  <td className="left_align" colSpan={2}>
                    &nbsp;&nbsp; ② 모터보트, 자동차 또는 오토바이에 의한 경기,
                    시범, 흥행(이를 위한 연습을 포함합니다) 또는 시운전(다만,
                    공용도로상에서 시운전을 하는 동안 보험금 지급사유가 발생한
                    경우에는 보장합니다)
                  </td>
                </tr>
                <tr>
                  <td className="left_align" colSpan={2}>
                    &nbsp;&nbsp; ③ 선박에 탑승하는 것을 직무로 하는 사람이
                    직무상 선박에 탑승하고 있는 동안
                  </td>
                </tr>
                <tr>
                  <td className="left_align" colSpan={2}>
                    7. 회사는 피보험자가 운동경기선수로 경기에 참가하는 경우에는
                    그 운동경기(연습을 포함합니다)중에 입은 상해손해에 대하여는
                    보상하여 드리지는 않습니다.
                  </td>
                </tr>
                <tr>
                  <td>여행중 교통상해 입원일당 특별약관</td>
                  <td>
                    회사는 피보험자가 아래에 열거된 행위를 하는 동안에 생긴
                    손해에 대하여는 제1항에 정한 교통사고에서 제외합니다.
                    <br /> 1. 시운전, 경기(연습을 포함합니다) 또는 흥행(연습을
                    포함합니다)을 위하여 운행중의 교통수단에 탑승하고 있는 동안
                    <br />
                    2. 하역작업을 하는 동안 발생된 손해
                    <br /> 3. 교통수단의 설치, 수선, 점검, 정비나 청소작업을
                    하는 동안
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="warranty_detail_bottom_closebtn_sec">
            <button
              className="warranty_detail_bottom_closebtn"
              onClick={closeModal}
            >
              닫기
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default DomSamSungNotWarranty;
