import React, { useState, useEffect, useDebugValue } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { toChange2 } from "../../assets/js/util/util";
import "../../assets/scss/Announ_detail.scss";
import Button from "../customButton/Button";
import { useDeviceScreen } from "../../assets/js/util/useDeviceScreen";

function AnnounDetail() {
  const { announId } = useParams();
  const [data, setData] = useState(null);
  const [prevData, setPrevData] = useState(null);
  const [nextData, setNextData] = useState(null);
  const device = useDeviceScreen();
  const navigate = useNavigate();
  const moveAnnoun = (id) => {
    navigate(`/Announ`);
  };
  useEffect(() => {
    const fetchData = async (id) => {
      try {
        const [detailRes, prevRes, nextRes] = await Promise.all([
          axios.get(`/api/announce/getAnnounceDetail/${id}`),
          axios.get(`/api/announce/previous/${id}`),
          axios.get(`/api/announce/next/${id}`),
        ]);

        setData(detailRes.data.data);
        setPrevData(prevRes.data.data);
        setNextData(nextRes.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    if (announId !== undefined) {
      fetchData(announId);
    }
  }, [announId]);

  const navigateToPrev = () => prevData && navigate(`/Announ/${prevData.SEQ}`);
  const navigateToNext = () => nextData && navigate(`/Announ/${nextData.SEQ}`);

  if (!data) return "Loading...";

  return (
    <div className="announ_detail_all">
      <div className="announ_detail_title">
        <h1>{data.TITLE}</h1>
      </div>
      <div className="announ_detail_title_sec">
        <p>
          {data.category === "0"
            ? "서비스"
            : data.category === "1"
            ? "이벤트"
            : "기타"}
        </p>
        <p>{data.TITLE}</p>
        <p>{toChange2(data.created_at)}</p>
      </div>
      <div
        className="announ_detail_content"
        dangerouslySetInnerHTML={{ __html: data.description }}
      ></div>
      <Button
        onClick={() => moveAnnoun()}
        text={"목록"}
        color={"s"}
        position={"c"}
        device={device}
        style={{ fontSize: "14px", fontWeight: "300", marginBottom: "5%" }}
      />
      {prevData !== undefined && prevData !== null && prevData.TITLE ? (
        <>
          <div onClick={navigateToPrev} className="announ_detail_prev_content">
            <p>이전글입니다</p>
            <p>{prevData.TITLE}</p>
          </div>
        </>
      ) : null}
      {nextData !== undefined && nextData !== null && nextData.TITLE ? (
        <>
          <div onClick={navigateToNext} className="announ_detail_next_content">
            <p>다음글입니다</p>
            <p>{nextData.TITLE}</p>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default AnnounDetail;
