import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const UserService = {
  getUserAuth: createAsyncThunk("user/getUserAuth", async (_, thunkApi) => {
    try {
      const response = await axios.get("/api/users/auth");
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }),
  login: createAsyncThunk("user/login", async (body, thunkApi) => {
    try {
      const response = await axios.post("/api/login", body);
      //response 로 그대로 넘기면 header는 직렬화 할 수 없어서 에러를 반환함
      return response.data;
    } catch (err) {
      throw err;
    }
  }),
  logout: createAsyncThunk("user/logout", async (body, thunkApi) => {
    try {
      const response = await axios.get("/api/users/logout", body);
      return response.data;
    } catch (err) {
      throw err;
    }
  }),
  register: createAsyncThunk("user/register", async (body, thunkApi) => {
    try {
      const response = await axios.post("/api/users/register", body);
      return response.data;
    } catch (err) {
      throw err;
    }
  }),
  renewalRegister: createAsyncThunk(
    "user/renewalRegister",
    async (body, thunkApi) => {
      try {
        const response = await axios.post("/api/users/renewalRegister", body);
        return response.data;
      } catch (err) {
        throw err;
      }
    }
  ),
  registerAdPop: createAsyncThunk(
    "user/registerAdPop",
    async (body, thunkApi) => {
      try {
        const response = await axios.post("/api/users/registerAdPop", body);
        return response.data;
      } catch (err) {
        throw err;
      }
    }
  ),
  renewalRegisterAdPop: createAsyncThunk(
    "user/registerAdPop",
    async (body, thunkApi) => {
      try {
        const response = await axios.post(
          "/api/users/renewalRegisterAdPop",
          body
        );
        return response.data;
      } catch (err) {
        throw err;
      }
    }
  ),
  registerEdison: createAsyncThunk(
    "user/registerEdison",
    async (body, thunkApi) => {
      try {
        const response = await axios.post("/api/users/registerEdison", body);
        return response.data;
      } catch (err) {
        console.error(err);
        throw err;
      }
    }
  ),
  findIdByEmail: createAsyncThunk(
    "user/findIdByEmail",
    async (body, thunkApi) => {
      try {
        const response = await axios.post("/api/findIdByEmail", body);
        return response.data;
      } catch (err) {
        throw err;
      }
    }
  ),
  findIdByPhone: createAsyncThunk(
    "user/findIdByPhone",
    async (body, thunkApi) => {
      try {
        const response = await axios.post("/api/findIdByPhone", body);
        return response.data;
      } catch (err) {
        throw err;
      }
    }
  ),
  deleteById: createAsyncThunk("user/deleteById", async (_, thunkApi) => {
    try {
      const response = await axios.get("/api/delete");
      return response.data;
    } catch (err) {
      throw err;
    }
  }),
  update: createAsyncThunk("user/update", async (body, thunkApi) => {
    try {
      const response = await axios.post("/api/users/update", body);
      return response.data;
    } catch (err) {
      throw err;
    }
  }),
  thirdAgreeUpdate: createAsyncThunk(
    "user/thirdAgreeUpdate",
    async (body, thunkApi) => {
      try {
        const response = await axios.post("/api/users/thirdAgreeUpdate", body);
        return response.data;
      } catch (err) {
        throw err;
      }
    }
  ),
};
