import React, { useEffect, useState } from "react";
import "../../assets/scss/TravelCoupon.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import withAuth from "../../hoc/auth";
import MainTitleComponent from "../main/MainTitleComponent";
import HelmetComponent from "../header/HelmetComponent";
import CouponSlider from "./CouponSlider";
import { useDeviceScreen } from "../../assets/js/util/useDeviceScreen";

function TravelCoupon() {
  const [couponData, setCouponData] = useState([]);
  const device = useDeviceScreen();
  const navigate = useNavigate();
  useEffect(() => {
    axios.get("/api/coupon/read").then((res) => {
      setCouponData([res.data]);
    });
  }, []);

  const moveDetail = (id) => {
    navigate(`/Coupon/${id}`);
  };

  return (
    <>
      <HelmetComponent title="TravelCoupon" />
      <CouponSlider />
      <div className="infoone_FL_sec">
        <MainTitleComponent
          title="여행지 쿠폰 찾기"
          divClass="infoone_FL_title_sec"
          pClass="infoone_FL_title"
        />
      </div>
      <div className="coupon_all">
        <div className="coupon_sec">
          {couponData[0] &&
            couponData[0].map((item, key) => {
              let shortenCouponName;

              if (device === "mobile") {
                shortenCouponName =
                  item.coupon_name.length > 20
                    ? item.coupon_name.slice(0, 20) + "..."
                    : item.coupon_name;
              } else {
                shortenCouponName =
                  item.coupon_name.length > 30
                    ? item.coupon_name.slice(0, 30) + "..."
                    : item.coupon_name;
              }
              return (
                <div key={item.seq}>
                  <div
                    className="coupon_item"
                    onClick={() => moveDetail(item.seq)}
                  >
                    <div className="coupon_item_img">
                      <img src={convertS3toCloudFront(item.coupon_img)}></img>
                    </div>
                    <div className="coupon_above_category">{item.category}</div>
                    <div className="coupon_text_sec">
                      <p className="coupon_title">{shortenCouponName}</p>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}
export default withAuth(TravelCoupon, null);
