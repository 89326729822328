import React from "react";
import "../../assets/scss/SignPopup.scss";
import { useDeviceScreen } from "../../assets/js/util/useDeviceScreen";

const SignPopup = ({ popUpJustGo, popUpAllAgree }) => {
  const device = useDeviceScreen();

  return (
    <div className="signpopup_sec">
      <div className="signpopup_text_sec">
        <p className="signpopup_text_0">회원가입 이벤트 안내</p>
        <p className="signpopup_text_1">
          {device === "mobile" ? (
            <>
              상품 또는 서비스의 홍보 및 판매권유 등을 위한
              <br />
              개인정보 수집 및 활용 약관과 <br />
              개인정보 제 3자 제공(유상)동의 약관에 미동의시,
            </>
          ) : (
            "상품 또는 서비스의 홍보 및 판매권유 등을 위한 개인정보 수집 및 활용 약관과 개인정보 제 3자 제공(유상)동의 약관에 미동의시,"
          )}
        </p>
        <p className="signpopup_text_2">
          {device === "mobile" ? (
            <>
              국내/외 여행자 보험
              <br />
              무료 가입 혜택 제공이 불가합니다.
            </>
          ) : (
            "국내/외 여행자 보험 무료 가입 혜택 제공이 불가합니다."
          )}
        </p>
      </div>
      <div className="signpopup_btn_sec">
        <button className="signpopup_n_btn" onClick={popUpJustGo}>
          {device === "mobile" ? (
            <>
              국내/외 여행자 보험
              <br />
              무료가입이벤트 미참여
            </>
          ) : (
            "여행자 보험 무료가입이벤트 미참여"
          )}
        </button>
        <button className="signpopup_y_btn" onClick={popUpAllAgree}>
          동의하기
        </button>
      </div>
    </div>
  );
};

export default React.memo(SignPopup);
