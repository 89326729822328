import React, { useEffect, useRef, useState } from "react";
import InputComponent from "../customButton/InputComponent";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/scss/FindUserPassword.scss";
import { UserService } from "../../service/UserService";
import { passwordCheck } from "../../assets/js/util/check";
import { confirmPasswordCheck } from "../../assets/js/util/check";

// 이름,이메일

// 아이디,이름, 휴대폰 번호 받거나
const FindUserPassword = ({ activeStateChangeHandler }) => {
  const dispatch = useDispatch();

  const [id, setId] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setconfirmPassword] = useState("");

  const idRef = useRef();
  const phoneRef = useRef();
  const passwordRef = useRef();
  const confirmpasswordRef = useRef();

  useEffect(() => {
    idRef.current.focus();
  }, []);

  const handleChange = (e, stateSetter) => {
    stateSetter(e.target.value);
  };

  const findPasswordHandler = (e) => {
    e.preventDefault();

    if (id.length === 0) {
      toast("아이디 입력해주세요");
      idRef.current.focus();
      return;
    }
    if (phone.length === 0) {
      toast("핸드폰번호를 입력해주세요");
      phoneRef.current.focus();
      return;
    }

    let passwordCheckResult = passwordCheck(password);
    if (!passwordCheckResult.result) {
      toast(`비밀번호${passwordCheckResult.message}`);
      passwordRef.current.focus();
      return;
    }
    // 1. 비밀번호 확인을 입력했는지
    // 2. 비밀번호 확인과 비밀번호가 같은지
    let confirmPasswordCheckResult = confirmPasswordCheck(
      password,
      confirmpassword
    );
    if (!confirmPasswordCheckResult.result) {
      toast(`${confirmPasswordCheckResult.message}`);
      confirmpasswordRef.current.focus();
      return;
    }

    let body = { id, phone, password };
    dispatch(UserService.findIdByPhone(body))
      .then((res) => {
        if (res.payload.success) {
          alert(`${res.payload.message}`);
          window.location.href = "/Login";
        } else {
          alert(
            `${res.payload.message}\n 다시 시도 후 문제 재발시 관리자에게 문의해주세요.`
          );
        }
      })
      .catch((error) => {
        alert(`${error} 다시 시도 후 문제 재발시 관리자에게 문의해주세요.`);
      });
  };
  return (
    <>
      <h1 className="finduserpassword_text">비밀번호 변경</h1>
      <div className="finduserpassword_area">
        <InputComponent
          type={"text"}
          id={"username"}
          name={"username"}
          required={"required"}
          placeholder={"아이디를 입력해주세요."}
          value={id}
          onChange={(e) => handleChange(e, setId)}
          maxLength={"16"}
          ref={idRef}
        />
        <InputComponent
          type={"tel"}
          name={"mobile"}
          id={"mobile"}
          required={"required"}
          placeholder={"'-' 빼고 입력해주세요. 예) 01012345678"}
          value={phone}
          onChange={(e) => handleChange(e, setPhone)}
          maxLength={"13"}
          ref={phoneRef}
        />
        <InputComponent
          type={"text"}
          id={"password"}
          name={"password"}
          required={"required"}
          placeholder={"변경할 비밀번호를 입력해주세요."}
          value={password}
          onChange={(e) => handleChange(e, setPassword)}
          maxLength={"16"}
          ref={passwordRef}
        />
        <InputComponent
          type={"text"}
          id={"confirm-password"}
          name={"confirm-password"}
          required={"required"}
          placeholder={"비밀번호를 한 번 더 입력해 주세요."}
          value={confirmpassword}
          onChange={(e) => handleChange(e, setconfirmPassword)}
          maxLength={"16"}
          ref={confirmpasswordRef}
        />
        <p
          style={{
            fontSize: "10px",
            display: "flex",
            justifyContent: "left",
            width: "100%",
            marginBottom: "2%",
            color: "gray",
          }}
        >
          * 비밀번호 : 영문, 숫자, 특수문자 포함 8자 이상 16자이하
        </p>
      </div>
      <button
        onClick={findPasswordHandler}
        className="finduserpassword_btn"
        type="submit"
      >
        확인
      </button>
      <div className="finduserpassword_find">
        <p
          onClick={() => activeStateChangeHandler("0")}
          className="finduserpassword_find_pw"
        >
          로그인 가기
        </p>
        <p
          onClick={() => activeStateChangeHandler("1")}
          className="finduserpassword_find_pw"
        >
          아이디 찾기
        </p>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};
export default FindUserPassword;
