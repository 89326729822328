import React from "react";
import InfoOneDetailWrapper from "./InfoOneDetailWrapper";

const InfoOneContent = ({ activeContent }) => {
  return (
    <>
      <InfoOneDetailWrapper activeContent={activeContent} />
    </>
  );
};

export default React.memo(InfoOneContent);
