import React from "react";
import SamSungModalForm from "./SamSungModalForm";
import "../../assets/scss/SamSungModal.scss";

function SamSungModal({ user, title, children }) {
  return (
    <div className="saumsung_modal">
      <div className="saumsung_modal_content">
        {children}
        <SamSungModalForm user={user} title={title}></SamSungModalForm>
      </div>
    </div>
  );
}

export default React.memo(SamSungModal);
