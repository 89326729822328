import React, { useEffect, useState } from "react";
import "../../assets/scss/RecommendModal.scss";
import Button from "../customButton/Button";
import axios from "axios";
import {
  nameConsultingRegex,
  phoneConsultingRegex,
} from "../../assets/js/util/regex";
const RecommendModal = ({
  user,
  device,
  closeHandler,
  startDate,
  title,
  seq,
  dueDate,
}) => {
  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const [name, setName] = useState("");
  const isStart = startDate === null ? false : true;
  const [start, setStart] = useState(
    isStart ? startDate[0].start_date : getCurrentDate()
  );

  const [phone, setPhone] = useState("");
  const [count, setCount] = useState(1);

  useEffect(() => {
    if (user && user.isAuth === true) {
      if (user.name) {
        setName(user.name);
      }
      if (user.phone) {
        setPhone(user.phone);
      }
    }
  }, [user]);

  const nameHandler = e => {
    setName(e.target.value);
  };
  const phoneHandler = e => {
    setPhone(e.target.value);
  };
  const startHandler = e => {
    setStart(e.target.value);
  };

  const countHandler = e => {
    const { value } = e.target;
    // value의 값이 숫자가 아닐경우 빈문자열로 replace 해버림.
    const onlyNumber = value.replace(/[^0-9]/g, "");
    setCount(onlyNumber);
  };

  const registerHandler = () => {
    // 빈값 확인 validataion걸기
    const inputName = name.trim();
    const inputPhone = phone.trim();
    const inputCount = parseInt(count);

    if (isNaN(inputCount)) {
      alert("인원수에 숫자만 입력해주세요.");
      return;
    }

    if (inputCount >= 100) {
      alert(
        "인원이 100명 이상일 수는 없습니다. \n 실제 인원이 100명이 넘는 경우 하단의 관리자에게 문의주세요."
      );
      return;
    }

    if (typeof inputCount !== "number") {
      alert("인원은 오직 숫자만 입력해주세요.");
      return;
    }
    if (!name.match(nameConsultingRegex)) {
      alert(`이름은 2~10자 사이만 가능합니다.`);
      return;
    }
    if (!inputPhone.match(phoneConsultingRegex)) {
      alert(`핸드폰은 01012345678 양식으로 입력해주세요.`);
      return;
    }

    if (start > dueDate) {
      alert(
        `상품 마감기한은 ${dueDate}입니다.\n 마감기한 이내로 선택가능합니다.`
      );
      return;
    }

    let body = {
      start,
      name: inputName,
      phone: inputPhone,
      count: inputCount,
      seq,
    };
    axios
      .post("/api/recommendProduct/subsConsulting", body)
      .then(res => {
        alert(`${res.data.message}`);

        closeHandler();
      })
      .catch(err => {
        console.log(err);
        alert("알수없는 에러발생");
        closeHandler();
      });
  };

  return (
    <div className="recommend-common-modal">
      <div className="recommend-modal-content">
        <h2>{title}</h2>
        <div className="recommend-modal-content-input">
          <div className="recomment-modal-content-input-detail">
            {isStart ? (
              <>
                <label htmlFor="start-select">출발일자를 선택해주세요.</label>
                <select
                  value={start}
                  onChange={startHandler}
                  name="starts"
                  id="start-select"
                >
                  {startDate.map(start => {
                    return (
                      <option
                        key={start.START_DATE_ID}
                        value={start.start_date}
                      >
                        {start.start_date} / {start.TRAVEL_PLANS}
                      </option>
                    );
                  })}
                </select>
              </>
            ) : (
              <>
                <label htmlFor="start-select">출발일자를 입력해주세요</label>
                <input
                  type="date"
                  id="start-select"
                  max={dueDate}
                  min={getCurrentDate()}
                  value={start}
                  placeholder={getCurrentDate()}
                  onChange={startHandler}
                />
              </>
            )}
          </div>
          <div className="recomment-modal-content-input-detail">
            <span>인원: </span>
            <input
              type="number"
              min="1"
              max="100"
              value={count}
              onChange={countHandler}
            />
          </div>
          <div className="recomment-modal-content-input-detail">
            <span>이름: </span>
            <input
              value={name}
              placeholder="홍길동"
              onChange={nameHandler}
              maxLength={10}
            />
          </div>
          <div className="recomment-modal-content-input-detail">
            <span>전화번호: </span>
            <input
              value={phone}
              placeholder="01012344802"
              onChange={phoneHandler}
              maxLength="11"
            />
          </div>
        </div>
        <div className="recommend-modal-button-region">
          <Button
            device={device}
            onClick={registerHandler}
            text="상담 신청하기"
            style={{ width: "40%", height: "40px" }}
            color="s"
          />
          <Button
            device={device}
            onClick={closeHandler}
            text="취소하기"
            style={{ width: "40%", height: "40px" }}
            color="x"
          />
        </div>
      </div>
    </div>
  );
};
export default React.memo(RecommendModal);
