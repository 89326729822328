import React from "react";
import "../../assets/scss/SecureInfo.scss";

const SecureInfo = () => {
  return (
    <div className="securemap_step_explain">
      <div>
        <p>1단계 여행유의</p>
        <p>신변안전 위험 요인 숙지 및 대비</p>
      </div>
      <div>
        <p>2단계 여행자제</p>
        <p>
          여행예정자의 경우 불필요한 여행을 자제하고 체류자의 경우 신변안전에
          특별 유의
        </p>
      </div>
      <div>
        <p>3단계 출국권고</p>
        <p>
          여행예정자의 경우 여행 취소 및 연기가 필요하고 체류자의 경우 긴요한
          용무가 아닌 한 출국
        </p>
      </div>
      <div>
        <p>4단계 여행금지</p>
        <p>
          여행예정자의 경우 여행 금지 준수, 체류자의 경우 즉시 대피 및 철수 필요
        </p>
      </div>
      <div>
        <p>특별 여행주의보</p>
        <p>
          발령 기준 : 단기적으로 긴급한 위험이 있는 경우
          <br />
          행동요령 : 여행경보 2단계(여행자제) 이상 3단계(출국권고) 이하에 준함
          <br />
          기간 : 발령일로부터 최대 90일까지 유효(통상 1개월 단위로 발령)
        </p>
      </div>
    </div>
  );
};

export default React.memo(SecureInfo);
