import React, { useState, useEffect } from "react";
import withAuth from "../../hoc/auth";
import "../../assets/scss/NewMyPage.scss";
import axios from "axios";
import { _each, _returneach, toChange2 } from "../../assets/js/util/util";
import Memorydata from "./NMPmemorydata";
import NMPregisterModal from "./NMPregisterModal";
import NMPmaterialsModal from "./NMPmaterialsModal";
import { shallowEqual, useSelector } from "react-redux";
import MyPrepare from "./MyPrepare";
import HelmetComponent from "../header/HelmetComponent";
import MainTitleComponent from "../main/MainTitleComponent";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import MyPageCouponComponent from "../myPage/MyPageCouponComponent";
import ProfileComponent from "./ProfileComponent";
import { triangleIcon } from "../../assets/js/util/apiConstant";
import Button from "../customButton/Button";

function NewMyPage() {
  const user = useSelector((state) => {
    return state.users.user;
  }, shallowEqual);

  const [reSchedule, setReschedule] = useState(false);
  const [schedules, setSchedules] = useState([]);

  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showMaterialsModal, setShowMaterialsModal] = useState(false);

  const [prepareModalIndex, setPrepareModalIndex] = useState(null);

  useEffect(() => {
    axios.get("/api/plan/myPlanner").then((res) => {
      setSchedules(Array.isArray(res.data.data) ? res.data.data : []);
    });
  }, [user, reSchedule]);

  const openRegisterModal = () => setShowRegisterModal(true);
  const closeRegisterModal = () => setShowRegisterModal(false);

  const openMaterialsModal = (e, index) => {
    setShowMaterialsModal(true);
    setPrepareModalIndex(index + 1);
  };
  const closeMaterialsModal = () => {
    setShowMaterialsModal(false);
    setPrepareModalIndex(null);
  };

  // 내용을 표시하거나 숨기는 함수
  const toggleContent = (index) => {
    setSchedules((prev) =>
      prev.map((schedule, idx) =>
        idx === index
          ? { ...schedule, showContent: !schedule.showContent }
          : schedule
      )
    );
  };

  return (
    <>
      <HelmetComponent title="My Link" />
      <ProfileComponent user={user} />
      <MyPageCouponComponent />
      <MyPrepare user={user} openMaterialsModal={openMaterialsModal} />

      {showMaterialsModal && !!prepareModalIndex && (
        <NMPmaterialsModal
          prepareModalIndex={prepareModalIndex}
          onClose={closeMaterialsModal}
        />
      )}

      <div>
        <div className="infoone_FL_sec">
          <MainTitleComponent
            title="일정 세우기"
            divClass="infoone_FL_title_sec"
            pClass="infoone_FL_title"
          />
          <div className="newmypage_schedule_summary">
            {Array.isArray(schedules) &&
              schedules.map((schedule, index) => (
                <div
                  className="newmypage_schedule_summary_card"
                  onClick={() => toggleContent(index)}
                  key={index}
                >
                  <div>
                    <div className="newmypage_schedule_titlesec">
                      <div>
                        <p>{schedule.TRAVEL_COUNTRY}</p>
                      </div>
                      <div>
                        <p>{toChange2(schedule.CREATED_AT)}</p>
                        <img
                          style={{
                            transform: schedule.showContent
                              ? "rotate(0deg)"
                              : "rotate(180deg)",
                          }}
                          src={convertS3toCloudFront(triangleIcon)}
                        />
                      </div>
                    </div>
                  </div>
                  {schedule.showContent && (
                    <Memorydata
                      reSchedule={reSchedule}
                      schedule={schedule}
                      setReschedule={setReschedule}
                    />
                  )}
                </div>
              ))}
          </div>
          <Button
            className={"newmypage_schedule_plus"}
            style={{ height: "30px" }}
            text={"+ 새 일정 등록하기"}
            color={"x"}
            position={"c"}
            onClick={openRegisterModal}
          />
        </div>
      </div>
      {showRegisterModal && (
        <NMPregisterModal user={user} onClose={closeRegisterModal} />
      )}
    </>
  );
}
export default withAuth(NewMyPage, false);
