import React, { useState } from "react";
import LoginComponent from "./LoginComponent";
import FindUserId from "./FindUserId";
import withAuth from "../../hoc/auth";
import FindUserPassword from "./FindUserPassword";
import { handleClick } from "../../assets/js/util/navigation";
import { useNavigate } from "react-router-dom";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import HelmetComponent from "../header/HelmetComponent";
import "../../assets/scss/Login.scss";
import AdSense from "../AdSense/DisplayAdHoriz";

function Login() {
  const navigate = useNavigate();
  // 0 :login , 1: id찾기, 2: password찾기
  const [activeState, setActiveState] = useState("0");

  const activeStateChangeHandler = (active) => {
    setActiveState(active);
  };

  return (
    <>
      <HelmetComponent title="Login" />
      <div className="login_sec_all">
        <div className="loginbox">
          {activeState === "0" && (
            <LoginComponent
              activeStateChangeHandler={activeStateChangeHandler}
            />
          )}
          {activeState === "1" && (
            <FindUserId activeStateChangeHandler={activeStateChangeHandler} />
          )}
          {activeState === "2" && (
            <FindUserPassword
              activeStateChangeHandler={activeStateChangeHandler}
            />
          )}
        </div>
        <div className="loginsign">
          <img
            className="loginbg"
            src={convertS3toCloudFront(
              "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/loginbg.png"
            )}
          ></img>
          <h1 className="loginsign_text">아직 회원이 아니신가요?</h1>
          <p className="loginsign_text2">
            회원가입을 하시면 국내외 여행자보험 무료가입 혜택을 받을 수
            있습니다.
          </p>
          <button
            onClick={() => handleClick("/Cert", navigate)}
            className="loginsign_btn"
            type="submit"
          >
            회원가입
          </button>
        </div>
      </div>
      <AdSense />
    </>
  );
}

export default withAuth(Login, true);
