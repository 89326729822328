import React, { useEffect, useState } from "react";
// import "../../assets/scss/terms/ThirdPartyConsentModal.scss";
import "../../assets/scss/ModalXPrivacyComponent.scss";
import { TERMS } from "../../assets/js/TERMS/terms_v4";
import { useMemo } from "react";
import CustomCheckBox from "../customButton/CustomCheckBox";
import { _keys } from "../../assets/js/util/util";
import {
  ksjCompany,
  postMNC,
  shinhanLife,
  withus,
  koreana,
  merchant,
} from "../../assets/js/util/insurance_company";
import {
  ERR01_NOTYPE,
  NEED_THIRD_AGREE,
  toAdmin,
} from "../../assets/js/util/message";

const ThirdPartyConsentModal = ({
  notAgreeInsurance,
  agreeEvent,
  cancleButton,
  device,
}) => {
  const [allAgreeStatus, setAllCheckBox] = useState(false);
  const [agreedTerms, setAgreedTerms] = useState({});
  const [checkBoxControl, setCheckBoxControl] = useState("");

  const terms = useMemo(() => TERMS.terms.slice(3), []);
  // agreeTerms의 초기화.
  useEffect(() => {
    const initialAgreedTerms = notAgreeInsurance.reduce((acc, item, index) => {
      acc[item] = false;
      return acc;
    }, {});

    setAgreedTerms(prevAgreedTerms => ({
      ...prevAgreedTerms,
      ...initialAgreedTerms,
    }));
  }, []);

  // observer 전체동의에대한
  useEffect(() => {
    setAllCheckBox(Object.values(agreedTerms).every(v => v));
  }, [agreedTerms]);

  const updateTermsType = id => {
    setAgreedTerms(prevStatus => {
      const updateStatus = { ...prevStatus };
      updateStatus[id] = !updateStatus[id];
      return updateStatus;
    });
    setCheckBoxControl("");
  };

  const handleCheckBoxChange = (e, id, name) => {
    setCheckBoxControl(id);
  };

  const handleAllCheckBox = (e, id, name) => {
    if (allAgreeStatus) {
      // 모두동의인 경우.
      setAllCheckBox(false);

      setAgreedTerms(prevStatus => {
        const updateStatus = { ...prevStatus };
        Object.keys(updateStatus).forEach(key => {
          updateStatus[key] = false;
        });
        return updateStatus;
      });
    } else {
      // 모두동의가 아닌 경우.
      setAllCheckBox(true);
      setAgreedTerms(prevStatus => {
        const updateStatus = { ...prevStatus };
        Object.keys(updateStatus).forEach(key => {
          updateStatus[key] = true;
        });
        return updateStatus;
      });
    }
  };

  const agreeValidation = () => {
    // validation
    if (!allAgreeStatus) {
      alert(`${NEED_THIRD_AGREE}`);
      return;
    }
    agreeEvent();
  };

  // 보험사 개별 선택한 경우의 분기 처리.
  useEffect(() => {
    if (checkBoxControl !== "") {
      switch (checkBoxControl) {
        case shinhanLife:
          updateTermsType(shinhanLife);
          break;
        case withus:
          updateTermsType(withus);
          break;
        case postMNC:
          updateTermsType(postMNC);
          break;
        case ksjCompany:
          updateTermsType(ksjCompany);
          break;
        case koreana:
          updateTermsType(koreana);
          break;
        case merchant:
          updateTermsType(merchant);
          break;
        default:
          alert(`${ERR01_NOTYPE}\n ${toAdmin}`);
          break;
      }
    }
  }, [checkBoxControl]);

  return (
    <React.Fragment>
      <CustomCheckBox
        onChange={handleAllCheckBox}
        id="all"
        name="개인정보 제3자 제공(유상) 전체동의 (필수)"
        checked={allAgreeStatus}
      />
      <div className="agr_title1">
        <div className="toggle_sec_all">
          <div className="term_container">
            {notAgreeInsurance.map((company, index) => {
              const findData = terms.find(x => x.company === company);
              if (!findData) {
                return alert("변순데?");
              }
              return (
                <React.Fragment key={index}>
                  <CustomCheckBox
                    onChange={handleCheckBoxChange}
                    name={findData.title + " (필수)"}
                    id={company}
                    checked={
                      agreedTerms[company] === undefined
                        ? false
                        : agreedTerms[company]
                    }
                  />
                  <div
                    className="term_container"
                    dangerouslySetInnerHTML={{ __html: findData.description }}
                  ></div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
      <div className="modalx_btn_sec">
        <button onClick={cancleButton} className="modalx_n_btn">
          {device === "mobile"
            ? "이벤트 미참여"
            : "여행자 보험 무료가입이벤트 미참여"}
        </button>
        <button onClick={() => agreeValidation()} className="modalx_y_btn">
          동의하기
        </button>
      </div>
    </React.Fragment>
  );
};
export default React.memo(ThirdPartyConsentModal);
