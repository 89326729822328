import React from "react";
import "../../assets/scss/Button.scss";

const Button = ({
  className,
  color,
  device,
  position,
  location,
  style,
  onClick,
  text,
}) => {
  let classes = [];
  if (className) classes.push(className);
  if (color) classes.push(`${color}_color`);
  if (device) classes.push(`${device}_button`);
  if (position) classes.push(`${position}_position_button`);
  if (location) classes.push(`${location}_button`);

  return (
    <button style={style} onClick={onClick} className={classes.join(" ")}>
      {text}
    </button>
  );
};

export default React.memo(Button);
