import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { navSettingIcon, travellogo } from "../../assets/js/util/apiConstant";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import axios from "axios";
import "../../assets/scss/ProfileComponent.scss";
import Button from "../customButton/Button";

const ProfileComponent = ({ user }) => {
  const navigate = useNavigate();
  const nickNameRef = useRef();
  const [reProfileData, setReProfileData] = useState(false);
  const [clickEditNickName, setClickEditNickName] = useState(false);
  const [nickName, setNickName] = useState("");

  const [profileData, setProfileData] = useState([]);

  const shouldRenderProfileDelete = profileData.length !== 0;

  useEffect(() => {
    if (user && user.isAuth) {
      try {
        axios.get("/api/profile/getProfile").then((res) => {
          setProfileData(res.data.data);
        });
      } catch (err) {
        console.error("프로필 이미지를 불러오는 데 실패했습니다.", err);
        alert("프로필 이미지를 불러오는 데 실패했습니다");
        window.location.reload();
      }
    }
  }, [user, reProfileData]);

  const handleProfileEdit = () => {
    document.getElementById("fileInput").click();
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      alert("파일을 선택해 주세요.");
      return;
    }
    const formData = new FormData();
    formData.append("imgData", file);

    try {
      axios
        .post("/api/profile/profileEdit", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          alert(res.data.message);
          if (res.data.success) {
            setReProfileData(!reProfileData);
          } else {
            window.location.reload();
          }
        })
        .catch((err) => {
          alert(err.response.data.error);
          window.location.reload();
        });
    } catch (error) {
      alert("이미지 업로드 중 오류 발생:", error);
      window.location.reload();
    }
  };

  const clickEditNickNameHandler = () => {
    setClickEditNickName(!clickEditNickName);
  };

  const profileDeletHandler = () => {
    try {
      if (confirm("프로필 사진을 삭제하시겠습니까?")) {
        axios.get("/api/profile/deleteProfile").then((res) => {
          alert(`${res.data.message}`);
          if (res.data.success) {
            setReProfileData(!reProfileData);
          } else {
            window.location.reload();
          }
        });
      }
    } catch (err) {
      console.log(err);
      window.location.reload();
    }
  };

  const nickNameChangeHandler = (e) => {
    setNickName(e.target.value);
  };

  const editNickNameHandler = () => {
    try {
      if (nickName.length === 0) {
        alert("닉네임을 입력해주세요.");
        nickNameRef.current.focus();
        return;
      }
      if (nickName.length > 25) {
        alert("닉네임은 25자리수 보다 작아야합니다.");
        nickNameRef.current.focus();
        return;
      }

      axios.get(`/api/profile/editNickName/${nickName}`).then((res) => {
        if (res.data.success) {
          alert(`${res.data.message}`);
          setNickName("");
          setClickEditNickName(false);
          setReProfileData(!reProfileData);
        } else {
          alert(`${res.data.message}`);
          if (res.data.data === 2700) {
            setNickName("");
            setClickEditNickName(true);
          } else {
            window.location.reload();
          }
        }
      });
    } catch (err) {
      alert(err);
      window.location.reload();
    }
  };

  const cancleTitleEdit = () => {
    setClickEditNickName(false);
    setNickName("");
  };

  return (
    <div className="newmypage_user_info">
      <div className="nmp_user_profilesec" onClick={handleProfileEdit}>
        <img
          className="nmp_user_profile"
          src={convertS3toCloudFront(
            profileData.length > 0 ? profileData[0].PROFILE_IMAGE : travellogo
          )}
          alt="나의 프로필 사진"
        />
        <Button className={"nmp_user_profile_audit"} text={"편집"} />
      </div>
      <input
        type="file"
        id="fileInput"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <div className="newmypage_profile_name">
        {clickEditNickName ? (
          <>
            <div className="newmypage_nickname_profile_edit_input">
              <input
                placeholder={
                  profileData.length > 0 &&
                  profileData[0] &&
                  profileData[0].NICKNAME
                    ? profileData[0].NICKNAME
                    : ""
                }
                value={nickName}
                onChange={nickNameChangeHandler}
                ref={nickNameRef}
              />
              <div className="newmypage_nickname_profile_edit_sec">
                <Button
                  text={"취소"}
                  onClick={cancleTitleEdit}
                  location={"f"}
                  style={{ margin: "auto 5% 0 0", padding: "0" }}
                />
                <Button
                  text={"저장하기"}
                  onClick={editNickNameHandler}
                  style={{ padding: "0" }}
                />
              </div>
            </div>
            <img
              className="newmypage_profile_setting_img"
              src={convertS3toCloudFront(navSettingIcon)}
              alt=""
              onClick={() => navigate(`/Mypage`)}
            />
          </>
        ) : (
          <>
            <div className="newmypage_nick_edit_sec">
              <div className="newmypage_nick_edit_sec_saved">
                <p className="newmypage_nick_edit_sec_p">
                  {profileData.length > 0 ? profileData[0].NICKNAME : user.name}
                </p>
                <img
                  className="newmypage_profile_setting_img"
                  src={convertS3toCloudFront(navSettingIcon)}
                  alt="navSetting_img"
                  onClick={() => navigate(`/Mypage`)}
                />
              </div>
              <div className="newmypage_nickname_profile_edit_sec">
                <Button
                  text={
                    profileData && profileData[0] && profileData[0].NICKNAME
                      ? "닉네임수정"
                      : "닉네임등록"
                  }
                  onClick={clickEditNickNameHandler}
                  style={{ padding: "0" }}
                />
                <Button
                  text={
                    shouldRenderProfileDelete &&
                    !profileData[0].PROFILE_IMAGE.includes("default")
                      ? "프로필사진삭제"
                      : null
                  }
                  onClick={profileDeletHandler}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(ProfileComponent);
