import React, { useState } from "react";
import withAuth from "../../hoc/auth";
import SecureTravelSecure from "./SecureTravel_Secure";
import SecureTravelInsuracne from "./SecureTravel_Insurance";
import "../../assets/scss/SecureTravel.scss";
import HelmetComponent from "../header/HelmetComponent";
import CategoryCustomButtonGroup from "../customButton/CategoryCustomButtonGroup";
import CategoryCustomButton from "../customButton/CategoryCustomButton";

// const secureTravelConfig = {
//   containerClass: "secure_category_sec",
//   buttons: [
//     {
//       title: "안전한 여행",
//       compareContent: "Secure",
//       baseClassName: "secure_category_button",
//     },
//     {
//       title: "여행자 보험",
//       compareContent: "Insurance",
//       baseClassName: "secure_category_button",
//     },
//   ],
//   components: {
//     Secure: <SecureTravelSecure />,
//     Insurance: <SecureTravelInsuracne />,
//   },
// };

const SecureTravel = () => {
  const [activeContent, setActiveContent] = useState("Secure");

  const handleClick = (content) => {
    setActiveContent(content);
  };

  return (
    <>
      <HelmetComponent title="안전여행" />
      <div className="secure_all">
        <div className="secure_category_back">
          <div className="secure_category_sec">
            <CategoryCustomButton
              title="안전한 여행"
              handleClick={handleClick}
              activeContent={activeContent}
              compareContent="Secure"
              baseClassName="secure_category_button"
            />
            <CategoryCustomButton
              title="여행자 보험"
              handleClick={handleClick}
              activeContent={activeContent}
              compareContent="Insurance"
              baseClassName="secure_category_button"
            />
          </div>
        </div>
        {activeContent === "Secure" && <SecureTravelSecure />}
        {activeContent === "Insurance" && <SecureTravelInsuracne />}
      </div>
    </>
  );
};

export default withAuth(SecureTravel, null);
