import React from "react";
import "../../assets/scss/LinkButtonAnnoun.scss";

const LinkButtonAnnoun = ({ handleOtherClick, text, type, isSelected }) => {
  const handleClick = () => {
    handleOtherClick(type);
  };

  const buttonClass = isSelected ? "announ_selected" : "announ_unselected";

  return (
    <button onClick={handleClick} className={buttonClass}>
      {text}
    </button>
  );
};

export default LinkButtonAnnoun;
