import React, { useState, useRef, useEffect, useCallback } from "react";
import "../../assets/scss/MemoryRegisterModal2.scss";
import { _each, _returneach } from "../../assets/js/util/util";
import "react-toastify/dist/ReactToastify.css";
import { SliderPicker } from "react-color";
import MemoryUpload from "./MemoryUpload";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import axios from "axios";

function MemoryRegisterModal2({
  onClose,
  onSelectColor,
  data,
  setRemyMemoryData,
  reMyMeoryData,
  setShowModal,
}) {
  const [fileData, setFile] = useState([]);
  const [resetPreview, setResetPreview] = useState(null);

  const [color, setColor] = useState("#000000");
  const [description, setDescripition] = useState("");

  const descriptionHandler = (e) => {
    setDescripition(e.target.value);
  };

  const handleColorChange = (colorEvent) => {
    const selectedColor = colorEvent.hex;
    setColor(selectedColor);
    onSelectColor(selectedColor);
  };

  const formatDateForInput = (dateString) => {
    return `${dateString.slice(0, 4)}-${dateString.slice(
      4,
      6
    )}-${dateString.slice(6, 8)}`;
  };
  const memoryRegister = () => {
    if (description.length === 0) {
      alert("설명을 입력해주세요");
      return;
    }
    if (description.length > 100) {
      alert("설명은 100자까지 가능합니다");
      return;
    }

    if (!fileData[0]) {
      alert("파일을 업로드해주세요.");
      return;
    }
    const formData = new FormData();
    formData.append("TITLE", data.TITLE);
    formData.append("START_DATE", formatDateForInput(data.TRAVEL_START_DATE));
    formData.append("END_DATE", formatDateForInput(data.TRAVEL_END_DATE));
    formData.append("DESCRIPTION", description);
    formData.append("COLOR", color);
    formData.append("imgData", fileData[0]);
    formData.append("TRAVEL_COUNTRY", data.TRAVEL_COUNTRY);
    try {
      axios
        .post("/api/memory/register", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          alert(res.data.message);
          if (res.data.success) {
            setRemyMemoryData(!reMyMeoryData);
            setShowModal(false);
            onClose();
          } else {
            window.location.reload();
          }
        });
    } catch (error) {
      alert(error);
      window.location.reload();
    }
  };

  return (
    <>
      <div className="memory2_background">
        <div className="memory2_content">
          <div className="memory2_register_sec">
            <div className="memory2_register_sec_detail">
              <p>제목</p>
              <input readOnly value={data.TITLE} placeholder="제목" />
            </div>
            <div className="memory2_register_sec_detail">
              <p>여행기간</p>
              <div className="memory2_register_sec_detail_date">
                <input
                  readOnly
                  type="date"
                  value={formatDateForInput(data.TRAVEL_START_DATE)}
                />
                <input
                  readOnly
                  type="date"
                  value={formatDateForInput(data.TRAVEL_END_DATE)}
                />
              </div>
            </div>
            <div className="memory2_register_sec_detail">
              <p>여행국가</p>
              <input readOnly type="text" value={data.TRAVEL_COUNTRY} />
            </div>
          </div>
          <div className="memory2_source_register">
            <MemoryUpload
              alreadyUpload={false}
              buttonText={
                <div className="register_upload_sec">
                  <div className="register_plus_img_sec">
                    <img
                      className="register_plus_img"
                      src={convertS3toCloudFront(
                        "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/plus_image.png"
                      )}
                    />
                  </div>
                  <div className="register_upload_text">이미지 업로드</div>
                </div>
              }
              setFile={setFile}
              resetPreview={setResetPreview}
            />
            <textarea value={description} onChange={descriptionHandler} />
          </div>
          <div className="memory2_content_color">
            <div className="memory2_content_color_title">
              <p>선택한 지역 색칠하기</p>
            </div>
            <div className="memory2_content_color_select">
              <div>
                <SliderPicker color={color} onChange={handleColorChange} />
                <p>{color}</p>
              </div>
            </div>
          </div>
          <div className="memory2_btnsec">
            <button onClick={onClose}>닫기</button>
            <button onClick={memoryRegister}>등록하기</button>
          </div>
        </div>
      </div>
    </>
  );
}
export default React.memo(MemoryRegisterModal2);
