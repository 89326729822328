import React, { useState } from "react";
import Modal from "react-modal";
import "../../assets/scss/SamSungWarranty.scss";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import { useDeviceScreen } from "../../assets/js/util/useDeviceScreen";

function SamSungNotWarranty() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const device = useDeviceScreen();
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      <div className="warranty_detail_sec">
        <button onClick={openModal}>보상하지않는손해 별첨 확인</button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        appElement={document.getElementById("root")}
        style={{
          content: {
            margin: device === "mobile" ? "10% auto 0 auto" : "0 auto",
          },
        }}
      >
        <div className="warranty_detail_all">
          <button className="warranty_detail_closebtn_sec" onClick={closeModal}>
            <img
              className="warranty_detail_closebtn"
              src={convertS3toCloudFront(
                "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/menu_close.svg"
              )}
            />
          </button>
          <div>
            <h1>보상하지않는 사항 요약본</h1>
            <div>
              <span class="font14_600">
                아래 내용은 보상하지않는 사항 요약본으로 추가 및 자세한 사항은
                꼭 약관을 확인하시기 바랍니다.
              </span>
            </div>
            <br />
            <table>
              <tbody>
                <tr>
                  <td colSpan={2}>보장명</td>
                  <td colSpan={3}>보상하지 않는 손해</td>
                </tr>
                <tr>
                  <td colSpan={2} rowSpan={9}>
                    공통
                  </td>
                  <td className="left_align" colSpan={3}>
                    1. 피보험자가 고의로 자신을 해친 경우. 다만, 피보험자가
                    심신상실 등으로 자유로운 의사결정을 할 수 없는 상태에서
                    자신을 해친 경우에는 보험금을 지급합니다.
                  </td>
                </tr>
                <tr>
                  <td className="left_align" colSpan={3}>
                    2. 보험수익자가 고의로 피보험자를 해친 경우. 다만, 그
                    보험수익자가 일부 보험수익자인 경우에는 다른 보험수익자에
                    대한 보험금은 지급합니다.
                  </td>
                </tr>
                <tr>
                  <td className="left_align" colSpan={3}>
                    3. 계약자가 고의로 피보험자를 해친 경우
                  </td>
                </tr>
                <tr>
                  <td className="left_align" colSpan={3}>
                    4. 피보험자가 임신, 출산(제왕절개를 포함합니다), 산후기.
                    그러나, 회사가 보장하는 보험금 지급사유와 보장개시일로부터
                    2년이 지난 후에 발생한 습관성 유산, 불임 및 인공수정 관련
                    합병증으로 인한 경우에는 지급합니다.
                  </td>
                </tr>
                <tr>
                  <td className="left_align" colSpan={3}>
                    5. 전쟁, 외국의 무력행사, 혁명, 내란, 사변, 폭동
                  </td>
                </tr>
                <tr>
                  <td className="left_align" colSpan={3}>
                    6. 회사는 다른 약정이 없으면 피보험자가 직업, 직무 또는
                    동호회 활동목적으로 아래에 열거된 행위로 인하여
                    제3조(보험금의 지급사유)의 상해 관련 보험금 지급사유가
                    발생한 때에는 해당 보험금을 지급하지 않습니다.
                  </td>
                </tr>
                <tr>
                  <td className="left_align" colSpan={3}>
                    &nbsp;&nbsp; ① 전문등반(전문적인 등산용구를 사용하여 암벽
                    또는 빙벽을 오르내리거나 특수한 기술, 경험, 사전훈련을
                    필요로 하는 등반을 말합니다), 글라이더 조종, 스카이다이빙,
                    스쿠버다이빙, 행글라이딩, 수상보트, 패러글라이딩
                  </td>
                </tr>
                <tr>
                  <td className="left_align" colSpan={3}>
                    &nbsp;&nbsp; ② 모터보트, 자동차 또는 오토바이에 의한 경기,
                    시범, 흥행(이를 위한 연습을 포함합니다) 또는 시운전(다만,
                    공용도로상에서 시운전을 하는 동안 보험금 지급사유가 발생한
                    경우에는 보장합니다)
                  </td>
                </tr>
                <tr>
                  <td className="left_align" colSpan={3}>
                    &nbsp;&nbsp; ③ 선박에 탑승하는 것을 직무로 하는 사람이
                    직무상 선박에 탑승하고 있는 동안
                  </td>
                </tr>
                <tr>
                  <td rowSpan={4}>기본형 해외여행 실손의료비 특별약관</td>
                  <td class="center_align">공통</td>
                  <td colSpan={3}>
                    <p>
                      1. 피보험자가 고의로 자신을 해친 경우. 다만, 피보험자가
                      심신상실 등으로 자유로운 의사결정을 할 수 없는 상태에서
                      자신을 해친 경우에는 보험금을 지급합니다.
                      <br />
                      2. 보험수익자가 고의로 피보험자를 해친 경우. 다만, 그
                      보험수익자가 일부 보험수익자인 경우에는 다른 보험수익자에
                      대한 보험금은 지급합니다.
                      <br />
                      3. 계약자가 고의로 피보험자를 해친 경우
                      <br />
                      4. 피보험자가 임신, 출산(제왕절개를 포함합니다), 산후기.
                      그러나, 회사가 보장하는 보험금 지급사유와 보장개시일로부터
                      2년이 지난 후에 발생한 습관성 유산, 불임 및 인공수정 관련
                      합병증으로 인한 경우에는 지급합니다.
                      <br />
                      5. 전쟁, 외국의 무력행사, 혁명, 내란, 사변, 폭동
                      <br />
                      6. 피보험자가 정당한 이유없이 입원기간 중 의사의 지시를
                      따르지 않거나 의사가 통원치료가 가능하다고 인정함에도
                      피보험자 본인이 자의적으로 입원하여 발생한 입원의료비
                      <br />
                      7. 피보험자가 정당한 이유없이 통원기간 중 의사의 지시를
                      따르지 않아 발생한 통원의료비
                      <br />
                      8. 회사는 다른 약정이 없으면 피보험자가 직업, 직무 또는
                      동호회 활동 목적으로 한 다음의 어느 하나에 해당하는 행위로
                      인하여 생긴 상해에 대해서는 보상하지 않습니다. (상해)
                      <br />
                      &nbsp;&nbsp; ① 전문등반(전문적인 등산용구를 사용하여 암벽
                      또는 빙벽을 오르내리거나 특수한 기술, 경험, 사전훈련을
                      필요로 하는 등반을 말합니다), 글라이더 조종, 스카이다이빙,
                      스쿠버다이빙, 행글라이딩, 수상보트, 패러글라이딩
                      <br />
                      &nbsp;&nbsp; ② 모터보트, 자동차 또는 오토바이에 의한 경기,
                      시범, 흥행(이를 위한 연습을 포함합니다) 또는 시운전(다만,
                      공용도로상에서 시운전을 하는 동안 보험금 지급사유가 발생한
                      경우에는 보장합니다)
                      <br />
                      &nbsp;&nbsp; ③ 선박에 탑승하는 것을 직무로 하는 사람이
                      직무상 선박에 탑승하고 있는 동안
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>해외상해의료비</td>
                  <td colSpan={3}>
                    <p>
                      1.건강검진(단, 검사결과 이상 소견에 따라 건강검진센터
                      등에서 발생한 추가 의료비용은 보상합니다), 예방접종,
                      인공유산에 든 비용. <br />
                      다만, 회사가 보상하는 상해 치료를 목적으로 하는 경우에는
                      보상합니다.
                      <br />
                      2.영양제, 비타민제, 호르몬 투여, 보신용 투약, 친자 확인을
                      위한 진단, 불임검사, 불임수술, 불임복원술,
                      보조생식술(체내,체외 인공수정을 포함합니다), 성장촉진,
                      의약외품과 관련하여 소요된 비용. 다만, 회사가 보상하는
                      상해 치료
                      <br />
                      3.의치, 의수족, 의안, 안경, 콘텍트렌즈, 보청기, 목발,
                      팔걸이(Arm Sling), 보조기 등 진료재료의 구입 및 대체비용.
                      다만, 인공장기 등 신체에 이식되어 그 기능을 대신하는
                      경우에는 보상합니다.
                      <br />
                      4.외모개선 목적의 치료로 인하여 발생한 의료비
                      <br />
                      5.진료와 무관한 각종 비용(TV시청료, 전화료, 각종 증명료
                      등을 말합니다), 의사의 임상적 소견과 관련이 없는 검사비용.
                      간병비
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>국내상해비급여의료비</td>
                  <td colSpan={3}>
                    <p>
                      1. 「국민건강보험법」에 따른 요양급여 중 본인부담금의 경우
                      국민건강보험 관련 법령에 따라 국민건강보험공단으로부터
                      사전 또는 사후 환급이 가능한 금액(본인부담금 상한제)
                      <br />
                      2. 「의료급여법」에 따른 의료급여 중 본인부담금의 경우
                      의료급여 관련 법령에 따라 의료급여기금 등으로부터 사전
                      또는 사후 환급이 가능한 금액(「의료급여법」에 따른
                      본인부담금 보상제 및 본인부담금 상한제)
                      <br />
                      3. 자동차보험(공제를 포함합니다)에서 보상받는
                      치료관계비(과실상계 후 금액을 기준으로 합니다) 또는
                      산재보험에서 보상받는 의료비. 다만,
                      본인부담의료비(자동차보험 진료수가에 관한 기준 및 산재보험
                      요양급여 산정기준에 따라 발생한」 실제 본인 부담의료비)는
                      약관에 따라 보상합니다.
                      <br />
                      4. 「응급의료에 관한 법률및 동 시행규칙에서 정한
                      응급환자에 해당하지 않는 자가 동법 제26조 권역응급의료센터
                      또는 「의료법」제3조의4에 따른 상급종합병원 응급실을
                      이용하면서 발생한 응급의료관리료로서 전액본인부담 의료비에
                      해당하는 의료비
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>국내질병급여의료비</td>
                  <td colSpan={3}>
                    <p>
                      1. 정신 및 행동장애(F04∼F99). 다만, F04∼F09, F20∼F29,
                      F30∼F39, F40∼F48, F51, F90∼F98과 관련한 치료에서 발생한
                      「국민건강보험법」에 따른 요양급여에 해당하는 의료비는
                      보상합니다.
                      <br />
                      2. 여성생식기의 비염증성 장애로 인한 습관성 유산, 불임 및
                      인공수정관련 합병증(N96∼N98) 으로 발생한 의료비 중
                      전액본인부담 의료비 및 보험가입일로부터 2년 이내에 발생한
                      의료비
                      <br />
                      3. 피보험자가 임신, 출산(제왕절개를 포함합니다), 산후기로
                      입원 또는 통원한 경우(O00∼O99)
                      <br />
                      4. 선천성 뇌질환(Q00∼Q04). 다만, 피보험자가 보험가입당시
                      태아인 경우에는 보상합니다.
                      <br />
                      5. 요실금(N39.3, N39.4, R32)
                      <br />
                      6. 「국민건강보험법」에 따른 요양급여 중 본인부담금의 경우
                      국민건강보험 관련 법령에 따라 국민건강보험공단으로부터
                      사전 또는 사후 환급이 가능한 금액(본인부담금 상한제)
                      <br />
                      7. 「의료급여법」에 따른 의료급여 중 본인부담금의 경우
                      의료급여 관련 법령에 따라 의료급여기금 등으로부터 사전
                      또는 사후 환급이 가능한 금액(「의료급여법」에 따른
                      본인부담금 보상제 및 본인부담금 상한제)
                      <br />
                      8. 성장호르몬제 투여에 소요된 비용으로 부담한
                      전액본인부담금
                      <br />
                      9. 산재보험에서 보상받는 의료비. 다만,
                      본인부담의료비(산재보험 요양급여 산정기준에 따라 발생한
                      실제 본인 부담의료비)는 제3조(보장종목별 보상내용)
                      (2)질병급여 제1항, 제3항부터 제8항에 따라 보상합니다.
                      <br />
                      10. 사람면역결핍바이러스(HIV) 감염으로 인한 치료비(다만,
                      「의료법」에서 정한 의료인의 진료상 또는 치료중 혈액에
                      의한 HIV 감염은 해당 진료기록을 통해 객관적으로 확인되는
                      경우는 보상합니다)
                      <br />
                      11. 「응급의료에 관한 법률」 및 동법 시행규칙에서 정한
                      응급환자에 해당하지 않는 자가 동법 제26조 권역응급의료센터
                      또는 「의료법」제3조의4에 따른 상급종합병원 응급실을
                      이용하면서 발생한 응급의료관리료로서 전액본인부담의료비에
                      해당하는 의료비
                    </p>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3} rowSpan={10}>
                    여행중 배상책임 특별약관
                  </td>
                  <td class="left_align">
                    1.피보험자의 직접적인 직무수행으로 인한 배상책임
                  </td>
                </tr>
                <tr>
                  <td class="left_align">
                    2.피보험자의 직무용으로만 사용되는 동산의 소유, 사용 또는
                    관리로 인한 배상책임
                  </td>
                </tr>
                <tr>
                  <td class="left_align">
                    3.피보험자가 소유, 사용 또는 관리하는 부동산으로 인한
                    배상책임
                  </td>
                </tr>
                <tr>
                  <td class="left_align">
                    4.피보험자의 근로자가 피보험자의 업무에 종사중에 입은 신체의
                    장해로 인한 배상책임. 단, 피보험자의 가사사용인에 대하여는
                    이와 같지 않습니다.
                  </td>
                </tr>
                <tr>
                  <td class="left_align">
                    5.피보험자와 타인간에 손해배상에 관한 약정이 있는 경우 그
                    약정에 따라 가중된 배상책임
                  </td>
                </tr>
                <tr>
                  <td class="left_align">
                    6.피보험자와 세대를 같이하는 친족(「민법 제777조」에 따른
                    8촌 이내의 혈족, 4촌 이내의 인척 및 배우자) 및 여행과정을
                    같이 하는 친족에 대한 배상책임
                  </td>
                </tr>
                <tr>
                  <td class="left_align">
                    7.피보험자가 소유, 사용 또는 관리하는 재물의 파손에 대하여
                    그 재물에 대하여 정당한 권리를 가진 사람에게 부담하는
                    배상책임. 단, 호텔의 객실이나 객실내의 동산에 끼치는 손해에
                    대하여는 이와 같지 않습니다.
                  </td>
                </tr>
                <tr>
                  <td class="left_align">
                    8.피보험자의 심신상실로 인한 배상책임
                  </td>
                </tr>
                <tr>
                  <td class="left_align">
                    9.피보험자 또는 피보험자의 지시에 따른 폭행 또는 구타로 인한
                    배상책임
                  </td>
                </tr>
                <tr>
                  <td class="left_align">
                    10.항공기, 선박, 차량(원동력이 인렫ㄱ에 의한 것을
                    제외합니다), 총기(공기총은 제외합니다)의 소유, 사용 또는
                    관리로 인한 배상책임
                  </td>
                </tr>
                <tr>
                  <td colSpan={3} rowSpan={4}>
                    여행중 중대사고 구조송환비용 특별약관
                  </td>
                  <td class="left_align">
                    1.피보험자가 고의로 자신을 해친 경우. 다만, 피보험자가
                    심신상실 등으로 자유로운 의사결정을 할 수 없는 상태에서
                    자신을 해친 경우에는 보험금을 지급합니다.
                  </td>
                </tr>
                <tr>
                  <td class="left_align">
                    2.보험수익자가 고의로 피보험자를 해친 경우. 다만, 그
                    보험수익자가 보험금의 일부 보험수익자인 경우에는 다른 보험
                    수익자에 대한 보험금은 지급됩니다.
                  </td>
                </tr>
                <tr>
                  <td class="left_align">
                    3.계약자가 고의로 피보험자를 해친 경우
                  </td>
                </tr>
                <tr>
                  <td class="left_align">
                    4.회사는 비용 중 정당하다고 인정된 부분에 대해서만 보상하여
                    드리며, 계약자, 피보험자 또는 보험수익자가 타인으로부터
                    손해배상을 받을 수 있는 경우에는 그 금액을 지급하지
                    않습니다.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="warranty_detail_bottom_closebtn_sec">
            <button
              className="warranty_detail_bottom_closebtn"
              onClick={closeModal}
            >
              닫기
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default SamSungNotWarranty;
