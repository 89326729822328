import React, { useState } from "react";
import "../../assets/scss/MemoryRegisterModal.scss";
import { _each, _returneach } from "../../assets/js/util/util";
import "react-toastify/dist/ReactToastify.css";
import { SliderPicker } from "react-color";
import MemoryUpload from "./MemoryUpload";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import axios from "axios";
import Button from "../customButton/Button";

function MemoryRegisterModal({
  onClose,
  onSelectColor,
  country,
  setShowModal,
  reMyMeoryData,
  setRemyMemoryData,
}) {
  const [fileData, setFile] = useState([]);
  const [resetPreview, setResetPreview] = useState(null);

  const [color, setColor] = useState("#000000");
  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [description, setDescripition] = useState("");

  const titleHandler = (e) => {
    setTitle(e.target.value);
  };
  const descriptionHandler = (e) => {
    setDescripition(e.target.value);
  };

  const handleStartDateChange = (event) => {
    const newDate = new Date(event.target.value);
    setStartDate(newDate);
  };

  const handleEndDateChange = (event) => {
    setEndDate(new Date(event.target.value));
  };

  const handleColorChange = (colorEvent) => {
    const selectedColor = colorEvent.hex;
    setColor(selectedColor);
    onSelectColor(selectedColor);
  };

  const memoryRegister = () => {
    if (title.length === 0) {
      alert("타이틀을 입력해주세요");
      return;
    }
    if (title.length > 30) {
      alert("설명은 30자까지 가능합니다");
      return;
    }
    if (description.length === 0) {
      alert("설명을 입력해주세요");
      return;
    }
    if (description.length > 100) {
      alert("설명은 100자까지 가능합니다");
      return;
    }

    if (!fileData[0]) {
      alert("파일을 업로드해주세요.");
      return;
    }
    if (!startDate) {
      alert("여행시작일자를 선택해주세요.");
      return;
    }
    if (!endDate) {
      alert("여행종료일자를 선택해주세요.");
      return;
    }
    const formData = new FormData();
    formData.append("TITLE", title);
    formData.append("START_DATE", startDate);
    formData.append("END_DATE", endDate);
    formData.append("DESCRIPTION", description);
    formData.append("COLOR", color);
    formData.append("imgData", fileData[0]);
    formData.append("TRAVEL_COUNTRY", country);
    try {
      axios
        .post("/api/memory/register", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          alert(res.data.message);
          if (res.data.success) {
            setRemyMemoryData(!reMyMeoryData);
            setShowModal(false);
            onClose();
          } else {
            window.location.reload();
          }
        });
    } catch (error) {
      alert(error);
      window.location.reload();
    }
  };

  return (
    <>
      <div className="memory_background">
        <div className="memory_content">
          <div className="memory_register_sec">
            <div className="memory_register_sec_detail">
              <p>제목</p>
              <input value={title} onChange={titleHandler} placeholder="제목" />
            </div>
            <div className="memory_register_sec_detail">
              <p>여행기간</p>
              <div className="memory_register_sec_detail_date">
                <input type="date" onChange={handleStartDateChange} />
                <input type="date" onChange={handleEndDateChange} />
              </div>
            </div>
            <div className="memory_register_sec_detail">
              <p>여행국가</p>
              <input readOnly type="text" value={country} />
            </div>
          </div>
          <div className="memory_source_register">
            <MemoryUpload
              alreadyUpload={false}
              buttonText={
                <div className="register_upload_sec">
                  <div className="register_plus_img_sec">
                    <img
                      className="register_plus_img"
                      src={convertS3toCloudFront(
                        "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/plus_image.png"
                      )}
                    />
                  </div>
                  <div className="register_upload_text">이미지 업로드</div>
                </div>
              }
              setFile={setFile}
              resetPreview={setResetPreview}
            />
            <textarea
              placeholder="이미지에 대한 설명을 입력해주세요."
              value={description}
              onChange={descriptionHandler}
            />
          </div>
          <div className="memory_content_color">
            <div className="memory_content_color_title">
              <p>선택한 지역 색칠하기</p>
            </div>
            <div className="memory_content_color_select">
              <div>
                <SliderPicker color={color} onChange={handleColorChange} />
                <p>{color}</p>
              </div>
            </div>
          </div>
          <div className="memory_btnsec">
            <Button
              onClick={onClose}
              text={"닫기"}
              color={"t"}
              location={"f"}
            />
            <Button onClick={memoryRegister} text={"등록하기"} color={"s"} />
          </div>
        </div>
      </div>
    </>
  );
}
export default React.memo(MemoryRegisterModal);
