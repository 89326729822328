import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { convertS3toCloudFront } from "../../assets/js/util/proxy.js";
import "../../assets/scss/AdPopCorn.scss";
import axios from "axios";
import { usePrompt } from "./use-prompt.ts";

const Edison = () => {
  let [searchParams] = useSearchParams();
  const [clickKey, setClickKey] = useState("");

  usePrompt(
    "현재 페이지를 벗어나면 애디슨 포인트를 받으실 수 없습니다.\n추후 포인트 수령을 위해 애디슨을 통해 재접속 해주셔야합니다.\n이 페이지를 벗어나시겠습니까?",
    true
  );

  useEffect(() => {
    const clickKeyValue = searchParams.get("click_key");
    if (!clickKeyValue) {
      // 예: 메인 페이지로 리디렉션하려면 아래와 같이 작성합니다.
      alert("잘못된 접근입니다.");
      window.location.href = "/";
      return null;
    }
    setClickKey(clickKeyValue);
  }, []);

  const nicePopup = () => {
    //투입전에 validation
    if (!clickKey) {
      alert("clickKey 값이 없습니다.");
      window.location.href = "/";
      return null;
    }
    let wl = window.location;
    let dev = false;

    if (wl.hostname === "localhost") {
      dev = true;
    }
    let returnUrl = dev
      ? `http://localhost:5000/api/nice/decrypt/edison/data` //http://localhost:5000/api/nice/decrypt/edison/data
      : "https://travellink.co.kr/api/nice/decrypt/edison/data"; // "https://travellink.co.kr/api/nice/decrypt/edison/data";
    let redirectUrl = `travellink.co.kr/EdisonSignForm/click_key=${clickKey}`; //`https://travellink.co.kr/EdisonSignForm` , https://travellink.co.kr/EdisonSignForm/click_key=${clickKey}
    let adKey = {
      clickKey,
    };
    axios
      .get("/api/nice/encrypt/edison/data", {
        params: { returnUrl, redirectUrl, adKey },
        withCredentials: true,
      })
      .then(res => {
        let encodeData = res.data;
        document.form_chk.action =
          "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
        document.form_chk.EncodeData.value = encodeData;
        document.form_chk.target = "popupChk";
        document.form_chk.submit();
      })
      .catch(err => {
        console.error(err);
      });
  };

  return (
    <Container
      fixed
      style={{ marginBottom: "20%", paddingLeft: "7%", paddingRight: "7%" }}
    >
      <div className="adpopcorn_sec_all">
        <img
          style={{ maxWidth: "100%", height: "auto" }}
          src={convertS3toCloudFront(
            "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/EVENTS/adpopcorn_eventpage.jpg"
          )}
        />
        <form type="hidden" name="form_chk" method="post">
          <input type="hidden" name="m" value="checkplusService" />
          <input type="hidden" name="EncodeData" value="" />
        </form>
        <div className="adpopcorn_signformtext">
          <p>트래블링크 무료 회원가입하고</p>
          <p>"포인트와 국내/외 여행자보험 무료가입 혜택"</p>
          <p>받아가세요!</p>
        </div>
        <div className="adpopcorn_btn" onClick={nicePopup}>
          회원가입하고 포인트 받기
        </div>
      </div>
    </Container>
  );
};

export default Edison;
