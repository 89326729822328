import React, { useState } from "react";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";

const GiftImageSlider = ({ data }) => {
  const [clickedData, setClickedData] = useState(data[0]);
  const [imgData, setImgData] = useState(data);

  const clickHandler = (e, item) => {
    setClickedData(item);
  };

  return (
    <div className="recommend_detail_main_img">
      <div>
        <img
          id="main_img"
          src={convertS3toCloudFront(clickedData.IMAGE_PATH)}
        />
      </div>
      <div
        className="recommend_detail_main_bottom_wrapper"
        style={{ height: "30px" }}
      >
        {imgData.map((item, index) => {
          return (
            <img
              key={index}
              className="recommend_detail_main_bottom_img"
              src={convertS3toCloudFront(item.IMAGE_PATH)}
              onClick={e => clickHandler(e, item)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(GiftImageSlider);
