import React from "react";
import "../../assets/scss/InfoOneHeaderButton.scss";

const InfoOneHeaderButton = ({
  title,
  handleClick,
  activeContent,
  compareContent,
}) => {
  return (
    <button
      className={`infoone_button ${
        activeContent === compareContent ? "clicked" : ""
      }`}
      onClick={() => handleClick(compareContent)}
    >
      {title}
    </button>
  );
};

export default React.memo(InfoOneHeaderButton);
