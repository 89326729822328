import React, { useEffect, useState } from "react";
import "../../assets/scss/SignForm_cert2.scss";
import axios from "axios";
import Loader from "../loader/LoaderComponent";
import HelmetComponent from "../header/HelmetComponent";

function SignFormCert2() {
  const [isLoading, setIsLoading] = useState(false);

  const nicePopup = e => {
    setIsLoading(true);
    let wl = window.location;
    let dev = false;

    if (wl.hostname === "localhost") {
      dev = true;
    }
    let returnUrl = dev
      ? `http://localhost:5000/api/nice/decrypt/data`
      : "https://travellink.co.kr/api/nice/decrypt/data";
    let redirectUrl = `${wl.protocol}//${wl.host}/SignForm`;
    axios
      .get("/api/nice/encrypt/data", {
        params: { returnUrl, redirectUrl },
        withCredentials: true,
      })
      .then(res => {
        let encodeData = res.data;
        document.form_chk.action =
          "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
        document.form_chk.EncodeData.value = encodeData;
        document.form_chk.target = "popupChk";
        document.form_chk.submit();
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    nicePopup();
  }, []);

  return (
    <>
      <HelmetComponent title="본인인증" />
      <form type="hidden" name="form_chk" method="post">
        <input type="hidden" name="m" value="checkplusService" />
        <input type="hidden" name="EncodeData" value="" />
      </form>
      <div className="cert2_step_all">
        <div className="cert2_step_sec">
          <p className="cert2_step1">01 본인인증</p>
          <p className="cert2_step2">&gt;</p>
          <p className="cert2_step2">02 정보입력 및 약관동의</p>
          <p className="cert2_step2">&gt;</p>
          <p className="cert2_step3">03 가입완료</p>
        </div>
        <div className="cert2_all">
          <div className="cert2_box">
            <div className="cert2_title_sec">
              <p className="cert2_title">01 본인인증</p>
            </div>
            <div className="cert2_box_expl">
              <p className="cert2_box_expl1">
                회원가입을 위해 본인인증을 진행해주세요.
              </p>
            </div>
            <div
              onClick={nicePopup}
              className={`cert2_btn${isLoading ? " loader-visible" : ""}`}
            >
              {isLoading ? <Loader /> : "본인인증 하기"}{" "}
            </div>
            <div className="cert2_title_expl">
              <p>
                * 트래블링크는 본인인증을 완료하신 고객만 회원가입이 가능합니다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SignFormCert2;
