import React, { useRef, useState, useEffect } from "react";
import "../../assets/scss/MemoryUpload.scss";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";

const MemoryUpload = ({
  buttonText,
  setFile,
  previewUrl,
  alreadyUpload,
  resetPreview,
}) => {
  const [preview, setPreview] = useState(previewUrl);
  const [modal, setModal] = useState(false);
  const fileInput = useRef(null);

  const handleFileUpload = () => {
    fileInput.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile((prevFileData) => [...prevFileData, file]);

    const reader = new FileReader();

    reader.onloadend = () => {
      setPreview(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    } else {
      setPreview(null);
    }
    if (!file) {
      setPreview(previewUrl);
    }
  };

  const handleImageClick = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };
  useEffect(() => {
    setPreview(previewUrl);
  }, [previewUrl]);
  useEffect(() => {
    setPreview(previewUrl);
    if (resetPreview) {
      resetPreview(() => setPreview);
    }
  }, [previewUrl, resetPreview]);
  return (
    <div className="memory_upload_all">
      {alreadyUpload && preview && (
        <p className="memory_upload_title">클릭시 확대됩니다.</p>
      )}
      {!alreadyUpload && !preview && (
        <p className="memory_upload_title">이미지를 업로드해주세요.</p>
      )}
      {!alreadyUpload && preview && (
        <p className="memory_upload_title">등록하기 버튼을 눌러주세요.</p>
      )}
      <div className="memory_upload_btn_sec">
        {!alreadyUpload && !preview && (
          <>
            <button className="memory_upload_btn" onClick={handleFileUpload}>
              {buttonText}
            </button>
            <input
              type="file"
              accept="image/*"
              ref={fileInput}
              onChange={handleFileChange}
              style={{ display: "none" }}
              className="memory_upload_input"
            />
          </>
        )}
        {preview && (
          <>
            <div className="memory_preview_sec" onClick={handleImageClick}>
              <img
                src={convertS3toCloudFront(preview)}
                alt="File Preview"
                className="memory_preview_image"
              />
            </div>
          </>
        )}
        {modal && (
          <div className="memory_modal" onClick={closeModal}>
            <img
              src={convertS3toCloudFront(previewUrl || preview)}
              alt="File Preview"
              className="memory_modal_image"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MemoryUpload;
