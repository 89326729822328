import React from "react";
import "../../assets/scss/SamSungModalX.scss";
import { useDeviceScreen } from "../../assets/js/util/useDeviceScreen";
import ThirdPartyConsentModal from "../Terms/ThirdPartyConsentModal";
import Pcomponent from "../common/Pcomponent";
import axios from "axios";
import { curVersion } from "../../assets/js/TERMS/terms_version";

const SamSungModalX = ({ setIsOpen, notAgreeInsurance, setAgreeStatus }) => {
  const device = useDeviceScreen();

  const cancleButton = () => {
    setIsOpen(false);
  };

  const agreeEvent = async () => {
    let body = {
      thirdAgree: true,
      terms_version: curVersion,
    };

    try {
      await axios.post("/api/insurance/insuranceAllInsert", body).then(res => {
        // setUserThirdAgree("1");
        if (res.data.success) {
          setAgreeStatus(prevStatus => {
            return !prevStatus;
          });
        } else {
          alert("오류발생했습니다. \n 관리자에게 문의해주세요.");
        }
      });
    } catch (err) {
      console.log(err);
      alert("오류발생했습니다. \n 관리자에게 문의해주세요.");
    }
  };

  return (
    <div className="modalx_sec">
      <div className="modalx_text_sec">
        <Pcomponent className="modalx_text_0" title="이벤트 안내" />
        <Pcomponent
          className="modalx_text_1"
          title="고객님께서는 현재 관련 이용약관 미동의 상태로,"
        />
        {device === "mobile" ? (
          <>
            <Pcomponent
              className="modalx_text_2"
              title="여행자 보험 무료 혜택 제공이 불가합니다."
            />
            <Pcomponent title="약관 동의시 이벤트 참여가 가능합니다." />
          </>
        ) : (
          <>
            <Pcomponent
              className="modalx_text_2"
              title="국내/외 여행자 보험 무료 가입 혜택 제공이 불가합니다."
            />
            <Pcomponent title="아래의 이용 약관을 동의하시면 정상적인 이벤트 참여가 가능합니다." />
          </>
        )}
      </div>
      <ThirdPartyConsentModal
        agreeEvent={agreeEvent}
        cancleButton={cancleButton}
        notAgreeInsurance={notAgreeInsurance}
        device={device}
      />
    </div>
  );
};

export default React.memo(SamSungModalX);
