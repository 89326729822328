import React from "react";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import withAuth from "../../hoc/auth";
import HelmetComponent from "../header/HelmetComponent";
import { useParams } from "react-router-dom";

function CustomCouponPage() {
  const { couponId } = useParams();

  return (
    <>
      <HelmetComponent title="쿠폰 사용페이지" />
      <img
        width="100%"
        height="auto"
        src={convertS3toCloudFront(
          `https://s3-travellink.s3.ap-northeast-2.amazonaws.com/COUPON_CUSTOM/coupon_custom${couponId}.png`
        )}
      />
    </>
  );
}
export default withAuth(CustomCouponPage, null);
