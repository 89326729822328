import React from "react";
import "../../assets/scss/Dom_map.scss";
const DomMap = () => {
  return (
    <>
      <div className="memory_dom_terms">
        <p>추후 업데이트 됩니다.</p>
      </div>
    </>
  );
};

export default React.memo(DomMap);
