import React, { useEffect } from "react";

const AdComponent = () => {
  useEffect(() => {
    try {
      const script = document.createElement("script");
      script.src =
        "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6739568423243081";
      script.async = true;
      script.crossOrigin = "anonymous";
      document.body.appendChild(script);
    } catch (err) {
      console.error(err);
    }
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-client="ca-pub-6739568423243081"
      data-ad-slot="3427372095"
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  );
};

export default AdComponent;
