import React from "react";
import "../../assets/scss/SamSungModalCautions.scss";
import SamSungWarranty from "./SamSungWarranty";
import SamSungNotWarranty from "./SamSungNotWarranty";
import { useDeviceScreen } from "../../assets/js/util/useDeviceScreen";

function SamSungModalCautions({}) {
  const device = useDeviceScreen();
  return (
    <>
      <div className="modal_cautions_sec">
        <div>
          <label className="modal_check3_text">서비스 안내</label>
          <div className="modal_cautions">
            <div>
              <p>
                트래블링크 회원가입 고객분들께 제공드리는 해외 여행보험 무료
                가입 혜택입니다.
              </p>
            </div>
          </div>
        </div>
        <div>
          <label className="modal_check3_text">가입대상</label>
          <div className="modal_cautions">
            <div>
              <p>1. 트래블링크 신규 회원가입자</p>
              <p>
                2. 개인정보 제3자 제공(유상)동의를 한 고객에 한하여 혜택 제공
              </p>
            </div>
          </div>
        </div>
        <div>
          <label className="modal_check3_text">가입기간</label>
          <div className="modal_cautions">
            <div>
              <p>2024년 05월 24일 ~ 2025년 05월 23일</p>
            </div>
          </div>
        </div>
        <div>
          <label className="modal_check3_text">보장기간</label>
          <div className="modal_cautions">
            <div>
              <p>여행보험 가입 신청시 기입한 일자에 한함</p>
            </div>
          </div>
        </div>
        <div>
          <label className="modal_check3_text">여행하는 국가에 대한 사항</label>
          <div className="modal_cautions">
            <p>1. 여행하는 국가에 따라 보험료가 다르지 않습니다.</p>
            <p>
              2. 외교통상부가 지정하는 지역별 여행경보 신호등 중
              적색경보(출국권고), 흑색경보(여행금지) 지역은 보험가입과 보상이
              불가능합니다.
            </p>
            <p>
              3. 외교통상부의 해외안전여행 사이트를 방문하셔서 여행하려는 국가가
              안전한 지역인지 확인해주세요.
            </p>
            <a href="https://www.0404.go.kr" target="_blank">
              해외안전여행 사이트 방문하기 (www.0404.go.kr)
            </a>
          </div>
        </div>
        <div>
          <label className="modal_check3_text">보험가입 플랜 (보장내역)</label>
          <div className="modal_cautions">
            {device === "mobile" || device === "tablet" ? (
              <table className="modal_table">
                <tbody>
                  <tr className="modal_tr">
                    <th className="modal_th" colSpan={2}>
                      구분
                    </th>
                    <td className="modal_td">보장금액</td>
                  </tr>
                  <tr className="modal_tr">
                    <td className="modal_td" colSpan={2}>
                      상해사망
                    </td>
                    <td className="modal_td">3,000,000원</td>
                  </tr>
                  <tr className="modal_tr">
                    <td className="modal_td" colSpan={2}>
                      상해후유장해
                    </td>
                    <td className="modal_td">3,000,000원</td>
                  </tr>
                  <tr className="modal_tr">
                    <td className="modal_td" rowSpan={4}>
                      기본형 해외여행 실손의료비 특별약관
                    </td>
                  </tr>
                  <tr className="modal_tr">
                    <td className="modal_td">해외상해의료비</td>
                    <td className="modal_td">1,000,000원</td>
                  </tr>
                  <tr className="modal_tr">
                    <td className="modal_td">국내상해급여의료비</td>
                    <td className="modal_td">5,000,000원</td>
                  </tr>
                  <tr className="modal_tr">
                    <td className="modal_td">국내질병급여의료비</td>
                    <td className="modal_td">5,000,000원</td>
                  </tr>
                  <tr className="modal_tr">
                    <td className="modal_td" colSpan={2}>
                      배상책임특약
                    </td>
                    <td className="modal_td">1,000,000원</td>
                  </tr>
                  <tr className="modal_tr">
                    <td className="modal_td" colSpan={2}>
                      중대사고 구조송환비용 특약
                    </td>
                    <td className="modal_td">5,000,000원</td>
                  </tr>
                  <tr className="modal_tr">
                    <td className="modal_td" colSpan={2}>
                      항공기납치 특약
                    </td>
                    <td className="modal_td">가입</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <table className="modal_cautions_table">
                <thead className="modal_cautions_thead">
                  <tr>
                    <th style={{ width: "4%" }} rowSpan={2}>
                      구분
                    </th>
                    <th style={{ width: "6.1%" }} rowSpan={2}>
                      상해사망
                    </th>
                    <th style={{ width: "7%" }} rowSpan={2}>
                      상해후유장해
                    </th>
                    <th colSpan={3}>기본형 해외여행 실손의료비 특별약관</th>
                    <th style={{ width: "5.4%" }} rowSpan={2}>
                      배상책임 특약
                    </th>
                    <th style={{ width: "8.4%" }} rowSpan={2}>
                      중대사고구조송환 특약
                    </th>
                    <th style={{ width: "6.4%" }} rowSpan={2}>
                      항공기납치 특약
                    </th>
                  </tr>
                  <tr>
                    <th>해외상해의료비</th>
                    <th>국내상해급여의료비</th>
                    <th>국내질병급여의료비</th>
                  </tr>
                </thead>
                <tbody className="modal_cautions_tbody">
                  <tr>
                    <td>보장 금액</td>
                    <td>3,000,000원</td>
                    <td>3,000,000원</td>
                    <td>1,000,000원</td>
                    <td>5,000,000원</td>
                    <td>5,000,000원</td>
                    <td>1,000,000원</td>
                    <td>5,000,000원</td>
                    <td>가입</td>
                  </tr>
                </tbody>
              </table>
            )}
            <div className="modal_cautions">
              <SamSungWarranty />
              <SamSungNotWarranty />
              <p>
                * 만 15세 미만 및 80세 이상 고객의 경우 상해사망이 제외됩니다.
              </p>
              <p>
                * 중대사고 구조송환 관련 입원 시에는 14일 이상 입원할 경우에만
                보장됩니다.
              </p>
              <p>
                * 상해국내급여와 상해 국내비급여의 통원 1회당 보장금액은 5만원
                입니다.
              </p>
            </div>
          </div>
        </div>
        <div>
          <label className="modal_check3_text">주의사항</label>
          <div className="modal_cautions">
            <p>1. 해외여행보험의 배상책임 1사고당 자기부담금은 1만 원입니다.</p>
            <p>
              2. 실손의료비는 2개 이상의 보험에 가입하더라도 중복 보상되지 않고
              비례보상됩니다.
            </p>
            <p>3. 현재 해외여행(체류) 중인 경우 가입이 불가합니다.</p>
          </div>
        </div>
        <div>
          <label className="modal_check3_text">고객센터</label>
          <div className="modal_cautions">
            <p>
              자세한 사항은 반드시 보장내용 및 약관을 읽어보시기 바라며,
              관련문의는 고객센터로 전화주세요.
            </p>
            <p>고객센터 : 1800-9010</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default SamSungModalCautions;
