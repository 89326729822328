export const changeList = ["ARS",
    "AUD",
    "BDT",
    "BOB",
    "BRL",
    "BYN",
    "CAD",
    "CHF",
    "CLP",
    "CNY",
    "COP",
    "CRC",
    "CZK",
    "DKK",
    "EGP",
    "EUR",
    "FJD",
    "GBP",
    "HKD",
    "HNL",
    "HRK",
    "HUF",
    "IDR",
    "ILS",
    "INR",
    "IRR",
    "ISK",
    "JPY",
    "KRW",
    "LAK",
    "MGA",
    "MOP",
    "MVR",
    "MXN",
    "MYR",
    "NOK",
    "NZD",
    "PGK",
    "PHP",
    "PLN",
    "PYG",
    "RUB",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "TWD",
    "UAH",
    "USD",
    "UYU",
    "VND"];


    export const changeListName = {
        "ARS": { country: "아르헨티나", unit: "아르헨티나 페소" },
        "AUD": { country: "호주", unit: "호주 달러" },
        "BDT": { country: "방글라데시", unit: "타카" },
        "BOB": { country: "볼리비아", unit: "볼리비아노" },
        "BRL": { country: "브라질", unit: "레알" },
        "BYN": { country: "벨라루스", unit: "벨라루스 루블" },
        "CAD": { country: "캐나다", unit: "캐나다 달러" },
        "CHF": { country: "스위스", unit: "스위스 프랑" },
        "CLP": { country: "칠레", unit: "칠레 페소" },
        "CNY": { country: "중국", unit: "위안" },
        "COP": { country: "콜롬비아", unit: "콜롬비아 페소" },
        "CRC": { country: "코스타리카", unit: "코스타리카 콜론" },
        "CZK": { country: "체코", unit: "체코 코루나" },
        "DKK": { country: "덴마크", unit: "덴마크 크로네" },
        "EGP": { country: "이집트", unit: "이집트 파운드" },
        "EUR": { country: "유럽연합", unit: "유로" },
        "FJD": { country: "피지", unit: "피지 달러" },
        "GBP": { country: "영국", unit: "영국 파운드" },
        "HKD": { country: "홍콩", unit: "홍콩 달러" },
        "HNL": { country: "온두라스", unit: "온두라스 렘피라" },
        "HRK": { country: "크로아티아", unit: "크로아티아 쿠나" },
        "HUF": { country: "헝가리", unit: "헝가리 포린트" },
        "IDR": { country: "인도네시아", unit: "루피아" },
        "ILS": { country: "이스라엘", unit: "이스라엘 셰켈" },
        "INR": { country: "인도", unit: "인도 루피" },
        "IRR": { country: "이란", unit: "이란 리알" },
        "ISK": { country: "아이슬란드", unit: "아이슬란드 크로나" },
        "JPY": { country: "일본", unit: "일본 엔" },
        "KRW": { country: "대한민국", unit: "원" },
        "LAK": { country: "라오스", unit: "라오스 키프" },
        "MGA": { country: "마다가스카르", unit: "마다가스카르 아리아리" },
        "MOP": { country: "마카오", unit: "마카오 파타카" },
        "MVR": { country: "몰디브", unit: "몰디브 루피아" },
        "MXN": { country: "멕시코", unit: "멕시코 페소" },
        "MYR": { country: "말레이시아", unit: "말레이시아 링깃" },
        "NOK": { country: "노르웨이", unit: "노르웨이 크로네" },
        "NZD": { country: "뉴질랜드", unit: "뉴질랜드 달러" },
        "PGK": { country: "파푸아뉴기니", unit: "키나" },
        "PHP": { country: "필리핀", unit: "필리핀 페소" },
        "PLN": { country: "폴란드", unit: "폴란드 즈워티" },
        "PYG": { country: "파라과이", unit: "파라과이 과라니" },
        "RUB": { country: "러시아", unit: "러시아 루블" },
        "SEK": { country: "스웨덴", unit: "스웨덴 크로나" },
        "SGD": { country: "싱가포르", unit: "싱가포르 달러" },
        "THB": { country: "태국", unit: "태국 바트" },
        "TRY": { country: "터키", unit: "터키 리라" },
        "TWD": { country: "대만", unit: "대만 달러" },
        "UAH": { country: "우크라이나", unit: "우크라이나 그리브나" },
        "USD": { country: "미국", unit: "미국 달러" },
        "UYU": { country: "우루과이", unit: "우루과이 페소" },
        "VND": { country: "베트남", unit: "베트남 동" },
      };
      
       
      
