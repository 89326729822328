import React, { useCallback, useEffect, useRef, useState } from "react";
import InputComponent from "../customButton/InputComponent";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserService } from "../../service/UserService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/scss/LoginComponent.scss";
import Button from "../customButton/Button";
import { useDeviceScreen } from "../../assets/js/util/useDeviceScreen";

const LoginComponent = ({ activeStateChangeHandler }) => {
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const device = useDeviceScreen();
  const idRef = useRef();
  const passwordRef = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    idRef.current.focus();
  }, []);

  const passwordChangeHandler = useCallback((e) => {
    setPassword(e.target.value);
  }, []);

  const idChangeHandler = useCallback((e) => {
    setId(e.target.value);
  }, []);

  const loginHandler = useCallback(() => {
    // id Check
    if (id.length === 0) {
      toast("아이디를 입력해주세요");
      idRef.current.focus();
      return;
    }

    if (password.length === 0) {
      toast("비밀번호를 입력해주세요");
      passwordRef.current.focus();
      return;
    }

    let body = {
      id,
      password,
    };
    dispatch(UserService.login(body)).then((res) => {
      if (res.payload.success) {
        if (res.payload.visitedEvent8) {
          window.location.href = "https://travellink.co.kr/Event/8";
        } else if (res.payload.visitedEvent10) {
          window.location.href = "https://travellink.co.kr/Event/10";
        } else if (res.payload.visitedEvent11) {
          window.location.href = "https://travellink.co.kr/Event/11";
        } else if (res.payload.visitedEvent12) {
          window.location.href = "https://travellink.co.kr/Event/12";
        } else if (res.payload.visitedEvent13) {
          window.location.href = "https://travellink.co.kr/Event/13";
        } else if (res.payload.visitedEvent15) {
          window.location.href = "https://travellink.co.kr/Event/15";
        } else {
          navigate("/");
        }
      } else {
        if (res.payload.status === "id") {
          toast(`${res.payload.message}`);
          idRef.current.focus();
        }

        if (res.payload.status === "password") {
          toast(`${res.payload.message}`);
          passwordRef.current.focus();
        }

        if (res.payload.status === "admin") {
          alert("error가 발생했습니다. \n 다시시도해주세요");
          navigate("/");
        }

        if (res.payload.status === "token") {
          alert("error가 발생했습니다. \n 다시시도해주세요");
          navigate("/");
        }
      }
    });
  }, [dispatch, id, navigate, password]);

  return (
    <>
      <h1 className="login_component_text">로그인</h1>
      <div className="login_component_area">
        <p className="login_component_subtext">아이디</p>
        <InputComponent
          type="email"
          id="loginId"
          name="login"
          placeholder="아이디를 입력해 주세요"
          onChange={idChangeHandler}
          value={id}
          ref={idRef}
          maxLength={"16"}
        />
        <p className="login_component_subtext">비밀번호</p>
        <InputComponent
          type="password"
          id="passwordId"
          name="password"
          placeholder="비밀번호를 입력해 주세요"
          onChange={passwordChangeHandler}
          value={password}
          ref={passwordRef}
          maxLength={"16"}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              loginHandler();
            }
          }}
        />
      </div>
      <Button
        onClick={loginHandler}
        text={"확인"}
        color={"s"}
        device={device}
        style={{
          width:
            device === "mobile" ? "70%" : device === "tablet" ? "90%" : "270px",
          height: "30px",
        }}
      />
      <div className="login_component_find">
        <p
          onClick={() => activeStateChangeHandler("1")}
          className="login_component_find_id"
        >
          아이디 찾기
        </p>
        <p
          onClick={() => activeStateChangeHandler("2")}
          className="login_component_find_pw"
        >
          비밀번호 찾기
        </p>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};
export default React.memo(LoginComponent);
