import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import PreventDiagonalScroll from "../Slider/PreventDiagonalScroll";
import "../../assets/scss/MypageCouponSlider.scss";

function MainCouponSlider({
  couponData,
  toggleModal,
  setSelectedCoupon,
  setCurrentSlide,
  currentSlide,
  slideCount,
}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const colors = [
    "#664289",
    "#a58a40",
    "#895a3d",
    "#e27458",
    "#895a3d",
    "#395aa5",
  ];
  const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
    return currentSlide === 0 ? null : (
      <div className={className} onClick={onClick}>
        <img
          className="mypage_coupon_prevarrow"
          src={convertS3toCloudFront(
            "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/down_arrow.png"
          )}
        ></img>
      </div>
    );
  };

  const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return currentSlide === slideCount - settings.slidesToShow ? null : (
      <div className={className} onClick={onClick}>
        <img
          className="mypage_coupon_nextarrow"
          src={convertS3toCloudFront(
            "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/down_arrow.png"
          )}
        ></img>
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: windowWidth <= 699 ? 2 : 3,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    beforeChange: (current, next) => {
      setCurrentSlide(next);
    },
  };
  return (
    <div>
      <PreventDiagonalScroll />
      <Slider {...settings}>
        {couponData &&
          couponData.map((item, key) => (
            <div
              className="mypage_user_coupon_item_all"
              key={item.seq}
              onClick={() => {
                toggleModal();
                setSelectedCoupon(item);
              }}
            >
              <div
                className="mypage_user_coupon_item"
                style={{
                  backgroundColor: colors[key % colors.length],
                }}
              >
                <p>{item.coupon_name}</p>
                <p>{item.coupon_benefit}</p>
                <div className="mypage_user_coupon_barcode_sec">
                  <img
                    className="mypage_user_coupon_barcode"
                    src={
                      item.barcode
                        ? convertS3toCloudFront(item.barcode)
                        : convertS3toCloudFront(
                            "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/RENEWAL/230822_mainlogo.png"
                          )
                    }
                  />
                </div>
              </div>
            </div>
          ))}
      </Slider>
    </div>
  );
}

export default MainCouponSlider;
