import React, { useState, useCallback } from "react";
import axios from "axios";
import { changeList, changeListName } from "../../assets/js/util/exchange";
import "../../assets/scss/ExchangeComponent.scss";
import { Tooltip } from "react-tooltip";
import ReactDOMServer from "react-dom/server";
import Loader from "../loader/LoaderComponent";
import Select from "react-select";
import { useDeviceScreen } from "../../assets/js/util/useDeviceScreen";

const ExchangeComponent = () => {
  const [fromCurrency, setFromCurrency] = useState("USD");
  const [toCurrency, setToCurrency] = useState("KRW");
  const [exchangeRate, setExchangeRate] = useState("");
  const [amount, setAmount] = useState("");
  const device = useDeviceScreen();
  const sortedChangeList = changeList.sort((a, b) =>
    changeListName[a].country.localeCompare(changeListName[b].country)
  );
  const [hasClicked, setHasClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const options = sortedChangeList.map((currency) => ({
    value: currency,
    label: changeListName[currency].country,
  }));
  const [amountnull, setAmountNull] = useState("");

  const handleFromCurrencyChange = (selectedOption) => {
    setFromCurrency(selectedOption.value);
  };

  const handleToCurrencyChange = (selectedOption) => {
    setToCurrency(selectedOption.value);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: "calc(" + provided.height + " - 3px)",
      minHeight: "initial",
      fontSize: "16px",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 10,
    }),
    singleValue: (provided, state) => {
      return { ...provided, fontSize: "16px" };
    },
    placeholder: (provided, state) => {
      return { ...provided, fontSize: "16px" };
    },
  };

  const fetchExchangeRate = useCallback(async () => {
    if (amount === "" || amount === null || isNaN(amount)) {
      setAmountNull("정확한 금액을 입력하세요");
      setHasClicked(false);
      return;
    }
    setIsLoading(true);
    setAmountNull("");

    try {
      const response = await axios.get(
        `https://api.exchangerate-api.com/v4/latest/${toCurrency}`
      );
      let rate = response.data.rates[fromCurrency];
      if (isNaN(rate)) {
        setExchangeRate("");
        setAmountNull("환율 정보를 불러올 수 없습니다");
        setHasClicked(false);
      } else {
        setExchangeRate(rate);
        setHasClicked(true);
      }
    } catch (error) {
      console.log(error);
      setAmountNull("환율 정보를 불러오는데 실패했습니다");
      setHasClicked(false);
    } finally {
      setIsLoading(false);
    }
  }, [fromCurrency, toCurrency, amount]);

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d{1,2}))/g, ",");
  };

  const getCurrencyUnit = (currencyCode) => {
    return changeListName[currencyCode]?.unit || "";
  };

  const handleTouchStart = (e) => {
    e.target.style.backgroundColor = "#fad200";
    e.target.style.color = "white";
  };

  const handleTouchEnd = (e) => {
    e.target.style.backgroundColor = "#fad200";
    e.target.style.color = "white";
  };

  return (
    <div className="main_ex_sec">
      {isLoading && <Loader />}
      <div className="ex_container">
        <div className="ex_car_sec">
          <div
            className="ex_sec_help"
            style={{ width: "40px", marginTop: "11px" }}
            data-tooltip-id="my-tooltip"
            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
              <div className="ex_sec_help_text">
                <p>
                  <strong>실시간 환율 계산기 사용법</strong>
                </p>
                <br />
                <p>
                  1. 기준 국가 선택 : 환전을 할(현재 보유 중인) 화폐를
                  선택하세요!
                </p>
                <p>2. 여행 국가 선택 : 여행 갈 국가의 화폐를 선택하세요!</p>
                <p>3. 환전 금액 : 환전을 진행할 금액을 입력하세요!</p>
                <p>
                  4. 환율 계산 버튼 클릭 : 실시간으로 환전 결과를 확인하세요!
                </p>
              </div>
            )}
          >
            <p className="ex_howto">이용방법 &gt;</p>
          </div>
          <Tooltip id="my-tooltip" />
          <div className="ex_car_all">
            <div className="ex_car_select">
              <div className="ex_car_second">
                <div className="ex_sec_subtitle">
                  <p>기준 국가 선택</p>
                </div>
                <Select
                  className="ex_car_nation"
                  value={options.find((option) => option.value === toCurrency)}
                  onChange={handleToCurrencyChange}
                  options={options}
                  isSearchable
                  styles={customStyles}
                />
              </div>
              <div className="ex_car_first">
                <div className="ex_sec_subtitle">
                  <p>여행 국가 선택</p>
                </div>
                <Select
                  className="ex_car_nation"
                  value={options.find(
                    (option) => option.value === fromCurrency
                  )}
                  onChange={handleFromCurrencyChange}
                  options={options}
                  isSearchable
                  styles={customStyles}
                />
              </div>
              {device === "mobile" && (
                <div className="ex_car_small_boundary"></div>
              )}
              <div className="ex_car_third">
                {device === "mobile" ? (
                  <label className="ex_sec_small_subtitle">환전 금액</label>
                ) : (
                  <label className="ex_sec_subtitle">환전 금액</label>
                )}

                <input
                  className="ex_car_input"
                  type="number"
                  value={amount}
                  onChange={handleAmountChange}
                />
                {device === "mobile" && (
                  <button
                    className="calculate_rate_btn"
                    onClick={fetchExchangeRate}
                    onTouchStart={handleTouchStart}
                    onTouchEnd={handleTouchEnd}
                  >
                    <p>환율</p>
                    <p>계산</p>
                  </button>
                )}
              </div>
            </div>

            <div className="calculate_rate_btn_sec">
              {!(device === "mobile") && (
                <button
                  className="calculate_rate_btn"
                  onClick={fetchExchangeRate}
                  onTouchStart={handleTouchStart}
                  onTouchEnd={handleTouchEnd}
                >
                  환율 계산
                </button>
              )}
            </div>
          </div>
          {amountnull && (
            <p
              className={
                device === "mobile"
                  ? "ex_car_small_result_text"
                  : "ex_car_result_text"
              }
            >
              {amountnull}
            </p>
          )}
          {hasClicked && amount !== "" && (
            <div
              className={
                device === "mobile"
                  ? "ex_car_small_result_text"
                  : "ex_car_result_text"
              }
            >
              {!isNaN(amount) &&
                exchangeRate &&
                `${numberWithCommas(amount)} ${getCurrencyUnit(
                  toCurrency
                )} = ${numberWithCommas(
                  Math.round(parseFloat(amount) * exchangeRate)
                )} ${getCurrencyUnit(fromCurrency)}`}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(ExchangeComponent);
