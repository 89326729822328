import React from "react";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import "../../assets/scss/TripSectionComponent.scss";

const TripSectionComponent = ({ dataSet, setDataSet }) => {
  const addPlaceHandler = (dayIndex) => {
    if (dataSet[dayIndex].places.length >= 5) {
      alert("최대 5개까지의 장소만 추가할 수 있습니다.");
      return;
    }

    const updatedDataSet = [...dataSet];
    updatedDataSet[dayIndex].places.push({
      name: "",
      descriptions: [""],
    });
    setDataSet(updatedDataSet);
  };

  const removePlaceHandler = (dayIndex, placeIndex) => {
    if (dataSet[dayIndex].places.length === 1) {
      alert("장소는 최소 1개는 있어야합니다.");
      return;
    }
    const updatedDataSet = [...dataSet];
    updatedDataSet[dayIndex].places.splice(placeIndex, 1);
    setDataSet(updatedDataSet);
  };

  const addDescriptionHandler = (dayIndex, placeIndex) => {
    if (dataSet[dayIndex].places[placeIndex].descriptions.length >= 3) {
      alert("최대 3개까지의 설명만 추가할 수 있습니다.");
      return;
    }

    const updatedDataSet = [...dataSet];
    updatedDataSet[dayIndex].places[placeIndex].descriptions.push("");
    setDataSet(updatedDataSet);
  };

  const removeDescriptionHandler = (dayIndex, placeIndex, descIndex) => {
    if (dataSet[dayIndex].places[placeIndex].descriptions.length === 1) {
      alert("최소 1개의 메모는 있어야합니다.");
      return;
    }
    const updatedDataSet = [...dataSet];
    updatedDataSet[dayIndex].places[placeIndex].descriptions.splice(
      descIndex,
      1
    );

    // 해당 장소의 설명이 모두 삭제되면, 장소도 삭제
    if (updatedDataSet[dayIndex].places[placeIndex].descriptions.length === 0) {
      updatedDataSet[dayIndex].places.splice(placeIndex, 1);
    }

    setDataSet(updatedDataSet);
  };
  return (
    <>
      {dataSet.map((dayInfo, index) => {
        return (
          <React.Fragment key={index}>
            <p>{index + 1}일차</p>
            {dayInfo.places.map((place, placeIndex) => (
              <div key={placeIndex} className="nmpregister_sec">
                <input
                  className="npmregister_modal_location_input"
                  placeholder="장소"
                  value={place.name}
                  onChange={(e) => {
                    const updatedDataSet = [...dataSet];
                    updatedDataSet[index].places[placeIndex].name =
                      e.target.value;
                    setDataSet(updatedDataSet);
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  {place.descriptions.map((desc, descIndex) => {
                    let style = {};
                    if (descIndex === 0) {
                      style.marginTop = "0";
                    }
                    if (descIndex === place.descriptions.length - 1) {
                      style.marginBottom = "0";
                    }

                    return (
                      <div
                        key={descIndex}
                        className="npmregister_explain_sec"
                        style={style}
                      >
                        <input
                          placeholder="메모 입력"
                          value={desc}
                          onChange={(e) => {
                            const updatedDataSet = [...dataSet];
                            updatedDataSet[index].places[
                              placeIndex
                            ].descriptions[descIndex] = e.target.value;
                            setDataSet(updatedDataSet);
                          }}
                        />
                        <div
                          onClick={() =>
                            addDescriptionHandler(index, placeIndex)
                          }
                          className="npmregister_modal_explain_plus"
                        >
                          <img
                            src={convertS3toCloudFront(
                              "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/plus_image.png"
                            )}
                          />
                        </div>
                        <div
                          className="npmregister_modal_explain_delete"
                          onClick={() =>
                            removeDescriptionHandler(
                              index,
                              placeIndex,
                              descIndex
                            )
                          }
                        >
                          <img
                            src={convertS3toCloudFront(
                              "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/RENEWAL/230808_minus.png"
                            )}
                          />
                        </div>
                      </div>
                    );
                  })}
                  <div
                    onClick={() => removePlaceHandler(index, placeIndex)}
                    className="npmregister_modal_location_delete"
                  >
                    <span>장소 지우기</span>
                  </div>
                </div>
              </div>
            ))}
            <div className="npmregister_modal_location_plus">
              <span onClick={() => addPlaceHandler(index)}>장소추가하기</span>
              <hr />
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default TripSectionComponent;
