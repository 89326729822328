import React from "react";

const InputComponent = React.forwardRef((props, ref) => {
  return (
    <input
      {...props}
      ref={ref}
      style={{
        border: "none",
        borderBottom: "1px solid gray",
        borderRadius: "0",
        outline: "none",
      }}
    />
  );
});

export default React.memo(InputComponent);
