import React, { useState } from "react";
import Modal from "react-modal";
import "../../assets/scss/SamSungWarranty.scss";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import { useDeviceScreen } from "../../assets/js/util/useDeviceScreen";

function SamSungWarranty() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const device = useDeviceScreen();
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      <div className="warranty_detail_sec">
        <button onClick={openModal}>보장내용 확인하기</button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        appElement={document.getElementById("root")}
        style={{
          content: {
            margin: device === "mobile" ? "10% auto 0 auto" : "0 auto",
          },
        }}
      >
        <div className="warranty_detail_all">
          <button className="warranty_detail_closebtn_sec" onClick={closeModal}>
            <img
              className="warranty_detail_closebtn"
              src={convertS3toCloudFront(
                "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/menu_close.svg"
              )}
            />
          </button>
          <div>
            <h1>보장내용</h1>
            <table>
              <tbody>
                <tr>
                  <td colSpan={2}>보장명</td>
                  <td>지급사유</td>
                  <td>지급금액</td>
                </tr>
                <tr>
                  <td colSpan={2}>여행 중 상해 사망</td>
                  <td>해외여행 중 상해의 직접결과로써 사망한 경우</td>
                  <td>보험가입금액</td>
                </tr>
                <tr>
                  <td colSpan={2}>여행 중 상해후유장해</td>
                  <td>
                    해외여행 중 상해의 직접결과로써 장해지급률에 해당하는
                    장해상태가 되었을 경우
                  </td>
                  <td>보험가입금액 X (3~100%)</td>
                </tr>
                <tr>
                  <td rowSpan={3}>기본형 해외여행 실손의료비 특별약관</td>
                  <td>해외상해의료비</td>
                  <td>
                    해외여행 중 발생한 상해로 인하여 발생한 치료비 중
                    해외의료기관에서 치료로 발생한 금액
                  </td>
                  <td>
                    발생한 금액 중 가입금액 한도 내 보상(척추지압술, 추나요법,
                    침술, 부항, 뜸 등의치료로 인한 의료비는 하나의 상해·질병에
                    대하여 US $ 1,000 한도로 보상)
                  </td>
                </tr>
                <tr>
                  <td>국내상해급여의료비</td>
                  <td>
                    해외여행 중 발생한 상해로 인하여 국내 의료기관에 입원 또는
                    통원하여 급여치료를 받거나 급여 처방조제를 받은 경우
                  </td>
                  <td>
                    <p>아래의 의료비를 보험가입금액 한도 내에서 보상</p>
                    <p>
                      ①입원(입원실료, 입원제비용, 입원수술비) :
                      「국민건강보험법」에서 정한 요양급여 또는
                      「의료급여법」에서 정한 의료급여 중 본인부담금의 80%에
                      해당하는 금액
                    </p>
                    <p>
                      ②통원(외래제비용,외래수술비,처방조제비) :
                      「국민건강보험법」에서 정한 요양급여 또는
                      「의료급여법」에서 정한 의료급여 중 본인부담금에서 아래
                      표의 ‘통원항목별 공제금액’을 뺀 금액 (단, 통원 1회당(외래
                      및 처방조제 합산) 5만원 한도)
                    </p>
                    <p>의료기관</p>
                    <p>
                      전문요양기관, 상급종합병원, 종합병원에서의 외래 및 그에
                      따른 처방조제(자기부담금: 2만원과 보장대상 의료비의 20% 중
                      큰 금액)
                    </p>
                    <p>
                      의료기관(종합병원제외), 보건소에서의 외래 및 그에 따른
                      처방조제(자기부담금: 1만원과 보장대상 의료비의 20% 중 큰
                      금액)
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>국내상해비급여의료비</td>
                  <td>
                    해외여행 중 발생한 상해으로 인하여 국내 의료기관에 입원 또는
                    통원하여 비급여치료를 받거나 비급여 처방조제를 받은 경우
                    (3대비급여 제외)
                  </td>
                  <td>
                    <p>아래의 의료비를 보험가입금액 한도 내에서 보상</p>
                    <p>
                      ①입원(입원실료, 입원제비용, 입원수술비) :
                      「국민건강보험법」 또는 「의료급여법」에서 정한 비급여 중
                      본인부담금의 70%에 해당하는 금액
                    </p>
                    <p>
                      ②상급병실료 차액 : 비급여 병실료의 50% (단, 1일 평균금액
                      10만원 한도, 1일 평균금액은 입원기간 동안 비급여 병실료
                      전체를 총 입원일수로 나누어 산출)
                    </p>
                    <p>
                      ③통원(외래제비용,외래수술비,처방조제비) :
                      「국민건강보험법」 또는 「의료급여법」에서 정한 비급여 중
                      본인부담금에서 공제금액을 뺀 금액 (계약해당일로부터 연간
                      통원 100회 한도, 자기부담금:3만원과 보장대상 의료비의 30%
                      중 큰 금액)
                    </p>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>여행중 배상책임 특별약관</td>
                  <td>
                    해외여행 중 우연한 사고로 타인의 신체나 재산에 피해를 끼쳐
                    법률상 손해배상을 해주게 될 경우
                  </td>
                  <td>
                    실제 소요된 손해배상금, 손해방지비용 또는 회사의 승인을 받아
                    지급한 소송 및 화해,조정에 관한 비용 보상(단,1사고당 1만원
                    공제)
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>여행중 중대사고 구조송환비용 특별약관</td>
                  <td>
                    해외여행 중 탑승한 항공기나 선박이 행방불명 또는 조난된
                    경우,산악등반중에 조난된 경우, 상해나 질병으로 사망한
                    경우,또는직접원인으로 14일 이상 입원한 경우 피보험자 또는
                    법정상속인이 부담하는 비용이 발생한 경우
                  </td>
                  <td>
                    수색구조비용, 항공운임 등
                    교통비(2명분),숙박비(2인X14일한도), 이송비용,제잡비(10만원
                    한도)
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>항공기납치 특별약관</td>
                  <td>
                    해외여행 중 피보험자가 탑승한 비행기가 납치되어 예정된
                    목적지에 도착할 수 없게 된 경우
                  </td>
                  <td>1일 7만원씩 20일 한도</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="warranty_detail_bottom_closebtn_sec">
            <button
              className="warranty_detail_bottom_closebtn"
              onClick={closeModal}
            >
              닫기
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default SamSungWarranty;
