import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "../../assets/scss/InfoOne.scss";
import withAuth from "../../hoc/auth";
import InfoOneMainBanner from "./InfoOneMainBanner";
import InfoOneHeader from "./InfoOneHeader";
import InfoOneContent from "./InfoOneContent";
import HelmetComponent from "../header/HelmetComponent";

const InfoOne = () => {
  const location = useLocation();
  // 환율 계산기 이동
  const defaultContent =
    location.state && location.state.title === "환율계산기"
      ? "exchange"
      : "flight";
  const [activeContent, setActiveContent] = useState(defaultContent); // 초기값을 defaultContent로 설정
  const handleClick = content => {
    setActiveContent(content);
  };

  return (
    <>
      <HelmetComponent title="항공/숙박" />
      <InfoOneHeader handleClick={handleClick} activeContent={activeContent} />
      <div className="infoone_mainbanner_sec">
        <InfoOneMainBanner activeContent={activeContent} />
      </div>
      <div>
        <InfoOneContent activeContent={activeContent} />
      </div>
    </>
  );
};

export default withAuth(InfoOne, null);
