import {
  birthRegex,
  emailRegex,
  emptySpaceRegex,
  nameRegex,
  passwordRegex,
  phoneRegex,
  userIdRegex,
} from "./regex";
import {
  _each,
  _finalTrim,
  _keys,
  checkNullOrUndefined,
  compareValue,
  typeCheck,
} from "./util";

// id 정규식에 부합하면 true, 아니면 false
export const idRegexCheck = str => {
  if (str.match(userIdRegex)) {
    return true;
  } else {
    return false;
  }
};

// 공백이 있으면 true
export const emptyCheck = str => {
  if (str.match(emptySpaceRegex)) {
    return true;
  } else {
    return false;
  }
};

export const idCheck = id => {
  let messageInfo = {
    message: "",
    result: false,
    data: "",
  };

  // null undefined check
  if (!checkNullOrUndefined(id)) {
    messageInfo[
      "message"
    ] = `id값이 잘못된 데이터유형입니다.유형: ${typeof id}`;
    return messageInfo;
  }

  // 문자열인지 확인
  if (!typeCheck(typeof id, "string")) {
    messageInfo["message"] = `입력값이 문자열이 아닙니다.유형: ${typeof id}`;
    return messageInfo;
  }

  // 빈 값인지, 공백이 있는지 확인
  if (emptyCheck(id)) {
    messageInfo["message"] = "문자열에 공백이 존재합니다.";
    return messageInfo;
  }

  // 영문 소문자/숫자 4~16글자 사이가 아닌 경우.
  if (!idRegexCheck(id)) {
    messageInfo["message"] = "영문소문자/숫자 4~16글자 입력하세요.";
    return messageInfo;
  }

  // 모든 경우를 통과.
  messageInfo["message"] = "아이디에 부합";
  messageInfo["result"] = true;
  messageInfo["data"] = id;

  return messageInfo;
};

// 패스워드 정규식, 정규식에 부합하면 true
export const passwordRegexCheck = str => {
  if (str.match(passwordRegex)) {
    return true;
  }
  return false;
};

export const passwordCheck = password => {
  let messageInfo = {
    message: "",
    result: false,
    data: "",
  };

  // null undefined check
  if (!checkNullOrUndefined(password)) {
    messageInfo[
      "message"
    ] = `password값이 잘못된 데이터유형입니다.유형: ${typeof password}`;
    return messageInfo;
  }

  // 문자열인지 확인
  if (!typeCheck(typeof password, "string")) {
    messageInfo[
      "message"
    ] = `입력값이 문자열이 아닙니다.유형: ${typeof password}`;
    return messageInfo;
  }
  // 빈 값인지, 공백이 있는지 확인
  if (emptyCheck(password)) {
    messageInfo["message"] = "문자열에 공백이 존재합니다.";
    return messageInfo;
  }
  // 영문 소문자/숫자 4~16글자 사이가 아닌 경우.
  if (!passwordRegexCheck(password)) {
    messageInfo["message"] = "영문,숫자,특수문자 포함 8자 이상 입력하세요.";
    return messageInfo;
  }
  // 모든 경우를 통과.
  messageInfo["message"] = "패스워드에 부합";
  messageInfo["result"] = true;
  messageInfo["data"] = password;
  return messageInfo;
};

// 비밀번호 , 비밀번호 같은지 확인
export const confirmPasswordCheck = (password, confirmpassword) => {
  let messageInfo = {
    message: "",
    result: false,
    data: "",
  };
  if (confirmpassword.length === 0) {
    messageInfo["message"] = "비밀번호 확인을 입력해주세요.";
    messageInfo["result"] = false;
    return messageInfo;
  }
  let confirmResult = compareValue(password, confirmpassword);

  // 모든 경우를 통과.
  if (confirmResult) {
    messageInfo["message"] = "패스워드에 부합";
    messageInfo["result"] = true;
    return messageInfo;
  } else {
    messageInfo["message"] = "비밀번호가 일치하지 않습니다.";
    messageInfo["result"] = false;

    return messageInfo;
  }
};

const nameRegexCheck = str => {
  if (str.match(nameRegex)) {
    return true;
  } else {
    return false;
  }
};

// 이름 check
export const nameCheck = name => {
  let messageInfo = {
    message: "",
    result: false,
    data: "",
  };

  if (name.length === 0) {
    messageInfo["message"] = "이름을 입력해주세요.";
    return messageInfo;
  }
  if (!nameRegexCheck(name)) {
    messageInfo["message"] = "적합한 이름이 아닙니다.";
    return messageInfo;
  }

  messageInfo["message"] = "적합한이름";
  messageInfo["data"] = name;
  messageInfo["result"] = true;

  return messageInfo;
};
const phoneRegexCheck = str => {
  if (str.match(phoneRegex)) {
    return true;
  }
  return false;
};

// 핸드폰 check
export const phoneCheck = phone => {
  let messageInfo = {
    message: "",
    result: false,
    data: "",
  };
  if (phone.length === 0) {
    messageInfo["message"] = "핸드폰을 입력해주세요.";
    return messageInfo;
  }

  if (!phoneRegexCheck(phone)) {
    messageInfo["message"] = "적합한 핸드폰 번호가 아닙니다.";
    return messageInfo;
  }
  messageInfo["message"] = "적합한핸드폰번호";
  messageInfo["data"] = phone;
  messageInfo["result"] = true;
  return messageInfo;
};

const emailRegexCheck = str => {
  if (str.match(emailRegex)) {
    return true;
  }
  return false;
};
export const emailCheck = email => {
  let messageInfo = {
    message: "",
    result: false,
    data: "",
  };

  // null undefined check
  if (!checkNullOrUndefined(email)) {
    messageInfo[
      "message"
    ] = `email값이 잘못된 데이터유형입니다.유형: ${typeof email}`;
    return messageInfo;
  }

  // 문자열인지 확인
  if (!typeCheck(typeof email, "string")) {
    messageInfo["message"] = `입력값이 문자열이 아닙니다.유형: ${typeof email}`;
    return messageInfo;
  }

  if (email.length === 0) {
    messageInfo["message"] = "이메일을 입력하세요";
    return messageInfo;
  }

  // 빈 값인지, 공백이 있는지 확인
  if (emptyCheck(email)) {
    messageInfo["message"] = "문자열에 공백이 존재합니다.";
    return messageInfo;
  }
  // 영문 소문자/숫자 4~16글자 사이가 아닌 경우.
  if (!emailRegexCheck(email)) {
    messageInfo["message"] = "이메일 형식이 올바르지 않습니다.";
    return messageInfo;
  }
  // 모든 경우를 통과.
  messageInfo["message"] = "email에 부합";
  messageInfo["result"] = true;
  messageInfo["data"] = email;
  return messageInfo;
};

// 0: male, 1:female
export const genderCheck = gender => {
  let messageInfo = {
    message: "",
    result: false,
    data: "",
  };

  if (gender === "0" || gender === "1") {
    messageInfo["message"] = "성별에 부합";
    messageInfo["result"] = true;
    messageInfo["data"] = gender;
    return messageInfo;
  } else {
    messageInfo["message"] = "gender에 부적합";
    messageInfo["result"] = false;
    return messageInfo;
  }
};

const birthRegexCheck = str => {
  if (str.match(birthRegex)) {
    return true;
  }
  return false;
};

export const birthCheck = birth => {
  let messageInfo = {
    message: "",
    result: false,
    data: "",
  };
  if (birth === "") {
    messageInfo["message"] = "생년월일을 선택하세요";
    return messageInfo;
  }

  if (!birthRegexCheck(birth)) {
    messageInfo["message"] =
      "생년월일 정규식이 옳바르지 않습니다. 다시시도 후 관리자에게 문의하세요.";
    return messageInfo;
  }

  messageInfo["message"] = "생년월일에 적합";
  messageInfo["result"] = true;
  messageInfo["data"] = birth;
  return messageInfo;
};

//  여행시작일자 < 여행종료일자
export const tripDateVali = (start, end) => {
  let prev = new Date(start);
  let next = new Date(end);

  if (prev <= next) {
    return true;
  } else {
    return false;
  }
};
