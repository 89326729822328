import { createSlice } from "@reduxjs/toolkit";
import { UserService } from "../../service/UserService";

const authSlice = createSlice({
  name: "user",
  //초기 데이터
  initialState: {
    status: false,
    message: "",
    user: {
      id: "",
      name: "",
      birth: "",
      gender: "",
      isAuth: false,
      email: "",
      phone: "",
      marketing_argee: "",
    },
  },
  //state를 변동시키는 func
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(UserService.getUserAuth.pending, (state, action) => {
        state.loading = "pending";
      })
      .addCase(UserService.getUserAuth.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.user = action.payload;
      })
      .addCase(UserService.getUserAuth.rejected, (state, action) => {
        console.log("getUserAuth rejected");
        state.loading = "failed";
      })
      .addCase(UserService.login.pending, (state, action) => {
        console.log("login pending");
      })
      .addCase(UserService.login.fulfilled, (state, action) => {
        console.log("login fulfilled");
        if (action.payload.status === false) {
          state.message = action.payload.message;
        } else {
          state.user.isAuth = true;
        }
      })
      .addCase(UserService.logout.pending, (state, action) => {
        console.log("logout pending");
      })
      .addCase(UserService.logout.fulfilled, (state, action) => {
        console.log("logout fulfilled");
        if (action.payload.success) {
          state.message = action.payload.message;
          state.user = {
            id: "",
            name: "",
            birth: "",
            gender: "",
            isAuth: false,
            email: "",
            phone: "",
            marketing_argee: "",
          };
        } else {
          state.message = action.payload.message;
        }
      })
      .addCase(UserService.logout.rejected, (state, action) => {
        console.log("logout rejected");
      })
      .addCase(UserService.register.pending, (state, action) => {
        console.log("register pending");
      })
      .addCase(UserService.register.fulfilled, (state, action) => {
        console.log(action);
      })
      .addCase(UserService.register.rejected, (state, action) => {
        console.log("register rejected");
        console.log(action);
      })
      .addCase(UserService.registerAdPop.pending, (state, action) => {
        console.log("registerAdPop pending");
      })
      .addCase(UserService.registerAdPop.fulfilled, (state, action) => {
        console.log(action);
      })
      .addCase(UserService.registerAdPop.rejected, (state, action) => {
        console.log("registerAdPop rejected");
        console.log(action);
      })
      .addCase(UserService.findIdByEmail.pending, (state, action) => {
        console.log("findIdByEmail pending");
      })
      .addCase(UserService.findIdByEmail.fulfilled, (state, action) => {
        console.log(action);
      })
      .addCase(UserService.findIdByEmail.rejected, (state, action) => {
        console.log("findIdByEmail rejected");
        console.log(action);
      })
      .addCase(UserService.findIdByPhone.pending, (state, action) => {
        console.log("findIdByPhone pending");
      })
      .addCase(UserService.findIdByPhone.fulfilled, (state, action) => {
        console.log(action);
      })
      .addCase(UserService.findIdByPhone.rejected, (state, action) => {
        console.log("findIdByPhone rejected");
        console.log(action);
      })
      .addCase(UserService.deleteById.pending, (state, action) => {
        console.log("deleteById pending");
      })
      .addCase(UserService.deleteById.fulfilled, (state, action) => {
        console.log(action);
      })
      .addCase(UserService.deleteById.rejected, (state, action) => {
        console.log("deleteById rejected");
        console.log(action);
      })
      .addCase(UserService.update.pending, (state, action) => {
        console.log("update pending");
      })
      .addCase(UserService.update.fulfilled, (state, action) => {
        console.log(action);
      })
      .addCase(UserService.update.rejected, (state, action) => {
        console.log("update rejected");
        console.log(action);
      });
  },
});

export const { setCredentials, logOut } = authSlice.actions;
export default authSlice.reducer;
