import React from "react";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import "../../assets/scss/MainTitleComponent.scss";

const MainTitleComponent = ({ pClass, divClass, title }) => {
  return (
    <div className={divClass}>
      <img
        src={convertS3toCloudFront(
          "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/RENEWAL/230808_flight_icon.png"
        )}
        alt="비행기이미지"
        className="main_top10_sec_flight_img"
      />
      <p className={pClass}>{title}</p>
    </div>
  );
};

export default React.memo(MainTitleComponent);
