import axios from "axios";
import React, { useState, useEffect } from "react";
import "../../assets/scss/NMPmemorydata.scss";

function MemoryData({ schedule, setReschedule, reSchedule }) {
  const [data, setData] = useState([]);
  useEffect(() => {
    let body = {
      uuid: schedule.uuid,
      SEQ: schedule.SEQ,
    };
    axios.post(`/api/plan/detailPlan`, body).then((res) => {
      setData(res.data.data);
    });
  }, []);
  const deleteMemory = (e, target) => {
    e.stopPropagation();
    if (confirm("일정을 삭제하시겠습니까?")) {
      try {
        axios.get(`/api/plan/deletePlan/${target}`).then((res) => {
          alert("성공적으로 삭제했습니다.");
          setReschedule(!reSchedule);
        });
      } catch (err) {
        alert(err);
        window.location.reload();
      }
    }
  };

  return (
    <>
      <div>
        <hr />
        <div className="newmypage_schedule_detail_title_sec">
          <p className="newmypage_schedule_detail_title">{schedule.TITLE}</p>
          <p>
            {schedule.TRAVEL_START_DATE} ~ {schedule.TRAVEL_END_DATE}
          </p>
        </div>
        <div>
          <div>
            {Object.entries(data).map(([day, locations]) => (
              <div className="newmypage_schedule_detail_content" key={day}>
                <h2>{day}일차</h2>
                {Object.entries(locations).map(
                  ([locationName, locationData]) => (
                    <div
                      className="newmypage_schedule_detail_content_detail"
                      key={locationData.seq}
                    >
                      <h3>{locationName}</h3>
                      <div>
                        {Object.entries(locationData.descriptions).map(
                          ([descNum, descData]) => (
                            <p key={descData.seq}>
                              {descNum}
                              {descData.content}
                            </p>
                          )
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>
            ))}
          </div>
          <div
            onClick={(e) => deleteMemory(e, schedule.SEQ)}
            className="newmypage_schedule_detail_button"
          >
            <p>삭제하기</p>
          </div>
        </div>
      </div>
    </>
  );
}
export default React.memo(MemoryData);
