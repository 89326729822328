import React, { useEffect, useState } from "react";

function PreventDiagonalScroll() {
  const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });

  const handleTouchStart = (e) => {
    setStartPoint({ x: e.touches[0].clientX, y: e.touches[0].clientY });
  };

  const handleTouchMove = (e) => {
    const moveX = e.touches[0].clientX;
    const moveY = e.touches[0].clientY;

    const deltaX = moveX - startPoint.x;
    const deltaY = moveY - startPoint.y;

    const angle = Math.abs(Math.atan2(deltaY, deltaX) * (180 / Math.PI));

    if (angle < 45 || angle > 135) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("touchmove", handleTouchMove, { passive: false });

    return () => {
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchmove", handleTouchMove);
    };
  }, [startPoint]);

  return null;
}

export default PreventDiagonalScroll;
