import { useCallback, useContext, useEffect } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";

function useConfirmExit(confirmExit: () => boolean, when = true) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) {
      return;
    }

    const push = navigator.push;

    navigator.push = (...args: Parameters<typeof push>) => {
      const result = confirmExit();
      if (result !== false) {
        push(...args);
      }
    };

    return () => {
      navigator.push = push;
    };
  }, [navigator, confirmExit, when]);
}

// 새로고침은 alert X
export function usePrompt(message: string, when = true) {
  useEffect(() => {
    if (when) {
      window.onbeforeunload = function (e) {
        // 페이지 이동을 감지하기 위한 트릭
        if (
          document.activeElement instanceof HTMLAnchorElement ||
          document.activeElement instanceof HTMLButtonElement
        ) {
          e.returnValue = message;
          return message;
        }
      };
    }

    // 새로고침도 alert
    // export function usePrompt(message: string, when = true) {
    //   useEffect(() => {
    //     if (when) {
    //       window.onbeforeunload = function () {
    //         return message;
    //       };
    //     }

    return () => {
      window.onbeforeunload = null;
    };
  }, [message, when]);

  const confirmExit = useCallback(() => {
    const confirm = window.confirm(message);
    return confirm;
  }, [message]);
  useConfirmExit(confirmExit, when);
}
