import React, { useState } from "react";
import "../../assets/scss/SignForm_cert.scss";
import Loader from "../loader/LoaderComponent";
import HelmetComponent from "../header/HelmetComponent";
import AdSense from "../AdSense/DisplayAdHoriz";

function SignFormCert() {
  const [isLoading, setIsLoading] = useState(false);

  const nicePopup = (e) => {
    window.open("/Cert2", "popupChk");
  };

  return (
    <>
      <HelmetComponent title="본인인증" />
      <form type="hidden" name="form_chk" method="post">
        <input type="hidden" name="m" value="checkplusService" />
        <input type="hidden" name="EncodeData" value="" />
      </form>
      <div className="cert_step_all">
        <div className="cert_step_sec">
          <p className="cert_step1">01 본인인증</p>
          <p className="cert_step2">&gt;</p>
          <p className="cert_step2">02 정보입력 및 약관동의</p>
          <p className="cert_step2">&gt;</p>
          <p className="cert_step3">03 가입완료</p>
        </div>
        <div className="cert_all">
          <div className="cert_box">
            <div className="cert_title_sec">
              <p className="cert_title">01 본인인증</p>
            </div>
            <div className="cert_box_expl">
              <p className="cert_box_expl1">
                회원가입을 위해 본인인증을 진행해주세요.
              </p>
            </div>
            <div
              onClick={nicePopup}
              className={`cert_btn${isLoading ? " loader-visible" : ""}`}
            >
              {isLoading ? <Loader /> : "본인인증 하기"}{" "}
            </div>
            <div className="cert_title_expl">
              <p>
                * 트래블링크는 본인인증을 완료하신 고객만 회원가입이 가능합니다.
              </p>
            </div>
          </div>
          <AdSense />
        </div>
      </div>
    </>
  );
}
export default SignFormCert;
