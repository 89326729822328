import React, { useState, useRef, useEffect, useCallback } from "react";
import withAuth from "../../hoc/auth";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { UserService } from "../../service/UserService";
import "../../assets/scss/MyPage.scss";
import { useNavigate } from "react-router-dom";
import { _each, _returneach } from "../../assets/js/util/util";
import { emailCheck, passwordCheck } from "../../assets/js/util/check";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import InsuranceCert from "./InsuranceCert";
import Loader from "../loader/LoaderComponent";
import HelmetComponent from "../header/HelmetComponent";
import { useDeviceScreen } from "../../assets/js/util/useDeviceScreen";
import Button from "../customButton/Button";
import ThirdAgreeDelete from "./ThirdAgreeDelete";

function MyPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState();

  const user = useSelector(state => {
    return state.users.user;
  }, shallowEqual);

  const [newEmail, setNewEmail] = useState("");
  const [newMarketingAgree, setMarketingAgree] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [newEmailState, setNewEmailState] = useState(false);
  const [newPasswordState, setNewPasswordState] = useState(false);

  const [eventParticipation, setEventParticipation] = useState(false);
  const [eventCreatedAt, setEventCreatedAt] = useState(null);
  const [eventInsuranceCompany, setEventInsuranceCompany] = useState(null);
  const [eventStartDate, setEventStartDate] = useState(null);
  const [eventEndDate, setEventEndDate] = useState(null);
  const [eventCountry, setEventCountry] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [shinhanAgree, setShinHanAgree] = useState(false);
  const device = useDeviceScreen();
  const openModal = useCallback(() => {
    setShowModal(true);
  }, []);
  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  useEffect(() => {
    try {
      axios.get("/api/insurance/readShinhan").then(res => {
        if (!res.data.success) {
          alert(`${res.data.message}`);
          navigate("/");
        }
        if (res.data.data) {
          setShinHanAgree(true);
        }
      });
    } catch (err) {
      console.error(err);
      alert("데이터를 조회하는데실패했습니다.");
      return;
    }
  }, [user]);
  useEffect(() => {
    let userMarketing_agree = user.marketing_argee === "1" ? true : false;
    let userEmail = user.email;
    let password = user.password;
    setMarketingAgree(userMarketing_agree);
    setNewEmail(userEmail);
    setNewPassword(password);

    let body = {
      name: user.name,
      phone: user.phone,
    };
    if (user.id && body.name && body.phone) {
      axios.post("/api/insurance/eventPart", body).then(res => {
        const eventData = res.data.data;
        setEventParticipation(eventData.exists);
        if (eventData.exists) {
          const createDate = new Date(`${eventData.created_at}`);
          const dataSetting = {
            year: "numeric",
            month: "long",
            day: "numeric",
            timeZone: "UTC",
          };
          const formattedCreateDate = new Intl.DateTimeFormat(
            "ko-KR",
            dataSetting
          ).format(createDate);
          const startDate = new Date(`${eventData.travel_start_date}`);
          const formattedStartDate = new Intl.DateTimeFormat(
            "ko-KR",
            dataSetting
          ).format(startDate);

          const endDate = new Date(`${eventData.travel_end_date}`);
          const formattedEndDate = new Intl.DateTimeFormat(
            "ko-KR",
            dataSetting
          ).format(endDate);

          setEventCreatedAt(formattedCreateDate);
          setEventInsuranceCompany(eventData.insurance_company);
          setEventStartDate(formattedStartDate);
          setEventEndDate(formattedEndDate);
          setEventCountry(eventData.travel_country);
        }
      });
    }
  }, [user]);

  const emailRef = useRef();
  const passwordRef = useRef();

  const logoutHandler = () => {
    let body = { id: user.id };
    dispatch(UserService.logout(body)).then(res => {
      if (res.payload.success) {
        alert(res.payload.message);
      } else {
        alert(res.payload.message);
      }
    });
  };

  const userDeleteHandler = () => {
    if (
      window.confirm(
        `회원 탈퇴시 14일간 재가입이 불가하며, 트래블링크 내에서 진행 중인 모든 이벤트 참여 내역과 등록하신 콘텐츠가 삭제됩니다.\n회원 탈퇴 하시겠습니까?`
      )
    ) {
      dispatch(UserService.deleteById()).then(res => {
        if (res.payload.success) {
          if (window.fbq) {
            window.fbq("trackCustom", "TvResign", {});
          }

          if (window.gtag) {
            window.gtag("event", "TvResign");
          }
          alert(res.payload.message);
          navigate("/");
        } else {
          alert(res.payload.message);
        }
      });
    }
  };

  const changeEmail = e => {
    setNewEmailState(true);
    emailRef.current.focus();
  };

  const changePassword = e => {
    setNewPasswordState(true);
    passwordRef.current.focus();
  };

  const changeMarketing = useCallback(e => {
    setMarketingAgree(prevState => !prevState);
  }, []);

  const register = e => {
    setIsLoading(true);
    let body = {
      id: user.id,
      password: "",
      email: user.email,
      marketing_agree: user.marketing_argee === "1" ? true : false,
      passwordChange: false,
      emailChange: false,
      marketingChange: false,
      webusOn: false,
    };

    //password변경이 일어났을때
    if (newPasswordState) {
      let passwordCheckResult = passwordCheck(newPassword);
      if (!passwordCheckResult.result) {
        toast(`비밀번호${passwordCheckResult.message}`);
        passwordRef.current.focus();
        setIsLoading(false);
        return;
      }
      body["passwordChange"] = true;
      body["password"] = newPassword;
    }

    //email변경한거고
    if (newEmailState) {
      // 이메일이 이전과 같은 경우
      if (body["email"] !== newEmail) {
        let emailCheckResult = emailCheck(newEmail);
        if (!emailCheckResult.result) {
          toast(`${emailCheckResult.message}`);
          emailRef.current.focus();
          setIsLoading(false);
          return;
        }
        body["email"] = newEmail;
        body["emailChange"] = true;
      } else {
        toast(`이메일이 기존값과 동일합니다.`);
        emailRef.current.focus();
        setIsLoading(false);
        return;
      }
    }

    //마케팅수신동의 변경
    if (body["marketing_agree"] !== newMarketingAgree) {
      body["marketing_agree"] = newMarketingAgree;
      body["marketingChange"] = true;
    }

    if (
      body["passwordChange"] ||
      body["emailChange"] ||
      body["marketingChange"]
    ) {
      dispatch(UserService.update(body)).then(res => {
        if (res.payload.success) {
          //정보변경이 성공
          alert(res.payload.message);
          setMarketingAgree(body["marketing_agree"]);
          setIsLoading(false);
          location.reload();
        } else {
          if (res.payload.status === "admin") {
            alert("다시 시도 후 같은 증상 발생 시 관리자에게 문의해주세요.");
            setIsLoading(false);
            return;
          }
          //이메일이 중복된경우
          if (res.payload.status === "email") {
            toast(`${res.payload.message}`);
            emailRef.current.focus();
            setIsLoading(false);
            return;
          }
        }
      });
    } else {
      setIsLoading(false);
      alert("변경사항없습니다.");
    }
  };
  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <HelmetComponent title="My Page" />
      <div className="mypage">
        <div className="mypage_all">
          <div className="mypage_content1">
            <div className="mypage_left">
              <div>
                <h2>{user.name}님</h2>
                <h3>반갑습니다.</h3>
              </div>
            </div>
            <div className="mypage_right">
              <div className="mypage_content2">
                <div className="mypage_top_content">
                  <div className="mypage_id">
                    <p>아이디</p>
                    <input readOnly={true} placeholder={user.id}></input>
                  </div>

                  <div className="mypage_pw">
                    <p>비밀번호</p>
                    {newPasswordState ? (
                      <input
                        value={newPassword}
                        onChange={e => setNewPassword(e.target.value)}
                        ref={passwordRef}
                      />
                    ) : (
                      <>
                        <input
                          ref={passwordRef}
                          readOnly={true}
                          placeholder="********"
                        />
                        <Button
                          onClick={changePassword}
                          device={device}
                          text={device === "mobile" ? ["변경"] : ["변경하기"]}
                          color={"s"}
                          style={{ fontSize: "12px" }}
                        />
                      </>
                    )}
                  </div>
                  <div className="mypage_name">
                    <p>이름</p>
                    <input
                      type="text"
                      readOnly={true}
                      placeholder={user.name}
                    />
                  </div>
                  <div className="mypage_phone">
                    <p>휴대전화</p>
                    <input
                      type="text"
                      readOnly={true}
                      placeholder={user.phone}
                    ></input>
                  </div>
                  <div className="mypage_birth">
                    <p>생년월일</p>
                    <input
                      type="text"
                      readOnly={true}
                      placeholder={user.birth}
                    ></input>
                  </div>
                  <div className="mypage_email">
                    <p>이메일</p>
                    {newEmailState ? (
                      <>
                        <input
                          type="text"
                          placeholder={newEmail}
                          value={newEmail}
                          onChange={e => setNewEmail(e.target.value)}
                          ref={emailRef}
                        />
                      </>
                    ) : (
                      <>
                        <input
                          type="text"
                          ref={emailRef}
                          readOnly={true}
                          placeholder={newEmail}
                        />
                        <Button
                          onClick={changeEmail}
                          device={device}
                          text={device === "mobile" ? ["변경"] : ["변경하기"]}
                          color={"s"}
                          style={{ fontSize: "12px" }}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="mypage_checkbox">
                  <p className="mypage_marketing">마케팅 정보 수신 동의</p>
                  <div className="mypage_marketing_checkbox">
                    <input
                      checked={newMarketingAgree}
                      type="checkbox"
                      onChange={changeMarketing}
                      value={newMarketingAgree}
                    />
                    <label>마케팅 정보 수신을 동의합니다.</label>
                  </div>
                </div>
                {shinhanAgree && (
                  <ThirdAgreeDelete
                    device={device}
                    shinhanAgree={shinhanAgree}
                  />
                )}
                <div>
                  <p className="mypage_event_history">이벤트 참여내역</p>
                  {!eventParticipation ? (
                    <div className="mypage_event_sec">
                      <p className="mypage_event_content">
                        이벤트 참여내역이 없습니다.
                      </p>
                    </div>
                  ) : (
                    <div className="mypage_event_sec">
                      <table>
                        <tbody>
                          <tr className="mypage_event_content">
                            <td className="mypage_event_content_left_td">
                              {device === "mobile"
                                ? [
                                    <span key="line10">참여</span>,
                                    <br key="br9" />,
                                    <span key="line11">이벤트명</span>,
                                  ]
                                : ["참여 이벤트명"]}
                            </td>
                            <td className="mypage_event_content_right_td">
                              {device === "mobile"
                                ? [
                                    <span key="line12">
                                      {eventInsuranceCompany}
                                    </span>,
                                    <br key="br10" />,
                                    <span key="line13">
                                      여행자 보험 이벤트
                                    </span>,
                                  ]
                                : [
                                    <span key="line13">
                                      {eventInsuranceCompany}
                                    </span>,
                                    <span key="line14">
                                      {" "}
                                      여행자 보험 이벤트
                                    </span>,
                                  ]}
                            </td>
                          </tr>
                          <tr className="mypage_event_content">
                            <td className="mypage_event_content_left_td">
                              {device === "mobile"
                                ? [
                                    <span key="line15">이벤트</span>,
                                    <br key="br11" />,
                                    <span key="line16">참여일시</span>,
                                  ]
                                : ["이벤트 참여일시"]}
                            </td>
                            <td className="mypage_event_content_right_td">
                              {eventCreatedAt}
                            </td>
                          </tr>
                          <tr className="mypage_event_content">
                            <td className="mypage_event_content_left_td">
                              {device === "mobile"
                                ? [
                                    <span key="line17">여행</span>,
                                    <br key="br12" />,
                                    <span key="line18">시작 일자</span>,
                                  ]
                                : ["여행 시작 일자"]}
                            </td>
                            <td className="mypage_event_content_right_td">
                              {eventStartDate}
                            </td>
                          </tr>
                          <tr className="mypage_event_content">
                            <td className="mypage_event_content_left_td">
                              {device === "mobile"
                                ? [
                                    <span key="line18">여행</span>,
                                    <br key="br13" />,
                                    <span key="line19">종료 일자</span>,
                                  ]
                                : ["여행 종료 일자"]}
                            </td>
                            <td className="mypage_event_content_right_td">
                              {eventEndDate}
                            </td>
                          </tr>
                          <tr className="mypage_event_content">
                            <td className="mypage_event_content_left_td">
                              여행지명
                            </td>
                            <td className="mypage_event_content_right_td">
                              {eventCountry ? eventCountry : "국내 여행"}
                            </td>
                          </tr>
                          <tr className="mypage_event_content">
                            <td className="mypage_event_content_left_td">
                              보상문의
                            </td>
                            <td className="mypage_event_content_right_td">
                              1800-9010
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <Button
                        text={"가입 확인서 보기"}
                        color={"t"}
                        device={device}
                        style={{
                          width: "150px",
                          height: "30px",
                          margin: "0 auto 2% auto",
                          fontSize: "12px",
                        }}
                        onClick={openModal}
                        position={"c"}
                      />
                      <InsuranceCert
                        eventCreatedAtProp={eventCreatedAt}
                        eventInsuranceCompanyProp={eventInsuranceCompany}
                        eventStartDateProp={eventStartDate}
                        eventEndDateProp={eventEndDate}
                        eventCountryProp={eventCountry}
                        showModal={showModal}
                        closeModal={closeModal}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="mypage_content3">
            <Button
              text={"저장하기"}
              onClick={register}
              color={"s"}
              style={{
                width: "100%",
                height: "35px",
                margin: "2% auto 2% auto",
              }}
              device={device}
            />

            <Button
              text={"로그아웃"}
              onClick={logoutHandler}
              position={"l"}
              style={{ width: "110px", height: "35px", fontSize: "12px" }}
              color={"x"}
            />
            <Button
              text={"이벤트"}
              onClick={() => navigate("/event")}
              position={"r"}
              style={{
                width: "110px",
                height: "35px",
                left: "calc(100% - 110px)",
                fontSize: "12px",
              }}
              color={"x"}
            />

            <div>
              <Button
                onClick={userDeleteHandler}
                text={"회원 탈퇴하기"}
                color={"t"}
                position={"r"}
                style={{
                  border: "none",
                  color: "gray",
                  margin: "2% auto",
                  fontSize: "10px",
                }}
              />
            </div>

            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </div>
        </div>
        <div className="mypage_boundary"></div>
      </div>
    </>
  );
}
export default withAuth(MyPage, false);
