import React, { Suspense, lazy } from "react";
import "../../assets/scss/Main.scss";
import { shallowEqual, useSelector } from "react-redux";
import withAuth from "../../hoc/auth";
import HelmetComponent from "../header/HelmetComponent";
import A2HS from "../customButton/A2hsButton";

// lazy-loaded 컴포넌트들
const Responsive = lazy(() => import("../Slider/Slider"));
const Remainmenu = lazy(() => import("./ReMainmenu"));
const ReMainHOT = lazy(() => import("./ReMainHOT"));
const ReMainTop10 = lazy(() => import("./ReMainTop10"));
const ReMainCoupon = lazy(() => import("./ReMainCoupon"));
const ReMainadbanner = lazy(() => import("./ReMainadbanner"));
const ReMainsecure = lazy(() => import("./ReMainsecure"));
const RemainTravelComponent = lazy(() => import("./RemainTravelComponent"));
const ReMainTIP = lazy(() => import("./ReMainTIP"));

const Main = () => {
  const user = useSelector(state => state.users.user, shallowEqual);
  return (
    <Suspense fallback={<div>로딩 중...</div>}>
      <HelmetComponent title="트래블링크 : 든든한 여행파트너" />
      <div className="mainbanner">
        <Responsive user={user} />
      </div>
      <A2HS />
      <Remainmenu />
      {/* <RemainTravelComponent /> */}
      <ReMainTop10 />
      <ReMainCoupon />
      <ReMainadbanner />
      <ReMainsecure />
      <ReMainHOT />
      <ReMainTIP />
    </Suspense>
  );
};

export default withAuth(Main, null);
