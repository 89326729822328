import React from "react";
import "../../assets/scss/MobileNavigationbar.scss";
import { useNavigate } from "react-router-dom";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";

const MobileNavigation = () => {
  const navigate = useNavigate();
  const moveNavi = (id) => () => {
    navigate(`/${id}`);
  };
  return (
    <>
      <div className="mobile_navigation">
        <div className="mobile_navigation_all_button">
          <div
            className="mobile_navigation_button"
            onClick={moveNavi(`MemoryComponent`)}
          >
            <img
              src={convertS3toCloudFront(
                "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/RENEWAL/230809_nav_mytravel.png"
              )}
              alt=""
            />
            <p>내 여행기</p>
          </div>
          <div className="mobile_navigation_button" onClick={moveNavi(``)}>
            <img
              src={convertS3toCloudFront(
                "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/RENEWAL/230809_nav_home.png"
              )}
              alt=""
            />
            <p>Home</p>
          </div>
          <div
            className="mobile_navigation_button"
            onClick={moveNavi(`Newmypage`)}
          >
            <img
              src={convertS3toCloudFront(
                "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/RENEWAL/230809_nav_mylink.png"
              )}
              alt=""
            />
            <p>My Link</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileNavigation;
