import React from "react";
import "../../assets/scss/CategoryCustomButton.scss";

const CategoryCustomButton = ({
  title,
  handleClick,
  activeContent,
  compareContent,
  baseClassName,
}) => {
  return (
    <button
      className={`${baseClassName} ${
        activeContent === compareContent ? "clicked" : ""
      }`}
      onClick={() => handleClick(compareContent)}
    >
      {title}
    </button>
  );
};

export default React.memo(CategoryCustomButton);
