import React, { useState, useEffect } from "react";
import "../../assets/scss/SamSungModalForm.scss";
import { tripDateVali } from "../../assets/js/util/check";
import axios from "axios";
import SamSungModalCautions from "../Event/SamSungModalCautions";
import SamSungModalCautionDetail from "./SamSungModalCautionDetail";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";

function SamSungModalForm({ user, title }) {
  const { name, birth, gender, phone } = user;

  const [country, setCountry] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [agreeToThirdParty, setAgreeToThirdParty] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [error, setError] = useState("");
  const [cautionDetailChecked, setCautionDetailChecked] = useState(false);
  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");

  function maketoIsoString(obj) {
    return obj.toISOString().split("T")[0];
  }

  const handleSubmit = e => {
    e.preventDefault();

    if (
      !name ||
      !phone ||
      !gender ||
      !birth ||
      !country ||
      !startDate ||
      !endDate ||
      !agreeToThirdParty ||
      !title ||
      !selectedOption ||
      !cautionDetailChecked
    ) {
      setError("모든 필수항목을 입력해주세요.");
      return;
    }
    if (!tripDateVali(startDate, endDate)) {
      setError("여행출발일자가 여행종료일자보다 클 수는 없습니다.");
      return;
    }

    if (endDate < minDate || endDate > maxDate) {
      setError("여행종료일자가 여행시작일자와 3개월 이내로 설정되어야 합니다.");
      setEndDate("");
      return;
    }

    let body = {
      title,
      name,
      phone,
      gender,
      birth,
      country,
      startDate,
      endDate,
      agreeToThirdParty,
      selectedOption,
    };
    axios.post("/api/insurance/register", body).then(res => {
      if (res.data.success) {
        if (window.fbq) {
          window.fbq("trackCustom", "TvOverInsurance", {});
        }

        alert(`${res.data.message}`);
        window.location.reload();
      } else {
        alert(`${res.data.message}`);
      }
    });
  };
  // 여행시작일자를 선택했을 경우,
  const selectTravelStartDate = e => {
    let startDate = e.target.value;
    let changeStartDate = new Date(startDate);

    //여행출발일자
    setStartDate(startDate);

    //여행출발일자 기준
    setMinDate(maketoIsoString(changeStartDate));

    //여행출발일자 기준 -> 3개월 + 도착일자제한설정
    changeStartDate.setMonth(changeStartDate.getMonth() + 3);
    changeStartDate.setDate(changeStartDate.getDate() - 1); // 1일 감소
    setMaxDate(changeStartDate.toISOString().split("T")[0]);
  };

  // 여행종료일자를 선택했을 경우,
  const selectTravelEndDate = e => {
    //여행종료일자
    let endDate = e.target.value;
    setEndDate(endDate);
  };
  return (
    <div className="modal_all">
      <div>
        <img
          className="modal_samlogo"
          src={convertS3toCloudFront(
            "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/samsung_logo.png"
          )}
        ></img>
        <div className="modal_title">
          <h1>
            해외 여행 보험
            <br></br>무료 가입 신청하기
          </h1>
        </div>
        <div className="modal_div">
          <div>
            <p>이름 : </p>
          </div>
          <input
            className="modal_div_input_readonly"
            type="text"
            value={name}
            readOnly
          />
        </div>
        <div className="modal_div">
          <div>
            <p>휴대전화 : </p>
          </div>
          <input
            className="modal_div_input_readonly"
            type="text"
            value={phone}
            readOnly
          />
        </div>
        <div className="modal_div">
          <div>
            <p>성별 : </p>
          </div>
          <input
            className="modal_div_input_readonly"
            type="text"
            value={gender}
            readOnly
          />
        </div>
        <div className="modal_div">
          <div>
            <p>생년월일 : </p>
          </div>
          <input
            className="modal_div_input_readonly"
            type="text"
            value={birth}
            readOnly
          />
        </div>
        <div className="modal_div">
          <div>
            <p>여행국가 : </p>
          </div>
          <input
            className="modal_nation"
            type="text"
            value={country}
            onChange={e => setCountry(e.target.value)}
          ></input>
        </div>
        <div className="modal_div">
          <div>
            <p>여행 목적 :</p>
          </div>
          <select
            className="modal_purpose"
            onChange={e => setSelectedOption(e.target.value)}
            value={selectedOption}
          >
            <option value="">선택하세요</option>
            <option value="0">여행/관광</option>
            <option value="1">연수/출장</option>
          </select>
        </div>
        <div className="modal_div">
          <div>
            <p>여행시작일자 : </p>
          </div>
          <input
            className="modal_date"
            type="date"
            min={minDate}
            max="2025-05-21"
            value={startDate}
            onChange={e => selectTravelStartDate(e)}
          />
        </div>
        <div className="modal_div">
          <div>
            <p>여행종료일자 : </p>
          </div>
          <input
            className="modal_date"
            type="date"
            min={minDate}
            max="2025-05-21"
            value={endDate}
            onChange={e => selectTravelEndDate(e)}
          />
        </div>
        <p
          style={{ fontSize: "10px", textAlign: "right", marginBottom: "10px" }}
        >
          * 해외 여행의 경우 여행시작일로부터 최대 3개월까지 보장됩니다.
        </p>
        <p
          style={{ fontSize: "10px", textAlign: "right", marginBottom: "10px" }}
        >
          * 1회 여행에 한하여 보장됩니다.
        </p>
      </div>
      <div className="modal_check1">
        <div className="modal_check1_div">
          <label className="modal_check1_text">
            단체보험 가입에 관한 동의 (필수):
          </label>
          <label className="modal_check1_input">
            <input
              className="modal_check1_box"
              type="checkbox"
              checked={agreeToThirdParty}
              onChange={e => setAgreeToThirdParty(e.target.checked)}
            />
          </label>
        </div>
        <div className="modal_textarea">
          {/* <p>■ 개인정보 제3자 제공에 관한 사항</p>
          <p>
            회사는 회원고객의 동의가 있거나 관련 법령의 규정에 의한 경우를
            제외하고 어떠한 경우에도 ‘개인정보의 수집 및 이용목적’ 에서 고지한
            범위를 넘어서거나, 해당되는 회원 서비스 영역과 무관한 타 기업/기관에
            제공하거나 이용하지 않습니다. 단, 고객 서비스 수행에 직접적으로
            관계되는 업체에 한해서 제한적인 조건으로 개인정보가 제공되며 내용은
            다음과 같습니다.
          </p>
          <table className="modal_table">
            <thead className="modal_thead">
              <tr className="modal_tr">
                <th className="modal_th">서비스 영역</th>
                <th className="modal_th">제공받는 자</th>
                <th className="modal_th">제공하는 항목</th>
                <th className="modal_th">제공받는 자의 이용목적</th>
                <th className="modal_th">보유 및 이용기간</th>
              </tr>
            </thead>
            <tbody className="modal_tbody">
              <tr className="modal_tr">
                <td className="modal_td">트래블링크</td>
                <td className="modal_td">삼성화재, 비아이에스보험대리점</td>
                <td className="modal_td">
                  성명, 영문명, 생년월일, 성별, 연락처(이메일)
                </td>
                <td className="modal_td">
                  여행보험 가입, 보험금 지급 서비스 제공
                </td>
                <td className="modal_td">
                  서비스 제공 및 관계법령에 따른 보존기간
                </td>
              </tr>
            </tbody>
          </table> */}
          <p style={{ marginTop: "10px" }}>■ 단체보험 가입</p>
          <p>
            * 트래블링크는 트래블링크 서비스 사업자 (주)에스비제이를 계약자로
            회원을 피보험자로 하는 단체보험 계약을 체결할 수 있습니다. <br />*
            회원은 트래블링크가 서비스 사업자 (주)에스비제이 회원을 피보험자로
            하는 단체보험을 체결함에 있어 트래블링크가 일괄로 가입하는 방식에
            동의합니다.
            <br />* 보험 계약에 관한 세부내용은 보험사가 제공하는 보험약관에
            따르며 이 규약에서 정하지 않은 사항은 트래블링크가 보험사와 약정한
            바에 따릅니다.
          </p>
        </div>
      </div>
      {/* <div className='modal_check2'>
                <div className='modal_check2_div'>
                    <label className='modal_check2_text'>마케팅 활용을 위한 개인정보 제3자 제공 (선택):</label>
                    <label className='modal_check2_input'>
                        <input className="modal_check2_box" type="checkbox" checked={agreeToMarketing} onChange={(e) => setAgreeToMarketing(e.target.checked)} />
                    </label>
                </div>
                <div>
                    <textarea readOnly defaultValue="이용약관"></textarea>
                </div>
            </div> */}
      <SamSungModalCautionDetail
        cautionDetailChecked={cautionDetailChecked}
        setCautionDetailChecked={setCautionDetailChecked}
      />
      {error && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <label colSpan={2} style={{ color: "red", marginTop: "2%" }}>
            {error}
          </label>
        </div>
      )}
      <div className="modal_btn">
        <div className="modal_term_btn_over">
          <a
            href="/pdfs/OverseasInsuranceTerms.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button>해외 여행보험 약관</button>
          </a>
        </div>
        <label>
          <button type="button" onClick={handleSubmit}>
            가입 신청하기
          </button>
        </label>
      </div>
      <div>
        <SamSungModalCautions
          appElement={document.getElementById("root")}
        ></SamSungModalCautions>
      </div>
    </div>
  );
}

export default SamSungModalForm;
