import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../assets/scss/addr/PostCodeModal.scss";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
const PostCodeModal = ({ setShowAddrModal, getAddress }) => {
  const [keyword, setKeyword] = useState("");
  const [addressList, setAddressList] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const [errorMessage, setErrorMessage] = useState("");

  const getAddr = async () => {
    try {
      // 검색어 체크
      if (!checkSearchedWord(keyword)) {
        return;
      }
      const response = await axios.post("/api/addr/getAddrApi", {
        currentPage,
        countPerPage: 5,
        keyword: keyword,
        resultType: "json",
      });

      const { common, juso } = response.data;
      if (common.errorCode === "0") {
        const totP = Math.ceil(parseInt(common.totalCount) / parseInt(5));
        setAddressList(juso);
        setTotalPage(totP);
        if (common.totalCount === "0") {
          setErrorMessage("조회되는 주소가 없습니다.");
        } else {
          setErrorMessage("");
        }
      } else {
        alert(`${common.errorMessage}`);
        setAddressList([]);
        setErrorMessage(common.errorMessage);
        return;
      }
    } catch (error) {
      console.error("에러 발생", error);
      alert("네트워크 에러 발생");
      setAddressList([]);
      setErrorMessage("네트워크에러발생");
    }
  };

  useEffect(() => {
    if (keyword !== "") {
      getAddr();
    }
  }, [currentPage]);

  const checkSearchedWord = value => {
    if (value.length > 0) {
      // 특수문자 제거
      const expText = /[%=><]/;
      if (expText.test(value)) {
        alert("특수문자를 입력할 수 없습니다.");
        setKeyword(value.replace(expText, ""));
        return false;
      }

      // 특정문자열(SQL 예약어의 앞뒤 공백 포함) 제거
      const sqlArray = [
        "OR",
        "SELECT",
        "INSERT",
        "DELETE",
        "UPDATE",
        "CREATE",
        "DROP",
        "EXEC",
        "UNION",
        "FETCH",
        "DECLARE",
        "TRUNCATE",
      ];

      let regex;
      for (let i = 0; i < sqlArray.length; i++) {
        regex = new RegExp(sqlArray[i], "gi");

        if (regex.test(value)) {
          alert(`"${sqlArray[i]}"와(과) 같은 특정문자로 검색할 수 없습니다.`);
          setKeyword(value.replace(regex, ""));
          return false;
        }
      }
    }
    return true;
  };

  const handleEnterSearch = event => {
    const evtCode = window.netscape ? event.which : event.keyCode;
    if (evtCode === 13) {
      event.keyCode = 0;
      getAddr(); // JSONP 사용 시 엔터 검색
    }
  };

  // 분기처리를 해야하지만 먼저 바인딩부터 하고올게.
  const nextPage = () => {
    if (totalPage === currentPage) {
      alert("마지막 페이지입니다.");
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage === 1) {
      alert("첫 페이지입니다.");
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const cancleAddrModal = () => {
    setShowAddrModal(false);
  };

  return (
    <div className="addrpopup_overlay">
      <div className="addrpopup_section">
        <div className="addrpopup_header" onClick={cancleAddrModal}>
          <img
            src={convertS3toCloudFront(
              "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/backbtn.png"
            )}
            alt="뒤로가기버튼"
            width="30px"
            height="30px"
            style={{ cursor: "pointer" }}
          />
          <span style={{ fontWeight: "600" }}>주소 검색</span>
        </div>
        <div className="addrpopup_search">
          <input
            type="text"
            onKeyDown={handleEnterSearch}
            onChange={e => setKeyword(e.target.value)}
            value={keyword}
            className="signform_left_input"
            placeholder="주소를 검색하세요."
          />
          <button onClick={getAddr} className="signformbtn_addr">
            검색
          </button>
        </div>
        <div className="addrpopup_content">
          {addressList.length === 0 ? (
            errorMessage === "" ? (
              <div>
                <div>TIP</div>
                <div>도로명,건물명,지번을 입력해야합니다.</div>
                <div>
                  <div>
                    <span>도로명 + 건물번호 - 테헤란로 152</span>
                  </div>
                  <div>
                    <span>동/읍/면/리 + 번지 - 사당동 102</span>
                  </div>
                  <div>
                    <span>건물명,아파트명 - 청담자이아파트</span>
                  </div>
                </div>
              </div>
            ) : (
              <div>{errorMessage}</div>
            )
          ) : (
            addressList.map((address, index) => {
              const { roadAddr, zipNo, jibunAddr } = address;
              return (
                <div
                  key={index + zipNo}
                  onClick={() => getAddress(address)}
                  className="addpopup_content_detail"
                >
                  <div className="addpopup_content_detail_left">
                    <div className="addpopup_content_detail_left1">
                      <div style={{ width: "100px" }}>
                        <span>도로명</span>
                      </div>
                      <div style={{ width: "100%" }}>
                        <span>{roadAddr}</span>
                      </div>
                    </div>
                    <div className="addpopup_content_detail_left1">
                      <div style={{ width: "100px" }}>
                        <span>지번</span>
                      </div>
                      <div style={{ width: "100%" }}>
                        <span>{jibunAddr}</span>
                      </div>
                    </div>
                  </div>
                  <div className="addpopup_content_detail_right">{zipNo}</div>
                </div>
              );
            })
          )}
        </div>
        {addressList.length > 0 && (
          <div className="addrpopup_paging">
            <img
              onClick={previousPage}
              src={convertS3toCloudFront(
                "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/backbtn.png"
              )}
              alt="<"
              width="30px"
              height="30px"
              style={{ cursor: "pointer" }}
            />
            <span>
              {currentPage}/{totalPage}
            </span>
            <img
              onClick={nextPage}
              src={convertS3toCloudFront(
                "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/backbtn.png"
              )}
              alt=">"
              width="30px"
              height="30px"
              style={{ cursor: "pointer", transform: "rotate(180deg)" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(PostCodeModal);
