import React, { useEffect, useState } from "react";
import axios from "axios";
import MainTitleComponent from "../main/MainTitleComponent";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import "../../assets/scss/MyPrepare.scss";

const MyPrepare = ({ user, openMaterialsModal }) => {
  const [reApi, setReApi] = useState(false);
  const [prepare, setPrepare] = useState([]);
  const [editStatus, setEditStatus] = useState(null);
  const [editTitle, setEditTitle] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const openImageModal = (image) => {
    setModalImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (user && user.isAuth) {
      try {
        axios.get("/api/prepare/getMyPrepare").then((res) => {
          setPrepare(res.data.data);
          setEditStatus(null);
        });
      } catch (err) {
        console.error(err);
      }
    }
  }, [user, reApi]);

  const editPrePareTitle = (index) => {
    try {
      if (editTitle.length === 0) {
        alert("편집할 내용을 입력해주세요.");
        return;
      }
      let body = {
        title: editTitle,
        seq: index,
      };
      axios.post("/api/prepare/registerTitle", body).then((res) => {
        alert(`${res.data.message}`);
        if (res.data.success) {
          setReApi(!reApi);
        } else {
          window.location.reload();
        }
      });
    } catch (err) {
      console.error(err);
      // window.location.reload();
    }
  };

  const changeEditStatusHandler = (category, index) => {
    if (category === "편집취소") {
      setEditStatus(null);
      setEditTitle("");
    } else {
      setEditTitle("");
      setEditStatus(index);
    }
  };

  const titleChangeHandler = (e) => {
    setEditTitle(e.target.value);
  };

  const deleteHandler = (index) => {
    //삭제 api 안날리는 경우 validataion
    if (!prepare[index].SEQ) {
      alert("등록먼저 부탁드립니다.");
      return;
    }
    if (confirm("여행준비물을 삭제하시겠습니까?")) {
      if (
        prepare[index].title === `준비물${index + 1}` &&
        prepare[index].MEMO === "" &&
        prepare[index].url === ""
      ) {
        alert("등록 먼저 부탁드립니다.");
        return;
      }

      let body = {
        seq: index + 1,
        SEQ: prepare[index].SEQ,
      };
      try {
        axios.post("/api/prepare/delete", body).then((res) => {
          alert(`${res.data.message}`);
          if (res.data.success) {
            setReApi(!reApi);
          } else {
            window.location.reload();
          }
        });
      } catch (err) {
        console.error(err);
      }
    }
  };

  const matchingItem = prepare.find((item) => item.url === modalImage);

  const memoContent = matchingItem ? matchingItem.MEMO : "";

  return (
    <>
      <div className="infoone_FL_sec">
        <MainTitleComponent
          title="여행 준비물"
          divClass="infoone_FL_title_sec"
          pClass="infoone_FL_title"
        />
        {isModalOpen && (
          <div
            className="newmypage_materials_prepare_modal"
            onClick={closeModal}
          >
            <div className="newmypage_materials_prepare_modal_modalContent">
              <img src={convertS3toCloudFront(modalImage)} alt="Preview" />
              <p>{memoContent}</p>
            </div>
          </div>
        )}
        <div className="newmypage_materials_img">
          {prepare.map((item, index) => (
            <div className="newmypage_materials_all" key={index}>
              <div className="newmypage_materials_img_sec">
                {item.url ? (
                  <img
                    src={convertS3toCloudFront(item.url)}
                    alt=""
                    onClick={() => openImageModal(item.url)}
                    className="newmypage_materials_img_big"
                  />
                ) : (
                  <div
                    className="newmypage_materials_img_null"
                    onClick={(e) => openMaterialsModal(e, index)}
                  >
                    +
                  </div>
                )}
              </div>
              <div className="newmypage_materials_subtilte_sec">
                {editStatus === index ? (
                  <>
                    <input
                      placeholder={editTitle}
                      maxLength="30"
                      value={editTitle}
                      onChange={titleChangeHandler}
                    />
                    <div className="newmypage_materials_subtilte_edit_sec">
                      <p
                        onClick={() =>
                          changeEditStatusHandler("편집취소", index)
                        }
                      >
                        편집취소
                      </p>
                      <p onClick={() => editPrePareTitle(index)}>등록</p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="newmypage_materials_subtilte">
                      <p>{item.title}</p>
                    </div>
                    <div className="newmypage_materials_subtilte_edit">
                      <p onClick={() => changeEditStatusHandler("편집", index)}>
                        편집
                      </p>
                      <p onClick={() => deleteHandler(index)}>삭제</p>
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default React.memo(MyPrepare);
