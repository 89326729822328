import React, { useEffect, useState } from "react";
import "../../assets/scss/Event.scss";
import { urlMove } from "../../assets/js/util/navigation";
import Container from "@mui/material/Container";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import Loader from "../loader/LoaderComponent";
import { shallowEqual, useSelector } from "react-redux";
import HelmetComponent from "../header/HelmetComponent";
import withAuth from "../../hoc/auth";

const EventComponent = () => {
  const [eventData, setEventData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const user = useSelector((state) => state.users.user, shallowEqual);
  useEffect(() => {
    axios
      .get("/api/events/read")
      .then((res) => {
        setEventData([res.data]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  if (isLoading) {
    return <Loader />;
  }

  const needLoginClickHandler = (url) => {
    if (user && user.isAuth) {
      urlMove(url);
    } else {
      navigate("/Login");
    }
  };

  return (
    <>
      <Container fixed style={{ marginBottom: "30vh", width: "90%" }}>
        <HelmetComponent title="Event" />
        <div className="event_title">
          <img
            className="event_fly_image"
            src={convertS3toCloudFront(
              "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/eventimages.png"
            )}
          ></img>
          <div className="event_title_text">
            <h2>다양한 혜택받고 지금 떠나세요!</h2>
            <h1>이벤트</h1>
          </div>
        </div>
        {eventData[0] &&
          eventData[0].map((item, key) => {
            return item.login === "y" ? (
              <div
                className="event_card"
                key={item.seq}
                onClick={() => {
                  if (item.source_url.includes("Event/7")) {
                    if (window.fbq) {
                      window.fbq("trackCustom", "TvInsuEventBannerClick", {});
                    }

                    if (window.gtag) {
                      window.gtag("event", "TvInsuEventBannerClick");
                    }
                  }
                  needLoginClickHandler(item.source_url);
                }}
              >
                <img
                  className="event_img"
                  src={convertS3toCloudFront(item.banner_path)}
                ></img>
              </div>
            ) : (
              <div
                className="event_card"
                key={item.seq}
                onClick={() => {
                  if (item.source_url.includes("Event/7")) {
                    if (window.fbq) {
                      window.fbq("trackCustom", "TvInsuEventBannerClick", {});
                    }

                    if (window.gtag) {
                      window.gtag("event", "TvInsuEventBannerClick");
                    }
                  }
                  urlMove(item.source_url);
                }}
              >
                <img
                  className="event_img"
                  src={convertS3toCloudFront(item.banner_path)}
                ></img>
              </div>
            );
          })}
      </Container>
    </>
  );
};

export default withAuth(EventComponent, null);
