import React from "react";
import "../../assets/scss/TermsPritext_v3_230821.scss";
// 개인정보개정
const TermsPritext_v5 = () => {
  return (
    <>
      <div className="v3_termpri_all">
        <h1 className="v3_termpri_all_title">개인정보 처리방침</h1>
        <p className="v3_termpri_5">
          주식회사 트래블링크 (이하 "당사")는 고객의 개인정보를 중요시하며,
          개인정보의 보호와 관련하여 「정보통신망 이용촉진 및 정보보호 등에 관한
          법률」 및 「개인정보보호법」 등 관련 법 상의 개인정보 보호 규정을
          준수하며, 본 개인정보 처리방침을 따릅니다.
        </p>
        <p className="v3_termpri_5">
          본 개인정보 처리방침은 당사의 개인정보 처리와 관련된 정보를 제공하고
          고객이 가진 권리와 그 권리를 행사할 수 있는 방법에 대해 설명하며,
          당사의 서비스 이용 중 개인정보와 관련하여 문의가 있을 경우 연락할 수
          있는 개인정보 보호책임자 및 담당자의 연락처를 안내합니다.
        </p>
        <div>
          <h1 className="v3_termpri_1">
            1.수집하는 개인정보 및 이용에 관한 안내
          </h1>
        </div>
        <div>
          <p className="v3_termpri_2">1). 회원 가입 시 수집되는 정보</p>
          <p className="v3_termpri_3">(1). 서비스 가입 및 이용</p>
          <p className="v3_termpri_4">
            ㄱ. 성명, 이메일주소, 비밀번호,휴대폰 번호 (필수)
          </p>
          <p className="v3_termpri_3">(2). 서비스 가입 본인 확인</p>
          <p className="v3_termpri_4">
            ㄱ. 성명, 생년월일, 성별, 내외국인정보, DI, 이통사 정보,
            휴대폰번호,주소 CI (필수)
          </p>
          <p className="v3_termpri_3">(3). 위치기반 서비스 제공</p>
          <p className="v3_termpri_4">ㄱ. 개인 위치정보 (필수)</p>
          <p className="v3_termpri_3">
            (4). 불법/부정 이용 방지 및 서비스 분석
          </p>
          <p className="v3_termpri_4">
            ㄱ. 자동생성정보 - 기기정보 (기기종류, OS버전), 이용기록 (IP주소,
            쿠키, 서비스, 이용기록 등) (필수)
          </p>
          <p className="v3_termpri_3">(5). 보유 및 이용 기간</p>
          <p className="v3_termpri_4">
            ㄱ. 회원탈퇴시까지 (단, 관계법령에 따름 또한 위치기반 서비스 제공시
            이용되는 개인위치정보는 일회성으로 이용 후 지체 없이 파기됨)
          </p>
          <p className="v3_termpri_2">2). 서비스 이용시 수집되는 정보</p>
          <p className="v3_termpri_3">(1). 상품 구매 시(공통)</p>
          <p className="v3_termpri_4">
            ㄱ. 구매 및 예약자 정보 (구매 및 예약자 이름, 이메일 주소, 휴대폰
            번호, 배송주소) (필수)
          </p>
          <p className="v3_termpri_3">(2). 고객센터 문의</p>
          <p className="v3_termpri_4">
            ㄱ. 이름, 이메일 주소, 휴대폰 번호 (필수)
          </p>
          <p className="v3_termpri_3">(3). 이벤트 참여</p>
          <p className="v3_termpri_4">
            ㄱ. 이름, 생년월일, 성별, 휴대폰 번호, 여행 국가, 여행 시작일자,
            여행 종료일자 (필수)
          </p>
          <p className="v3_termpri_4">
            ㄴ. 기타 이벤트 혜택 수령에 별도의 개인정보가 필요한 경우 이벤트
            설명 페이지 하단에 기재 (필수)
          </p>
          <p className="v3_termpri_3">(4). 보유 및 이용 기간</p>
          <p className="v3_termpri_4">
            ㄱ. 회원탈퇴시까지 (단, 관계법령에 따름 또한 위치기반 서비스 제공시
            이용되는 개인위치정보는 일회성으로 이용 후 지체 없이 파기됨)
          </p>
        </div>
        <div>
          {/* 개인정보개정 */}
          <h1 className="v3_termpri_1">2.개인정보의 제공</h1>
          <p className="v3_termpri_5">
            회원의 개인정보를 ‘수집하는 개인정보 및 이용에 관한 안내’에서 고지한
            범위 내에서 사용하며, 동 범위를 초과하여 타인에게 제공하지 않습니다.
            다만 양질의 서비스 제공을 위해 상품 예약 및 구매 단계에서 필요한
            정보를 회원에게 동의를 구한 후 제3자에게 제공합니다.
          </p>
          <p className="v3_termpri_2">1). 제공받는 자</p>
          <p className="v3_termpri_3">
            (1). 삼성화재해상보험주식회사 : 국 내/외 여행자 보험 가입
          </p>
          <p className="v3_termpri_4">
            ㄱ. 제공 정보 : 이름, 생년월일, 성별, 휴대폰 번호, 여행 국가, 여행
            시작일자, 여행 종료일자{" "}
          </p>
          <p className="v3_termpri_4">
            * 개인정보 제공은 고객 선택사항으로 동의하지 않으셔도, 서비스 이용에
            제한은 없습니다.
          </p>
          <br />
          <p className="v3_termpri_3">
            (2). 신한라이프생명보험주식회사 및 해당 보험사 보험모집 위탁계약을
            체결한 자 : 보험상품 판매를 위한 마케팅(전화) 자료활용 및
            경품발송(문자)
          </p>
          <p className="v3_termpri_4">
            ㄱ. 제공 정보 : 성명, 생년월일, 성별, 휴대폰번호, 참여매체,
            동의완료일시
          </p>
          <p className="v3_termpri_4">
            ㄴ. 보유 및 이용기간: 제공동의일로부터 1년(고객동의 철회 시 지체없이
            파기)
          </p>
          <p className="v3_termpri_4">
            * 개인정보 제공은 고객 선택사항으로 동의하지 않으셔도, 서비스 이용에
            제한은 없습니다.
          </p>
          <br />
          <p className="v3_termpri_3">
            (3). ㈜포스트엠앤씨 : 제휴 상품 판매를 위한 마케팅(전화) 자료활용 및
            경품발송(문자)
          </p>
          <p className="v3_termpri_4">
            ㄱ. 제공 정보 : 이름, 생년월일, 성별, 휴대폰번호, 여행국가
          </p>
          <p className="v3_termpri_4">
            ㄴ. 보유 및 이용기간 : 제공동의일로부터 1년 (고객동의 철회 시,
            지체없이 파기)
          </p>
          <p className="v3_termpri_4">
            * 개인정보 제공은 고객 선택사항으로 동의하지 않으셔도, 서비스 이용에
            제한은 없습니다.
          </p>
          <br />
          <p className="v3_termpri_3">
            (4). ㈜케이에스제이컴퍼니 : 제휴 상품 판매를 위한 마케팅(전화)
            자료활용 및 경품발송(문자)
          </p>
          <p className="v3_termpri_4">
            ㄱ. 제공 정보 : 이름, 생년월일, 성별, 휴대폰번호, 여행국가
          </p>
          <p className="v3_termpri_4">
            ㄴ. 보유 및 이용기간 : 제공동의일로부터 1년 (고객동의 철회 시,
            지체없이 파기)
          </p>
          <p className="v3_termpri_4">
            * 개인정보 제공은 고객 선택사항으로 동의하지 않으셔도, 서비스 이용에
            제한은 없습니다.
          </p>
          <br />
          <p className="v3_termpri_3">
            (5). ㈜디에이위더스 : 제휴 상품 판매를 위한 마케팅(전화) 자료활용 및
            경품발송(문자)
          </p>
          <p className="v3_termpri_4">
            ㄱ. 제공 정보 : 이름, 생년월일, 성별, 휴대폰번호, 여행국가
          </p>
          <p className="v3_termpri_4">
            ㄴ. 보유 및 이용기간 : 제공동의일로부터 1년 (고객동의 철회 시,
            지체없이 파기)
          </p>
          <p className="v3_termpri_4">
            * 개인정보 제공은 고객 선택사항으로 동의하지 않으셔도, 서비스 이용에
            제한은 없습니다.
          </p>
          <br />
          <p className="v3_termpri_3">
            (6). 주식회사 써프림 머천트 : 제휴 상품 판매를 위한 마케팅(전화)
            자료활용 및 경품발송(문자)
          </p>
          <p className="v3_termpri_4">
            ㄱ. 제공 정보 : 이름, 생년월일, 성별, 휴대폰번호, 여행국가, 주소
          </p>
          <p className="v3_termpri_4">
            ㄴ. 보유 및 이용기간 : 제공동의일로부터 1년 (고객동의 철회 시,
            지체없이 파기)
          </p>
          <p className="v3_termpri_4">
            * 개인정보 제공은 고객 선택사항으로 동의하지 않으셔도, 서비스 이용에
            제한은 없습니다.
          </p>
          <br />
          <p className="v3_termpri_3">
            (7). (주)코리아나화장품(홍대 뷰티센터) : 스킨케어 체험단 모집을 위한
            마케팅(전화) 자료활용 및 마케팅 및 경품 발송(문자)
          </p>
          <p className="v3_termpri_4">
            ㄱ. 제공 정보 : 이름, 생년월일, 성별, 휴대폰번호, 지역
          </p>
          <p className="v3_termpri_4">
            ㄴ. 보유 및 이용기간 : 제공동의일로부터 1년 (고객동의 철회 시,
            지체없이 파기)
          </p>
          <p className="v3_termpri_4">
            * 개인정보 제공은 고객 선택사항으로 동의하지 않으셔도, 서비스 이용에
            제한은 없습니다.
          </p>
          <br />
          <p className="v3_termpri_3">
            (8). DB손해보험(주) : 전화상담을 통한 보험상품 안내, 판매 및 모집을
            위한 텔레마케팅, SMS, EMAIL 등 발송 및 서비스 가입시 가입처리
          </p>
          <p className="v3_termpri_4">
            ㄱ. 제공 정보 : 성명, 생년월일, 성별, 휴대폰번호, 지역, 통신사,
            동의완료일시
          </p>
          <p className="v3_termpri_4">
            ㄴ. 보유 및 이용기간 : 제공동의일로부터 1년 (고객동의 철회 시,
            지체없이 파기)
          </p>
          <p className="v3_termpri_4">
            * 개인정보 제공은 고객 선택사항으로 동의하지 않으셔도, 서비스 이용에
            제한은 없습니다.
          </p>
        </div>
        <div>
          <h1 className="v3_termpri_1">3.개인정보의 위탁</h1>
          <p className="v3_termpri_5">
            트래블링크는 서비스의 제공, 서비스의 유지/관리, 이용자 편의 제공 등
            원활한 업무 수행을 위하여 다음과 같이 개인정보 처리업무를 외부
            전문업체에 위탁 운영하고 있습니다.
          </p>
          {/* 개인정보개정 */}
          <p className="v3_termpri_2">1). 수탁자</p>
          <p className="v3_termpri_3">
            (1). 위버스커뮤니케이션즈(주) : 이벤트대행(이벤트
            참여이력/경품지급/동의3자제공), 제휴관련 고객 CS처리(고객의 별도
            동의 없이 개인정보처리방침 내 고지만 진행)
          </p>
          <p className="v3_termpri_3">
            (2). ㈜포스트엠앤씨 : 마케팅대행(이벤트 혜택 안내, 제휴상품 안내),
            제휴관련 고객 CS처리(고객의 별도 동의 없이 개인정보처리방침 내
            고지만 진행)
          </p>
          <p className="v3_termpri_3">
            (3). ㈜케이에스제이컴퍼니 : 마케팅대행(이벤트 혜택 안내, 제휴상품
            안내), 제휴관련 고객 CS처리(고객의 별도 동의 없이 개인정보처리방침
            내 고지만 진행)
          </p>
          <p className="v3_termpri_3">
            (4). ㈜디에이위더스 : 마케팅대행(이벤트 혜택 안내, 제휴상품 안내),
            제휴관련 고객 CS처리(고객의 별도 동의 없이 개인정보처리방침 내
            고지만 진행)
          </p>
          <p className="v3_termpri_3">
            (5). 주식회사 써프림머천트 : 마케팅대행(이벤트 혜택 안내, 제휴상품
            안내), 제휴관련 고객 CS처리(고객의 별도 동의 없이 개인정보처리방침
            내 고지만 진행)
          </p>
          <p className="v3_termpri_3">
            (5). DB손해보험 ㈜ : 전화상담을 통한 보험상품 안내, 판매 및 모집을
            위한 텔레마케팅, SMS, EMAIL 등 발송 및 서비스 가입시 가입처리
          </p>
        </div>
        <div>
          <h1 className="v3_termpri_1">
            4.개인정보의 보유 및 이용기간, 파기 절차 및 방법
          </h1>
          <p className="v3_termpri_5">
            수집된 개인정보의 보유•이용기간은 서비스 이용계약
            체결(회원가입)시부터 서비스 이용계약 해지(탈퇴 신청)시까지 입니다.
            원칙적으로, 회사는 개인정보 수집 및 이용 목적이 달성된 후에는 해당
            정보를 지체 없이 파기합니다.
          </p>
          <p className="v3_termpri_5">
            단, 관계 법령에 의해 보존할 필요가 있는 경우, 회사는 아래와 같이
            관계 법령에서 정한 일정한 기간 동안 회원 정보를 보관합니다.
          </p>
          <p className="v3_termpri_2">1). 관계 법령에 의한 개인정보 보유</p>
          <p className="v3_termpri_3">
            (1). 소비자의 불만 또는 분쟁 처리에 관한 기록 : 3년 (근거 법률
            :전자상거래 등에서의 소비자 보호에 관한 법률)
          </p>
          <p className="v3_termpri_3">
            (2). 표시, 광고에 관한 기록 : 6개월 (근거 법률 : 전자상거래 등에서의
            소비자 보호에 관한 법률)
          </p>
          <p className="v3_termpri_3">
            (3). 위치정보에 관한 기록 : 6개월 (근거 법률 : 위치정보의 보호 및
            이용등에 관한 법률)
          </p>
          <p className="v3_termpri_3">
            (4). 웹사이트 방문 기록 : 3개월 (근거 법률 : 통신비밀보호법)
          </p>
          <p className="v3_termpri_2">2). 파기절차</p>
          <p className="v3_termpri_5">
            이용자의 개인정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우
            별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 보유
            사유에 따라(관계 법령에 의한 개인정보 보유 참조) 일정 기간 저장된 후
            파기됩니다. 이때, 별도의 DB로 옮겨진 개인정보는 회원이 동의한 목적을
            초과하거나 혹은 법률이 정한 경우 외의 다른 목적으로 이용되지
            않습니다.
          </p>
          <p className="v3_termpri_2">3). 파기방법</p>
          <p className="v3_termpri_5">
            종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각 등을 통하여
            파기하고, 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는
            기술적 방법 또는 물리적 방법을 사용하여 파기합니다.
          </p>
          <p className="v3_termpri_2">4). 개인정보유효기간제 도입</p>
          <p className="v3_termpri_5">
            트래블링크를 최종 이용 후 1년 동안 이용 기록이 없는
            고객(장기미이용회원)의 개인정보는 별도로 분리하여 안전하게 관리하게
            되며, 대상자에게는 분리 보관 처리일을 기준으로 하여 최소 30일 이전에
            이메일 주소를 통해 안내를 합니다. 분리 보관된 개인정보는 관련 법령에
            의한 정보보호 보유 사유에 따라(관계 법령에 의한 개인정보 보유 참조)
            일정 기간 저장된 후 파기됩니다.
          </p>
        </div>
        <div>
          <h1 className="v3_termpri_1">5.고객의 권리와 의무</h1>
          <p className="v3_termpri_2">1). 고객의 권리</p>
          <p className="v3_termpri_5">
            고객은 언제든지 수집 정보에 대하여 수정, 동의 철회, 삭제, 열람을
            요청할 수 있습니다. 다만, 동의 철회, 삭제 시 서비스의 일부 또는 전부
            이용이 제한될 수 있습니다. 트래블링크가 수집한 개인정보는 아래와
            같은 방법으로 확인할 수 있습니다.
          </p>
          <p className="v3_termpri_3">(1). 트래블링크 웹페이지 : 마이페이지</p>
          <p className="v3_termpri_5">
            트래블링크 웹을 통해 직접 확인하지 못하는 정보는 트래블링크
            고객센터(070-4814-3963, 1:1 채팅 문의, info@sbjgroup.co.kr)에
            요청하여 확인할 수 있습니다.
          </p>
          <p className="v3_termpri_5">
            개인정보 동의 철회 및 삭제, 처리 정지를 요청하고자 하는 경우에는
            트래블링크 고객센터(070-4814-3963, 1:1 채팅 문의,
            info@sbjgroup.co.kr)를 통해 요청할 수 있습니다. 또한, 고객은 언제든
            회원 탈퇴를 통해 개인정보의 수집 및 이용 동의를 철회할 수 있습니다.
            이러한 요청 시, 서비스의 일부 또는 전부 이용이 제한될 수 있습니다.
            또한, 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여
            불가피한 경우, 다른 사람의 생명·신체를 해할 우려가 있거나 다른사람의
            재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우, 개인정보를
            처리하지 아니하면 정보 주체와 약정한 서비스를 제공하지 못하는 등
            계약의 이행이 곤란한 경우로서 정보주체가 그 계약의 해지 의사를
            명확하게 밝히지 아니한 경우에는 동의 철회, 삭제, 처리 정지가 어려울
            수 있습니다.
          </p>
          <p className="v3_termpri_5">
            요청하신 처리가 완료될 때까지 해당 정보를 이용하거나 타인에게
            제공하지 않습니다. 또한, 합리적인 사유로 잘못된 개인정보를 제3자에게
            이미 제공한 경우, 그 결과를 지체 없이 제3자에게 통지하여 동의 철회,
            삭제, 처리 정지하도록 조치합니다.
          </p>
          <p className="v3_termpri_2">2). 고객의 의무</p>
          <p className="v3_termpri_5">
            고객은 자신의 개인정보를 보호할 의무가 있으며, 회사의 귀책사유가
            없이 ID(이메일 주소), 비밀번호, 접근매체 등의 양도·대여·분실이나
            로그인 상태에서 이석 등 고객 본인의 부주의나 관계법령에 의한
            보안조치로 차단할 수 없는 방법이나 기술을 사용한 해킹 등 회사가
            상당한 주의에도 불구하고 통제할 수 없는 인터넷상의 문제 등으로
            개인정보가 유출되어 발생한 문제에 대해 회사는 책임을 지지 않습니다.
            고객은 자신의 개인정보를 최신의 상태로 유지해야 하며, 고객의
            부정확한 정보 입력으로 발생하는 문제의 책임은 고객 자신에게
            있습니다. 타인의 개인정보를 도용한 회원가입 또는 ID 등을 도용하여
            결제 처리 시 고객 자격 상실과 함께 관계법령에 의거하여 처벌될 수
            있습니다. 고객은 아이디, 비밀번호 등에 대해 보안을 유지할 책임이
            있으며 제3자에게 이를 양도하거나 대여할 수 없습니다. 고객은 회사의
            개인정보보호 정책에 따라 보안을 위한 주기적인 활동에 협조할 의무가
            있습니다.
          </p>
          <p className="v3_termpri_2">3). 파기방법</p>
          <p className="v3_termpri_5">
            종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각 등을 통하여
            파기하고, 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는
            기술적 방법 또는 물리적 방법을 사용하여 파기합니다.
          </p>
          <p className="v3_termpri_2">4). 개인정보유효기간제 도입</p>
          <p className="v3_termpri_5">
            트래블링크를 최종 이용 후 1년 동안 이용 기록이 없는
            고객(장기미이용회원)의 개인정보는 별도로 분리하여 안전하게 관리하게
            되며, 대상자에게는 분리 보관 처리일을 기준으로 하여 최소 30일 이전에
            이메일 주소를 통해 안내를 합니다. 분리 보관된 개인정보는 관련 법령에
            의한 정보보호 보유 사유에 따라(관계 법령에 의한 개인정보 보유 참조)
            일정 기간 저장된 후 파기됩니다.
          </p>
        </div>
        <div>
          <h1 className="v3_termpri_1">
            6.인터넷접속정보 파일 설정 및 맞춤형 광고 안내
          </h1>
          <p className="v3_termpri_5">
            트래블링크는 고객 개개인에게 간편하고 효율적인 개인 맞춤화된
            서비스를 제공하기 위해서 서비스 이용과정에서 자동으로 생성하는
            정보나 개인식별이 불가능한 기기 정보를 수집하고 저장할 수 있습니다.
          </p>
          <p className="v3_termpri_2">1). 쿠키</p>
          <p className="v3_termpri_5">
            쿠키(Cookie)는 사용자의 효율적이고 안전한 웹 사용을 보장하기 위해
            웹사이트 접속 시 사용자의 디바이스로 전송 및 저장되는 작은 텍스트
            파일입니다. 쿠키가 저장된 이후 다시 웹사이트를 방문할 경우 쿠키는
            웹사이트 사용자의 디바이스를 인식하여 지난 설정과 과거 이용내역을
            자동으로 불러옵니다. 또한 방문한 서비스 정보, 서비스 접속 시간 및
            빈도, 서비스 이용 과정에서 생성된 또는 제공(입력)한 정보 등을
            분석하여 고객의 취향과 관심에 특화된 서비스(광고 포함)를 제공할 수
            있습니다. 고객은 쿠키에 대한 선택권을 가지고 있으며, 웹브라우저에서
            옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다
            확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
            현재 사용하는 브라우저의 쿠키 설정 확인 및 변경은 아래와 같은 방법을
            통해 가능합니다.
          </p>
          <p className="v3_termpri_3">
            (1). Microsoft Edge{" "}
            <span
              onClick={() =>
                (window.location.href =
                  "https://support.microsoft.com/ko-kr/topic/microsoft-edge%EC%97%90%EC%84%9C-%EC%BF%A0%ED%82%A4-%EB%B3%B4%EA%B8%B0-a7d95376-f2cd-8e4a-25dc-1de753474879")
              }
            >
              보기
            </span>
          </p>
          <p className="v3_termpri_3">
            (2). Chrome{" "}
            <span
              onClick={() =>
                (window.location.href =
                  "https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=ko")
              }
            >
              보기
            </span>
          </p>
          <p className="v3_termpri_3">
            (3). FireFox{" "}
            <span
              onClick={() =>
                (window.location.href =
                  "https://support.mozilla.org/ko/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=ko")
              }
            >
              보기
            </span>
          </p>
          <p className="v3_termpri_3">
            (4). Safari{" "}
            <span
              onClick={() =>
                (window.location.href =
                  "https://support.apple.com/ko-kr/guide/safari/sfri11471/mac")
              }
            >
              보기
            </span>
          </p>
          <p className="v3_termpri_2">2). 맞춤형 광고</p>
          <p className="v3_termpri_5">
            트래블링크는 고객을 더 잘 이해하여 무의미하고 불필요한 광고 노출을
            지양하고, 고객의 관심 상품 등을 반영한 정보성 광고의 노출을
            증가시키기 위하여 온라인상의 이용자 활동 정보를 활용합니다. 단,
            인종, 종교, 성적 지향, 병력 정보와 같은 민감 카테고리로 분류된 행태
            정보는 맞춤형 광고에 활용하지 않습니다.
          </p>
          <p className="v3_termpri_5">
            온라인 행태정보 기반의 맞춤형 광고는 ‘쿠키(Cookie)’ 또는
            ‘광고식별자(ADID/IDFA)’를 기반으로 웹 사이트 방문 이력, 앱 사용
            이력, 구매 및 검색 이력 등 이용자의 관심, 흥미, 기호 및 성향
            등(이하, 행태정보)을 파악하고 분석할 수 있는 행태정보를 활용하여
            맞춤형 광고를 제공합니다.
          </p>
          <p className="v3_termpri_3">
            (1).페이스북, 카카오, 구글 : 이용자의 웹사이트 방문이력, 이용자의
            검색 이력, 광고식별자 (사용자 관심 기반의 맞춤형 광고 제공을 위함)
          </p>
          <p className="v3_termpri_5">
            이용자는 언제든지 이러한 맞춤형 광고 수신을 거부할 수 있으며, 이
            경우 맞춤형 광고가 아닌 임의의 광고가 노출됩니다. 맞춤형 광고 수신을
            거부하는 방법은 다음과 같습니다.
          </p>
          <p className="v3_termpri_3">
            (1). 안드로이드 : 구글 설정 → 광고 → 광고 맞춤설정 선택 해제
          </p>
          <p className="v3_termpri_3">
            (2). IOS : 아이폰 설정 → 개인정보보호 → 광고 → 광고 추적 제한
          </p>
        </div>
        <div>
          <h1 className="v3_termpri_1">
            7.개인정보보호를 위한 기술적, 관리적 조치
          </h1>
          <p className="v3_termpri_5">
            트래블링크에서는 여행자분들의 개인정보를 보호하기 위해 아래와 같은
            대책을 시행하고 있습니다.
          </p>
          <p className="v3_termpri_2">1). 암호화 보관</p>
          <p className="v3_termpri_3">
            법령에서 암호화를 요구하는 고유식별정보, 비밀번호, 결제정보 외에도
            전화번호, 이메일 등도 암호화하여 보관하고 있으며, 암호화된
            통신구간을 이용하여 전송하고 있습니다.
          </p>
          <p className="v3_termpri_2">2). 안전한 관리</p>
          <p className="v3_termpri_3">
            침입차단시스템, 위협 탐지 시스템을 이용하여 해킹이나 바이러스
            등으로부터 개인정보의 유출과 훼손을 막기 위해 노력하고 있습니다.
            최신 백신 프로그램을 이용하여 이용자들의 개인정보나 자료가
            유출되거나 손상되지 않도록 방지하고 있으며, 암호화 통신 등을 통해
            네트워크상에서 개인정보를 안전하게 송수신하고 있습니다.
          </p>
          <p className="v3_termpri_2">3). 개인정보취급자 최소화</p>
          <p className="v3_termpri_3">
            개인정보를 처리하는 직원을 최소한으로 관리하며, 개인정보의
            다운로드가 가능한 직원들의 업무용 PC 에 대해 인터넷망과 연결을
            차단하여 개인정보 유출 가능성을 줄이고 있습니다. 또한 정기 및 비정기
            교육을 실시하여 개인정보보호에 대한 임직원들의 인식 제고를 위해
            노력하고 있습니다.
          </p>
        </div>
        <div>
          <h1 className="v3_termpri_1">8.개인위치정보의 처리</h1>
          <p className="v3_termpri_5">
            트래블링크는 위치정보의 보호 및 이용 등에 관한 법률에 따라 이용자의
            개인위치정보를 안전하게 관리합니다.
          </p>
          <p className="v3_termpri_2">
            1). 개인위치정보의 처리목적 및 보유기간
          </p>
          <p className="v3_termpri_3">
            트래블링크는 위치정보 이용약관에서 정한 목적 범위 내에서
            개인위치정보를 수집‧이용하며, 개인위치정보를 일회성으로 이용 후 지체
            없이 파기합니다.
          </p>
          <p className="v3_termpri_2">
            2). 개인위치정보 수집ㆍ이용ㆍ제공사실 확인자료의 보유근거 및
            보유기간
          </p>
          <p className="v3_termpri_3">
            트래블링크는 위치정보의 보호 및 이용 등에 관한 법률 제16조 제2항에
            근거하여 이용자의 위치정보 수집ㆍ이용ㆍ제공사실 확인자료를 기록,
            보존하며, 1년간 보관합니다.
          </p>
          <p className="v3_termpri_2">3). 개인위치정보 파기 절차 및 방법</p>
          <p className="v3_termpri_3">
            트래블링크는 개인위치정보의 처리목적이 달성된 경우, 개인위치정보를
            재생이 불가능한 방법으로 안전하게 파기하고 있습니다. 전자적 파일
            형태는 복구 및 재생이 불가능한 기술적인 방법으로 파기하며, 출력물
            등은 분쇄하거나 소각하는 방식으로 파기합니다.
          </p>
          <p className="v3_termpri_2">
            4). 개인위치정보의 제3자 제공 및 통보에 관한 사항
          </p>
          <p className="v3_termpri_3">
            트래블링크는 이용자의 사전 동의 없이 개인위치정보를 제3자에게
            제공하지 않으며, 이용자가 지정한 제3자에게 개인위치정보를 제공하는
            경우 매회 개인위치정보주체에게 제공받는 자, 제공일시 및 제공목적을
            즉시 통보합니다.
          </p>
          <p className="v3_termpri_2">
            5). 보호의무자의 권리ㆍ의무 및 그 행사방법
          </p>
          <p className="v3_termpri_3">
            트래블링크는 아래의 경우에 해당하는 자(이하 “8세 이하의 아동 등”)의
            위치정보의 보호 및 이용 등에 관한 법률 제26조2항에 해당하는 자(이하
            “보호의무자”)가 8세 이하의 아동 등의 생명 또는 신체보호를 위하여
            개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의 동의가 있는
            것으로 봅니다.
          </p>
          <p className="v3_termpri_3">(1). 8세 이하의 아동</p>
          <p className="v3_termpri_3">(2). 피성년후견인</p>
          <p className="v3_termpri_3">
            (3). 장애인복지법 제2조 제2항 제2호에 따른 정신적 장애를 가진
            사람으로서 장애인고용촉진 및 직업재활법 제2조 제2호에 따른
            중증장애인에 해당하는 사람(장애인복지법 제32조에 따라 장애인 등록을
            한 사람만 해당한다)
          </p>
          <p className="v3_termpri_5">
            8세 이하의 아동 등의 생명 또는 신체의 보호를 위하여 개인위치정보의
            이용 또는 제공에 동의를 하고자 하는 보호의무자는 서면동의서에
            보호의무자임을 증명하는 서면을 첨부하여 회사에 제출하여야 하며,
            보호의무자는 8세 이하의 아동 등의 개인위치정보 이용 또는 제공에
            동의하는 경우 위치 서비스 약관상의 이용자의 권리를 모두 가집니다.
          </p>
          <p className="v3_termpri_2">6). 위치정보 관리책임자의 정보</p>
          <p className="v3_termpri_3">
            트래블링크의 위치정보 관리책임자는 아래 개인정보보호책임자가
            겸직하고 있습니다.
          </p>
        </div>
        <div>
          <h1 className="v3_termpri_1">
            9.개인정보 보호책임자 및 기관 문의 안내
          </h1>
          <p className="v3_termpri_5">
            고객의 개인정보에 관한 업무를 총괄해서 책임지고, 개인정보와 관련된
            불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자 및
            담당부서를 지정하여 운영하고 있습니다. 회사가 제공하는 서비스를
            이용하면서 발생하는 모든 개인정보 보호 관련 문의, 불만, 피해구제
            등에 관한 사항은 아래로 연락하여 문의할 수 있습니다. 트래블링크는
            이러한 문의에 대해 지체 없이 답변 및 처리할 것입니다.
          </p>
          <p className="v3_termpri_2">1). 개인정보 보호책임자</p>
          <p className="v3_termpri_3">(1). 성명 : 정일웅</p>
          <p className="v3_termpri_3">(2). 연락처 : 070-4814-3963</p>
          <p className="v3_termpri_3">(3). 이메일 : info@sbjgroup.co.kr</p>
          <p className="v3_termpri_2">2). 개인정보 관리담당자</p>
          <p className="v3_termpri_3">(1). 성명 : 윤덕</p>
          <p className="v3_termpri_3">(2). 연락처 : 02-6953-1890</p>
          <p className="v3_termpri_3">(3). 이메일 : info@sbjgroup.co.kr</p>
          <p className="v3_termpri_5">
            기타 개인정보 침해 신고나 상담이 필요하신 경우에는 아래 기관에 문의
            가능합니다.
          </p>
          <p className="v3_termpri_5">
            ▶ 개인정보침해 신고센터 : (국번없이) 118 / (웹사이트:
            privacy.kisa.or.kr)
          </p>
          <p className="v3_termpri_5">
            ▶ 대검찰청 사이버범죄수사단 : (국번없이) 1301 / (웹사이트:
            www.spo.go.kr)
          </p>
          <p className="v3_termpri_5">
            ▶ 경찰청 사이버수사국 : (국번없이) 182 / (웹사이트:
            http://ecrm.cyber.go.kr)
          </p>
          <p className="v3_termpri_5">
            ▶ 개인정보 분쟁조정위원회 : (국번없이) 1833-6972 / (웹사이트:
            www.kopico.go.kr)
          </p>
        </div>
        <div>
          <h1 className="v3_termpri_1">10.고지의 의무</h1>
          <p className="v3_termpri_5">
            개인정보 처리방침을 변경하는 경우 회사는 홈페이지의 공지사항란 또는
            이메일로 지체 없이 알리고, 이용자가 언제든지 변경된 사항을 쉽게
            알아볼 수 있도록 조치하겠습니다.
          </p>
          {/* 개인정보개정 */}
          <p className="v3_termpri_5">공지일자 : 2024년 04월 03일</p>
          <p className="v3_termpri_5">시행일자 : 2023년 04월 03일</p>
        </div>
      </div>
    </>
  );
};

export default TermsPritext_v5;
