import React, { useEffect, useState, useRef, useMemo } from "react";
import Datamap from "datamaps";
import axios from "axios";
import { alpha2ConvertAlpha3 } from "../../assets/js/util/alpha2ConvertAlpha3";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import MainTitleComponent from "../main/MainTitleComponent";
import { isEmpty } from "../../assets/js/util/util";
import { handleClick } from "../../assets/js/util/navigation";
import {
  flightIcon,
  openApiEmb,
  openApiSecure,
  searchAlarm,
  securePc,
} from "../../assets/js/util/apiConstant";
import SecureInfo from "./SecureInfo";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import "../../assets/scss/SecureTravel_Secure.scss";
import Button from "../customButton/Button";
import { useDeviceScreen } from "../../assets/js/util/useDeviceScreen";

const steps = [
  "1단계 여행유의",
  "2단계 여행자제",
  "3단계 출국권고",
  "4단계 여행금지",
  "특별여행주의보",
];

const safetyLevels = {
  HIGH: "#FF0000",
  MEDIUM_HIGH: "#FF4500",
  MEDIUM: "#FF8C00",
  LOW_MEDIUM: "#FFA500",
  LOW: "#008000",
  defaultFill: "#D3D3D3",
};
const SecureTravelSecure = () => {
  const navigate = useNavigate();
  const mapRef = useRef();
  const embRef = useRef(null);

  const device = useDeviceScreen();
  const [secureStep, setSecureStep] = useState(1);
  const [secureembStep, setSecureembStep] = useState(1);
  const [oneCountry, setOneCountry] = useState({});
  const [twoCountry, setTwoCountry] = useState({});
  const [threeCountry, setThreeCountry] = useState({});
  const [fourCountry, setFourCountry] = useState({});
  const [fiveCountry, setFiveCountry] = useState({});

  const countryDataMap = useMemo(
    () => ({
      1: oneCountry,
      2: twoCountry,
      3: threeCountry,
      4: fourCountry,
      5: fiveCountry,
    }),
    [oneCountry, twoCountry, threeCountry, fourCountry, fiveCountry]
  );

  const [countryList, setCountryList] = useState([]);

  const [selectCountry, setSelectCountry] = useState(null);
  const [searchCountry, setSearchCountry] = useState("");
  const [apiData, setApiData] = useState({});
  const [magazineData, setMegazineData] = useState([]);
  //선택한 나라의 대사관 정보
  const [emb, setEmb] = useState(null);
  const location = useLocation();
  const selectOptions = countryList.map((country) => ({
    value: country,
    label: country,
  }));
  useEffect(() => {
    axios.get(securePc).then((res) => {
      setMegazineData(res.data);
    });
  }, []);

  useEffect(() => {
    if (searchCountry !== "") {
      axios
        .get(`${openApiEmb}${searchCountry}`)
        .then((res) => {
          setEmb(res.data.data[0]);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [searchCountry]);
  useEffect(() => {
    axios.get(openApiSecure).then((res) => {
      const filteredCountries = res?.data?.data?.filter(
        (country) =>
          country.alarm_lvl != null &&
          country.country_nm != undefined &&
          country.country_nm != null
      );

      const countryList = filteredCountries.map(
        (country) => country.country_nm
      );
      const initialData = { 1: {}, 2: {}, 3: {}, 4: {}, 5: {} };

      const alarmData = filteredCountries.reduce((acc, country) => {
        if (country.alarm_lvl >= 1 && country.alarm_lvl <= 5) {
          acc[country.alarm_lvl][
            alpha2ConvertAlpha3[country.country_iso_alp2]
          ] = country.alarm_lvl;
        }
        return acc;
      }, initialData);

      setCountryList(countryList);
      setApiData(alarmData[1]);
      setOneCountry(alarmData[1]);
      setTwoCountry(alarmData[2]);
      setThreeCountry(alarmData[3]);
      setFourCountry(alarmData[4]);
      setFiveCountry(alarmData[5]);
    });
  }, []);

  useEffect(() => {
    if (isEmpty(apiData)) return;

    while (mapRef.current.firstChild) {
      mapRef.current.firstChild.remove();
    }

    const mapData = Object.keys(apiData).reduce((acc, countryCode) => {
      const alarmLevel = apiData[countryCode];
      let fillColor;

      switch (alarmLevel) {
        case 1:
          fillColor = "LOW";
          break;
        case 2:
          fillColor = "LOW_MEDIUM";
          break;
        case 3:
          fillColor = "MEDIUM";
          break;
        case 4:
          fillColor = "MEDIUM_HIGH";
          break;
        case 5:
          fillColor = "HIGH";
          break;
        default:
          fillColor = "defaultFill"; // 기본값
      }

      acc[countryCode] = { fillKey: fillColor };
      return acc;
    }, {});

    new Datamap({
      element: mapRef.current,
      fills: {
        ...safetyLevels,
        defaultFill: "#D3D3D3",
      },
      data: mapData,
      highlightOnHover: true,
    });
  }, [apiData, secureStep]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const initialCountry = searchParams.get("country");
    if (initialCountry) {
      countrySelectHandler({ value: initialCountry, label: initialCountry });
      if (embRef.current) {
        const topPosition =
          embRef.current.getBoundingClientRect().top + window.pageYOffset - 20;
        window.scrollTo({
          top: topPosition,
          behavior: "smooth",
        });
      }
    }
  }, []);

  const onChangeSecureStep = (step) => {
    const currentCountryData = countryDataMap[step];
    if (isEmpty(currentCountryData)) {
      alert(steps[step - 1] + "에 대한 정보가 없습니다.");

      return;
    }
    setSecureStep(step);
    setApiData(currentCountryData);
  };

  const countrySelectHandler = (obj) => {
    const countryName = obj.value;
    setSearchCountry(countryName);
    setSelectCountry(obj);
    axios
      .get(`${searchAlarm}${countryName}`)
      .then((res) => {
        const level = res.data.data[0].alarm_lvl;
        setSecureembStep(level);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      {apiData && (
        <>
          <div
            style={{
              backgroundColor: "#eeeeef",
              margin: "0 auto",
            }}
          >
            <div ref={mapRef} className="securetravel_whole_map"></div>
          </div>
        </>
      )}
      <div className="securemap_step">
        {steps.map((step, index) => (
          <div
            key={index}
            className={secureStep === index + 1 ? "clicked" : ""}
            onClick={() => onChangeSecureStep(index + 1)}
          >
            {step}
          </div>
        ))}
      </div>
      <SecureInfo />
      <div className="infoone_FL_sec">
        <MainTitleComponent
          title="내가 갈 여행지 검색"
          divClass="infoone_FL_title_sec"
          pClass="infoone_FL_title"
        />
        <div>
          <Select
            value={selectCountry}
            onChange={countrySelectHandler}
            options={selectOptions}
            placeholder="내가 갈 여행지(국가) 검색"
            className="securemap_search_input"
          />
          {/* {selectCountry ? (
            <Button
              text={`${selectCountry.label} 대사관 정보 저장하기`}
              color={"s"}
              style={{
                width: device !== "pc" ? "90%" : "60%",
                margin: device !== "pc" ? "8% auto 0 auto" : "2% auto 0 auto",
                height: "30px",
                fontSize: "14px",
              }}
              position={"c"}
              onClick={saveEmbUser}
            />
          ) : null} */}
        </div>
        {emb && (
          <>
            <div className="secure_emb_sec_title">
              <p className="secure_emb_sec_title_nation">{searchCountry}</p>
              <p className="secure_emb_sec_title_explain">
                {steps[secureembStep - 1]} 지역 입니다.
              </p>
            </div>
            <div
              className="secure_emb_sec"
              dangerouslySetInnerHTML={{ __html: emb.contact_remark }}
            />
          </>
        )}
      </div>
      <div className="infoone_FL_sec">
        <MainTitleComponent
          title="안전 매거진"
          divClass="infoone_FL_title_sec"
          pClass="infoone_FL_title"
        />
        <div className="securemap_magazine_card_all">
          {magazineData &&
            magazineData.map((item, key) => {
              return (
                <React.Fragment key={key}>
                  <div className="securemap_magazine_card">
                    <img
                      className="securemap_magazine_img"
                      onClick={() =>
                        handleClick(`/Links/${item.seq}`, navigate)
                      }
                      src={convertS3toCloudFront(item.image_path)}
                    />
                    {/* <p
                      className="securemap_magazine_card_title"
                      onClick={() =>
                        handleClick(`/Links/${item.seq}`, navigate)
                      }
                    >
                      {item.title}
                    </p> */}
                  </div>
                </React.Fragment>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default SecureTravelSecure;
