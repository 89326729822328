import React from "react";
import "../../assets/scss/DomSamSungModalCautions.scss";
import DomSamSungWarranty from "./DomSamSungWarranty";
import { useDeviceScreen } from "../../assets/js/util/useDeviceScreen";
import DomSamSungNotWarranty from "./DomSamsungNotWarranty";

function SamSungModalCautions({}) {
  const device = useDeviceScreen();
  return (
    <>
      <div className="dom_modal_cautions_sec">
        <div>
          <label className="dom_modal_check3_text">서비스 안내</label>
          <div className="dom_modal_cautions">
            <div>
              <p>
                트래블링크 회원가입 고객분들께 제공드리는 국내 여행보험 무료
                가입 혜택입니다.
              </p>
            </div>
          </div>
        </div>
        <div>
          <label className="dom_modal_check3_text">가입대상</label>
          <div className="dom_modal_cautions">
            <div>
              <p>1. 트래블링크 신규 회원가입자</p>
              <p>
                2. 개인정보 제3자 제공(유상)동의를 한 고객에 한하여 혜택 제공
              </p>
            </div>
          </div>
        </div>
        <div>
          <label className="dom_modal_check3_text">가입기간</label>
          <div className="dom_modal_cautions">
            <div>
              <p>2024년 05월 24일 ~ 2025년 05월 23일</p>
            </div>
          </div>
        </div>
        <div>
          <label className="dom_modal_check3_text">보장기간</label>
          <div className="dom_modal_cautions">
            <div>
              <p>여행보험 가입 신청시 기입한 일자에 한함</p>
            </div>
          </div>
        </div>
        <div>
          <label className="dom_modal_check3_text">
            보험가입 플랜 (보장내역)
          </label>
          <div className="dom_modal_cautions">
            {device === "tablet" ? (
              <table className="dom_modal_table">
                <tbody>
                  <tr className="dom_modal_tr">
                    <th className="dom_modal_th">구분</th>
                    <th className="dom_modal_th">보장 금액</th>
                  </tr>
                  <tr className="dom_modal_tr">
                    <td className="dom_modal_td">상해사망</td>
                    <td className="dom_modal_td">3,000,000원</td>
                  </tr>
                  <tr className="dom_modal_tr">
                    <td className="dom_modal_td">상해후유장해</td>
                    <td className="dom_modal_td">3,000,000원</td>
                  </tr>
                  <tr className="dom_modal_tr">
                    <td className="dom_modal_td">질병사망 특약</td>
                    <td className="dom_modal_td">3,000,000원</td>
                  </tr>
                  <tr className="dom_modal_tr">
                    <td className="dom_modal_td">
                      교통상해입원일당 특약(1일 이상)
                    </td>
                    <td className="dom_modal_td">10,000원</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <table className="dom_modal_cautions_table">
                <thead className="dom_modal_cautions_thead">
                  <tr>
                    <th>구분</th>
                    <th>상해사망</th>
                    <th>상해후유장해</th>
                    <th>질병사망 특약</th>
                    <th>교통상해입원일당 특약(1일 이상)</th>
                  </tr>
                </thead>
                <tbody className="dom_modal_cautions_tbody">
                  <tr>
                    <td>보장 금액</td>
                    <td>3,000,000원</td>
                    <td>3,000,000원</td>
                    <td>3,000,000원</td>
                    <td>10,000원</td>
                  </tr>
                </tbody>
              </table>
            )}
            <div className="dom_modal_cautions">
              <DomSamSungWarranty />
              <DomSamSungNotWarranty />
              <p>
                * 만 15세 미만 및 80세 이상 고객의 경우 상해사망, 질병사망
                담보는 제외됩니다.
              </p>
            </div>
          </div>
        </div>
        <div>
          <label className="dom_modal_check3_text">주의사항</label>
          <div className="dom_modal_cautions">
            <p>1. 현재 국내여행 중인 경우는 가입이 불가능합니다.</p>
          </div>
        </div>
        <div>
          <label className="dom_modal_check3_text">고객센터</label>
          <div className="dom_modal_cautions">
            <p>
              자세한 사항은 반드시 보장내용 및 약관을 읽어보시기 바라며,
              관련문의는 고객센터로 전화주세요.
            </p>
            <p>고객센터 : 1800-9010</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default SamSungModalCautions;
