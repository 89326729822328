import React, { useEffect, useState } from "react";
import "../../assets/scss/Links_detail.scss";
import { useParams } from "react-router-dom";
import Event_Responsive from "../Slider/Event_Slider";
import axios from "axios";
// import { toChange2 } from "../../assets/js/util/util";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import Loader from "../loader/LoaderComponent";
import HelmetComponent from "../header/HelmetComponent";

function NewlineText({ text }) {
  return text.split("\n").map((str, idx) => <p key={idx}>{str}</p>);
}

const LinksDetail = () => {
  const { eventId } = useParams();
  const [detailData, setDetailData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`/api/Links/LinksDetail/${eventId}`)
      .then((res) => {
        if (res.data.success) {
          setDetailData([res.data.data]);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <HelmetComponent title="Links 게시글" />
      <div>
        {detailData[0] && (
          <div>
            <div className="links_detail_content">
              <div className="links_detail_imgsec">
                <img
                  className="links_detail_img"
                  src={convertS3toCloudFront(detailData[0].image_path)}
                ></img>
              </div>
              <div>
                {/* <div className="links_detail_date">
                  {toChange2(detailData[0]?.created_at)}
                </div> */}
                <div className="links_detail_title">{detailData[0].title}</div>
                <div className="links_detail_subtitle">
                  {detailData[0].subtitle}
                </div>
              </div>
              <div className="links_detail_boundary"></div>
              <div className="links_detail_text">
                <NewlineText text={detailData[0].body} />
              </div>
              {/* <div className="links_detail_ref">
                {detailData[0].source_info}
              </div> */}
              {/* () => handleClick(detailData[0].source_url,navigate) */}
              {/* <div
                onClick={() => {
                  window.open(detailData[0].source_url);
                }}
                className="links_detail_btnsec"
              >
                <button className="links_detail_btn">
                  전체내용 보러가기 &gt;
                </button>
              </div> */}
            </div>
            <div className="links_detail_background"></div>
          </div>
        )}
        <div style={{ marginBottom: "10%" }}>
          <Event_Responsive />
        </div>
      </div>
    </>
  );
};

export default LinksDetail;
