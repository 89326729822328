import React from "react";
import CategoryCustomButton from "./CategoryCustomButton";

const CategoryCustomButtonGroup = ({
  configurations,
  activeContent,
  handleClick,
}) => {
  return (
    <>
      <div className={configurations.containerClass}>
        {configurations.buttons.map((buttonConfig, index) => (
          <CategoryCustomButton
            key={index}
            title={buttonConfig.title}
            handleClick={handleClick}
            activeContent={activeContent}
            compareContent={buttonConfig.compareContent}
            baseClassName={buttonConfig.baseClassName}
          />
        ))}
      </div>
      {configurations.components[activeContent]}
    </>
  );
};

export default React.memo(CategoryCustomButtonGroup);
