import React, { useEffect, useState } from "react";
import "../../assets/scss/TermsPri.scss";
import TermsPritext_v1 from "./TermsPritext_v1";
import TermsPritext_v2 from "./TermsPritext_v2_230721";
import TermsPritext_v3 from "./TermsPritext_v3_230821";
import TermsPritext_v4 from "./TermsPritext_v4_231117";
import TermsPritext_v5 from "./TermsPritext_v5_240403";

const Footer = () => {
  const [selectedVersion, setSelectedVersion] = useState(null);

  // 이 부분에 새로운 버전이 추가될 때마다 업데이트하면 됩니다. 개인정보개정
  const termsVersions = [
    { version: "2023년 4월 26일 개정", component: <TermsPritext_v1 /> },
    { version: "2023년 7월 21일 개정", component: <TermsPritext_v2 /> },
    { version: "2023년 8월 21일 개정", component: <TermsPritext_v3 /> },
    { version: "2023년 11월 17일 개정", component: <TermsPritext_v4 /> },
    { version: "2024년 04월 03일 개정", component: <TermsPritext_v5 /> },
    // 예: { version: 'v3', component: <TermsPritext_v3 /> },
  ];

  useEffect(() => {
    setSelectedVersion(termsVersions[termsVersions.length - 1].component); // 기본적으로 마지막 버전을 선택합니다.
  }, []);

  const handleChange = e => {
    const selected = termsVersions.find(tv => tv.version === e.target.value);
    setSelectedVersion(selected.component);
  };

  return (
    <>
      <div>
        <div className="termspri_select_sec">
          <select className="termspri_select" onChange={handleChange}>
            {[...termsVersions].reverse().map((tv, idx) => (
              <option
                className="termspri_select_option"
                key={idx}
                value={tv.version}
              >
                {tv.version}
              </option>
            ))}
          </select>
        </div>
        {selectedVersion}
      </div>
    </>
  );
};

export default Footer;
