import React, { useState } from "react";
import { _each, _returneach } from "../../assets/js/util/util";
import "react-toastify/dist/ReactToastify.css";
import MemoryUpload from "./NewmypageUpload";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import axios from "axios";
import "../../assets/scss/NMPmaterialsModal.scss";

function NMPmatarialsModal({ onClose, prepareModalIndex }) {
  const [fileData, setFile] = useState([]);
  const [resetPreview, setResetPreview] = useState(null);
  const [description, setDescripition] = useState("");

  const onSubmitData = async () => {
    if (!fileData[0]) {
      alert("이미지를 업로드해주세요");
      return;
    }
    if (description.length === 0) {
      alert("설명을 입력해주세요");
      return;
    }
    const formData = new FormData();
    formData.append("memo", description);
    formData.append("imgData", fileData[0]);
    formData.append("seq", prepareModalIndex);

    try {
      axios
        .post("/api/prepare/registerImageAndMemo", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res);
        });
      alert("등록되었습니다.");
      window.location.reload();
    } catch (error) {
      alert(error);
      window.location.reload();
    }
  };
  const descriptionHandler = (e) => {
    setDescripition(e.target.value);
  };
  return (
    <>
      <div className="nmpmaterials_background">
        <div className="nmpmaterials_content">
          <div>
            <MemoryUpload
              alreadyUpload={false}
              buttonText={
                <div className="register_upload_sec">
                  <div className="register_plus_img_sec">
                    <img
                      className="register_plus_img"
                      src={convertS3toCloudFront(
                        "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/plus_image.png"
                      )}
                    />
                  </div>
                  <div className="register_upload_text">이미지 업로드</div>
                </div>
              }
              setFile={setFile}
              resetPreview={setResetPreview}
            />
            <textarea
              className="nmpmaterials_content_textarea"
              value={description}
              onChange={descriptionHandler}
              placeholder="설명입력"
            />
          </div>
          <div className="nmpmaterials_btnsec">
            <button onClick={onSubmitData}>등록하기</button>
            <button onClick={onClose}>닫기</button>
          </div>
        </div>
      </div>
    </>
  );
}
export default React.memo(NMPmatarialsModal);
