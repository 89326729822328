import React, { useState, useEffect } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import overNationSorting from "./over_nation_sorting.json";
import overNationSorting2 from "./over_nation_sorting2.json";
import geoUrl from "./map.json";
import Select from "react-select";
import MemoryRegisterModal from "./MemoryRegisterModal";
import overCoordinateLocation from "./over_coordinate_location.json";
import axios from "axios";
import MemoryRegisterModal2 from "./MemoryRegisterModal2";
import { toChange2 } from "../../assets/js/util/util";
import "../../assets/scss/Over_map.scss";
import { useDeviceScreen } from "../../assets/js/util/useDeviceScreen";

function TravelMap({ myMemoryList, setRemyMemoryData, reMyMeoryData }) {
  const [selectedColor, setSelectedColor] = useState("#fad200");
  const [countryColorMap, setCountryColorMap] = useState({});

  const [selectValue, setSelectValue] = useState(null);
  const device = useDeviceScreen();
  // Select click한 데이터명 영여명
  const [clickData, setClickData] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [modalXY, setModalXY] = useState([]);
  const [showNmpModal, setShowNmpModal] = useState(false);

  //이미 있는데이터 선택한 경우
  const [showNmpModal2, setShowNmpModal2] = useState([false, []]);

  const [markerLocation, setMarketLocation] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);

  // plan나라 설정한 데이터
  const [myPlanCountry, setMyPlanCountry] = useState([]);

  const [selectMyCountryList, setSelectMyCountryList] = useState([]);

  //내 여행기 list에 해당하는 색칠렌더링
  useEffect(() => {
    const newCountryColorMap = {};

    myMemoryList.map((memory) => {
      newCountryColorMap[overNationSorting2[memory.TRAVEL_COUNTRY]] =
        memory.COLOR;
    });

    setCountryColorMap(newCountryColorMap);
  }, [myMemoryList]);

  useEffect(() => {
    //내가 설정한 데이터 불러오기
    try {
      axios.get("/api/plan/getMyCountryList").then((res) => {
        setMyPlanCountry(res.data.data);
      });
    } catch (err) {
      alert(err);
    }
  }, []);
  useEffect(() => {
    const data = Object.keys(overNationSorting).map((country) => ({
      value: country,
      label: overNationSorting[country],
    }));

    setCountryOptions(data);
  }, []);

  const handleSelectColor = (colorValue) => {
    setSelectedColor(colorValue);
  };

  const handleChange = (obj) => {
    setSelectMyCountryList([]);
    setShowModal(false);
    setSelectValue(obj);
    setClickData(obj.value);
    setModalXY([]);

    const coordinate = findCoordinates(obj.value);
    setMarketLocation(coordinate);
  };

  const findCoordinates = (countryName) => {
    return overCoordinateLocation[countryName] || [];
  };

  const showModalHandler = (e) => {
    let x = e.pageX;
    let y = e.pageY;

    const modalWidth = 200;
    const modalHeight = 20;

    if (x + modalWidth > window.innerWidth) {
      x = window.innerWidth - modalWidth;
    }

    if (y + modalHeight > window.innerHeight) {
      y = window.innerHeight - modalHeight;
    }
    setShowModal(true);
    setModalXY([x, y]);

    const countryName = overNationSorting[clickData];
    for (let i = 0; i < myPlanCountry.length; i++) {
      if (myPlanCountry[i].TRAVEL_COUNTRY === countryName) {
        if (
          !selectMyCountryList.some(
            (item) => item.TRAVEL_COUNTRY === countryName
          )
        ) {
          setSelectMyCountryList((prevList) => [...prevList, myPlanCountry[i]]);
        }
      }
    }
  };

  const showNmpModalHandler = (e) => {
    setShowNmpModal(true);
  };

  const NmpCloseHandler = () => {
    setShowNmpModal(false);
  };
  const showNmpModalHandler2 = (myCountry) => {
    setShowNmpModal2([true, myCountry]);
  };

  const NmpCloseHandler2 = () => {
    setShowNmpModal2([false, []]);
  };
  return (
    <div>
      {showNmpModal && (
        <MemoryRegisterModal
          onClose={NmpCloseHandler}
          onSelectColor={handleSelectColor}
          country={selectValue.label}
          setRemyMemoryData={setRemyMemoryData}
          reMyMeoryData={reMyMeoryData}
          setShowModal={setShowModal}
        />
      )}
      {showNmpModal2[0] && (
        <MemoryRegisterModal2
          onClose={NmpCloseHandler2}
          onSelectColor={handleSelectColor}
          data={showNmpModal2[1]}
          setRemyMemoryData={setRemyMemoryData}
          reMyMeoryData={reMyMeoryData}
          setShowNmpModal2={setShowNmpModal2}
          setShowModal={setShowModal}
        />
      )}
      {showModal && (
        <div
          style={{
            top: `${modalXY[1]}px`,
            left: `${modalXY[0]}px`,
            zIndex: `1`,
          }}
          className={
            selectMyCountryList && selectMyCountryList.length > 0
              ? "memory_marker_modal"
              : "memory_marker_modal_nodata"
          }
        >
          {selectMyCountryList && selectMyCountryList.length > 0
            ? selectMyCountryList.map((myCountry, index) => {
                return (
                  <React.Fragment key={index}>
                    <div
                      onClick={() => showNmpModalHandler2(myCountry, index)}
                      className="memory_marker_schedule_exist"
                    >
                      <div>
                        <p>{myCountry.TRAVEL_COUNTRY}</p>
                        <p> {toChange2(myCountry.CREATED_AT)}</p>
                      </div>
                      <div className="memory_marker_schedule_title">
                        <p>{myCountry.TITLE}</p>
                      </div>
                    </div>
                    <hr />
                  </React.Fragment>
                );
              })
            : null}
          <div className="memory_marker_plus" onClick={showNmpModalHandler}>
            직접 입력하기
          </div>
        </div>
      )}

      <ComposableMap
        width={device === "mobile" ? 1000 : 1400}
        height={device === "mobile" ? 600 : 500}
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) => {
            return geographies.map((geo) => {
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onClick={() => {
                    const coordinate = findCoordinates(geo.properties.name);
                    setSelectMyCountryList([]);
                    setShowModal(false);
                    setModalXY([]);
                    setMarketLocation(coordinate);
                    setClickData(geo.properties.name);
                    setSelectValue({
                      value: geo.properties.name,
                      label: overNationSorting[geo.properties.name],
                    });
                  }}
                  style={{
                    default: {
                      fill: countryColorMap[geo.properties.name] || "#333",
                    },
                    hover: { fill: selectedColor, cursor: "pointer" },
                    pressed: {
                      fill: selectedColor,
                      outline: "none !important",
                    },
                  }}
                />
              );
            });
          }}
        </Geographies>
        {markerLocation.length === 2 ? (
          <Marker key={clickData} coordinates={markerLocation}>
            <circle
              r={device === "mobile" ? 25 : 10}
              fill="#F00"
              stroke="#fff"
              strokeWidth={2}
              onClick={showModalHandler}
              style={{
                cursor: "pointer",
              }}
            />
          </Marker>
        ) : null}
      </ComposableMap>
      <div className="memory_select">
        <Select
          value={selectValue}
          onChange={handleChange}
          options={countryOptions}
          className="memory_select_input"
          placeholder="여행지(국가) 검색"
        />
      </div>
    </div>
  );
}

export default React.memo(TravelMap);
