import React, { useEffect, useState } from "react";
import axios from "axios";
import { urlMove } from "../../assets/js/util/navigation";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import "../../assets/scss/CouponSlider.scss";

const CouponSlider = () => {
  const [datas, setData] = useState([]);

  useEffect(() => {
    axios
      .get("/api/main/readcoupon")
      .then((res) => {
        // Assuming the data you need is inside res.data
        setData(res.data.data); // Set the fetched data to your state
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return (
    // Use a fragment <> ... </> to wrap your map function
    <>
      {datas &&
        datas.map((data, index) => (
          <div
            onClick={() => urlMove(data.source_url)}
            key={index}
            className="travelcoupon_backimg_sec"
          >
            <img
              className="travelcoupon_backimg"
              src={convertS3toCloudFront(data.image_path)}
              alt={data.title}
            />
          </div>
        ))}
    </>
  );
};

export default React.memo(CouponSlider);
