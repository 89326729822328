import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../../assets/scss/BackButton.scss";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";

function BackButton() {
  let navigate = useNavigate();
  let location = useLocation();
  const [showButton, setShowButton] = useState(false);

  // 이전 위치를 기억하는 state
  const [lastLocation, setLastLocation] = useState();

  useEffect(() => {
    // 이전 위치가 있고 현재 위치가 메인 페이지가 아닌 경우 버튼을 표시
    setShowButton(lastLocation !== undefined && location.pathname !== "/");
    // 위치가 변경될 때마다 이전 위치를 업데이트
    setLastLocation(location.pathname);
  }, [location]);

  const goBack = () => {
    navigate(-1);
  };

  if (!showButton) {
    return null;
  }

  return (
    <button className="backbutton" onClick={goBack}>
      <img
        className="backbutton_img"
        src={convertS3toCloudFront(
          "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/backbtn.png"
        )}
        alt="back"
      />
    </button>
  );
}

export default BackButton;
