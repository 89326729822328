import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../assets/scss/InfoOneContentDetail.scss";
import ExchangeComponent from "../main/ExchangeComponent";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import MainTitleComponent from "../main/MainTitleComponent";
import { urlMove } from "../../assets/js/util/navigation";

// activeRegion,
const InfoOneContentDetail = ({ activeRegion, activeContent }) => {
  const [priceData, setPriceData] = useState([]);
  const [linkData, setLinkData] = useState([]);

  let displayTitle = activeContent;

  switch (activeContent) {
    case "exchange":
      displayTitle = "환전";
      break;
    case "flight":
      displayTitle = "항공";
      break;
    case "rodge":
      displayTitle = "숙박";
      break;
    case "package":
      displayTitle = "패키지";
      break;
    default:
      break;
  }

  useEffect(() => {
    Promise.all([
      axios.get("/api/infoOne/getPriceSlider", {
        params: { activeRegion, activeContent },
      }),
      axios.get("/api/infoOne/getLinkSlider", {
        params: { activeRegion, activeContent },
      }),
    ]).then(([price, link]) => {
      setPriceData(price.data.data);
      setLinkData(link.data.data);
    });
  }, [activeRegion, activeContent]);

  const handleOnClick = (item, urlMove) => {
    const eventName = `TvInfo${item.CATEGORY2}${item.CATEGORY3}${item.TITLE}`;

    if (window.fbq) {
      window.fbq("trackCustom", eventName, {});
    }

    if (window.gtag) {
      window.gtag("event", eventName);
    }

    urlMove(item.SOURCE_URL);
  };

  return (
    <>
      {activeContent === "exchange" ? (
        <div className="infoone_ex_sec_all">
          <div className="infoone_FL_sec">
            <MainTitleComponent
              title="실시간 환율 계산기"
              divClass="infoone_FL_title_sec"
              pClass="infoone_FL_title"
            />
            <div className="infoone_ex_cal_sec">
              <ExchangeComponent />
              <p className="infoone_ex_cal_explain">
                * 실시간 환율 계산기는 환율에 대한 참고사항이며, 환전 금액과
                다를 수 있습니다.
              </p>
            </div>
          </div>
          <div className="infoone_FL_sec">
            <MainTitleComponent
              title="환전 Link"
              divClass="infoone_FL_title_sec"
              pClass="infoone_FL_title"
            />
            <div className="infoone_FL_Link_sec_all">
              <div className="infoone_FL_exchange_sec">
                {linkData &&
                  linkData.map((item, key) => {
                    return (
                      <React.Fragment key={key}>
                        <div
                          className="infoone_FL_exchange"
                          key={key}
                          onClick={() => handleOnClick(item, urlMove)}
                        >
                          <img
                            className="infoone_FL_exchange_img"
                            src={convertS3toCloudFront(item.IMAGE_PATH)}
                          />
                          {/* <div className="infoone_FL_Link_text">
                            <p>{item.TITLE}</p>
                            <p>{item.DESCRIPTION}</p>
                          </div> */}
                        </div>
                      </React.Fragment>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="infoone_FL_sec">
            <MainTitleComponent
              title={`${displayTitle} 특가`}
              divClass="infoone_FL_title_sec"
              pClass="infoone_FL_title"
            />
            {priceData &&
              priceData.map((item, key) => {
                return (
                  <div
                    className="infoone_FL_sec_img"
                    key={key}
                    onClick={() => handleOnClick(item, urlMove)}
                  >
                    <img src={convertS3toCloudFront(item.IMAGE_PATH)} />
                  </div>
                );
              })}
          </div>
          <div className="infoone_FL_sec">
            <MainTitleComponent
              title={`${displayTitle} Link`}
              divClass="infoone_FL_title_sec"
              pClass="infoone_FL_title"
            />
            <div className="infoone_FL_Link_sec_all">
              <div className="infoone_FL_Link_sec">
                {linkData &&
                  linkData.map((item, key) => {
                    return (
                      <React.Fragment key={key}>
                        <div className="infoone_FL_Link_sec_temp">
                          <div
                            className="infoone_FL_Link_left"
                            key={key}
                            onClick={() => handleOnClick(item, urlMove)}
                          >
                            <img
                              className="infoone_FL_Link_img"
                              src={convertS3toCloudFront(item.IMAGE_PATH)}
                            />
                            <div className="infoone_FL_Link_text">
                              <p>{item.TITLE}</p>
                              <p>{item.DESCRIPTION}</p>
                            </div>
                          </div>
                          <div
                            onClick={() => urlMove(item.SOURCE_URL)}
                            className="infoone_FL_Link_Link"
                          >
                            <img
                              src={convertS3toCloudFront(
                                "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/RENEWAL/230812_link.png"
                              )}
                            />
                            <p>Link</p>
                          </div>
                        </div>
                        <hr />
                      </React.Fragment>
                    );
                  })}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default React.memo(InfoOneContentDetail);
