

export const userIdRegex = /^[a-z0-9]{3,16}$/;
export const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+=-~])(?=.*[0-9]).{6,25}$/i;
export const nameRegex = /^[가-힣]{2,4}$/;
export const emailRegex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i
export const phoneRegex = /^01[016789]-\d{3,4}-\d{4}$/;
export const birthRegex = /\d{4}-\d{2}-\d{2}/;

// 공백체크
export const emptySpaceRegex = /\s/g;

export const nameConsultingRegex = /^[가-힣]{2,10}$/;
export const phoneConsultingRegex = /^010\d{8}$/;