import React, { useEffect, useState } from "react";
import "../../assets/css/shop.css";
import axios from "axios";
import { urlMove } from "../../assets/js/util/navigation";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import withAuth from "../../hoc/auth";
import HelmetComponent from "../header/HelmetComponent";
import { useDeviceScreen } from "../../assets/js/util/useDeviceScreen";

const Shop = () => {
  const [shopData, setShopData] = useState([]);
  const device = useDeviceScreen();
  useEffect(() => {
    axios.get("/api/shops/read").then((res) => {
      setShopData([res.data]);
    });
  }, []);

  function makeComma(price) {
    return price.toLocaleString("ko-KR") + "원";
  }

  return (
    <>
      <HelmetComponent title="TravelShop" />
      <div className="travelshop_backimg_sec">
        <img
          className="travelshop_backimg"
          src={convertS3toCloudFront(
            "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/travelshop_backimg.jpg"
          )}
        />
        <div className="shop_title_sec">
          <h1>Travel Shop</h1>
          <h2>여행 라이프에 도움되는 제품을 특가로 제공합니다.</h2>
        </div>
      </div>
      <div className="shop_all"></div>
      <div className="shop_sec">
        {shopData[0] &&
          shopData[0].map((item, key) => {
            let commaPrice = makeComma(item.price);
            let shortenCouponName;
            let shortenCouponExpl;
            let description = !item.description ? "" : item.description;

            if (device === "mobile") {
              shortenCouponName =
                item.title.length > 12
                  ? item.title.slice(0, 12) + "..."
                  : item.title;
              shortenCouponExpl =
                description.length > 13
                  ? description.slice(0, 13) + "..."
                  : description;
            } else {
              shortenCouponName =
                item.title.length > 30
                  ? item.title.slice(0, 30) + "..."
                  : item.title;
              shortenCouponExpl =
                description.length > 20
                  ? description.slice(0, 20) + "..."
                  : description;
            }
            return (
              <div key={item.seq} onClick={() => urlMove(item.source_url)}>
                <div className="shop_item">
                  <div className="shop_item_img">
                    <img src={convertS3toCloudFront(item.image_path)}></img>
                  </div>
                  <div className="shop_above_category">{item.company}</div>
                  <div className="shop_text_sec">
                    <p className="shop_title">{shortenCouponName}</p>
                    <p className="shop_subtitle">{shortenCouponExpl}</p>
                    <p className="shop_context">{commaPrice}</p>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default withAuth(Shop, null);
