import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserService } from "../../service/UserService";
import React, { useEffect, useState, useRef } from "react";
import BackButton from "../customButton/BackButton";
import axios from "axios";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import "../../assets/scss/Header.scss";
import { handleClick, urlMove } from "../../assets/js/util/navigation";
import InsuranceCert from "../myPage/InsuranceCert";
import { navSettingIcon, travellogo } from "../../assets/js/util/apiConstant";
import { useDeviceScreen } from "../../assets/js/util/useDeviceScreen";

const MENU_LOGO =
  "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/menu_btn_3.png";
const MENU_CLOSE_LOGO =
  "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/menu_close.svg";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const users = useSelector(state => state.users);
  const user = useSelector(state => state.users.user);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [menuLogo, setMenuLogo] = useState(convertS3toCloudFront(MENU_LOGO));
  const [menucloselogo, setMenuCloseLogo] = useState(
    convertS3toCloudFront(MENU_CLOSE_LOGO)
  );
  const [eventData, setEventData] = useState([]);
  const toggleMenu = () => setIsActive(!isActive);
  const navNode = useRef();
  const toggleButtonNode = useRef();
  const fetchData = async () => {
    const res = await axios.get("/api/events/read");
    const reversedData = [...res.data].reverse();
    setEventData([reversedData]);
  };

  const [profileData, setProfileData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isInsuranceModalVisible, setIsInsuranceModalVisible] = useState(false);

  const [eventParticipation, setEventParticipation] = useState(false);
  const [eventCreatedAt, setEventCreatedAt] = useState(null);
  const [eventInsuranceCompany, setEventInsuranceCompany] = useState(null);
  const [eventStartDate, setEventStartDate] = useState(null);
  const [eventEndDate, setEventEndDate] = useState(null);
  const [eventCountry, setEventCountry] = useState(null);
  const device = useDeviceScreen();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };

  const showInsuranceModal = () => {
    setIsInsuranceModalVisible(true);
  };

  const hideInsuranceModal = () => {
    setIsInsuranceModalVisible(false);
  };
  useEffect(() => {
    if (user && user.isAuth && !user.error) {
      try {
        axios.get("/api/profile/getProfile").then(res => {
          setProfileData(res.data.data);
        });
      } catch (err) {
        console.error("프로필 이미지를 불러오는 데 실패했습니다.", err);
        alert("프로필 이미지를 불러오는 데 실패했습니다");
        window.location.reload();
      }
    }
  }, [user, isActive]);

  useEffect(() => {
    fetchData();
  }, []);

  const modalRef = useRef(null);
  const insuranceModalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = e => {
      if (
        isActive &&
        !navNode.current.contains(e.target) &&
        !toggleButtonNode.current.contains(e.target) &&
        !(modalRef.current && modalRef.current.contains(e.target)) && // isModalVisible 모달 예외 처리
        !(
          insuranceModalRef.current &&
          insuranceModalRef.current.contains(e.target)
        ) // isInsuranceModalVisible 모달 예외 처리
      ) {
        setIsActive(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isActive]);

  useEffect(() => {
    if (navNode.current) {
      if (navNode.current.classList.contains("active")) {
        document.body.style.overflowX = "hidden";
      } else {
        document.body.style.overflowX = "hidden";
      }
    }
  }, [navNode.current?.className]);

  const onClickHandler = (type, device) => {
    const convertTypeToClassName = type => {
      return type.replace(/\//g, ""); // "/" 문자를 제거
    };
    if (device === "mobile") {
      toggleMenu();
    }
    if (type === "toplogo") {
      navigate("/");
      const links = document.querySelectorAll(".menu span");
      links.forEach(link => {
        link.classList.remove("clicked");
      });
    } else {
      const links = document.querySelectorAll(".nav_pc_menu span");
      links.forEach(link => {
        if (link.classList.contains(convertTypeToClassName(type))) {
          link.classList.add("clicked");
        } else {
          link.classList.remove("clicked");
        }
      });
      handleClick(type, navigate);
      if (type === "/") {
        setTimeout(() => {
          const link = document.querySelector(".menu span");
          if (link === "/") {
            link.classList.remove("clicked");
          }
        }, 0);
      }
    }
  };

  useEffect(() => {
    const currentURL = window.location.href;
    const types = ["InfoOne", "SecureTravel", "Coupon", "Event", "Question"];

    let found = false;
    for (let type of types) {
      if (currentURL.includes(type)) {
        if (document.querySelector(`.nav_pc_menu span.${type}.clicked`)) {
          found = true;
        }
        break;
      }
    }
    if (!found) {
      const links = document.querySelectorAll(".nav_pc_menu span.clicked");
      links.forEach(link => {
        link.classList.remove("clicked");
      });
    }
  }, [window.location.href]);

  const logoutHandler = () => {
    let body = { id: user.id };
    if (window.confirm("로그아웃 하시겠습니까?")) {
      dispatch(UserService.logout(body)).then(res => {
        if (res.payload.success) {
          setIsLoggedIn(false);
          setIsActive(false);
          navigate("/Login");
        } else {
          alert(res.payload.message);
        }
      });
    }
  };

  const needLoginClickHandler = url => {
    if (user && user.isAuth) {
      urlMove(url);
    } else {
      navigate("/Login");
      setIsActive(false);
    }
  };

  const LogoComponent = () => (
    <div className="toplogo">
      <img
        className="mainlogo"
        src={convertS3toCloudFront(
          "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/RENEWAL/230822_mainlogo.png"
        )}
        alt="트래블링크 로고"
        onClick={() => onClickHandler("toplogo")}
      />
    </div>
  );

  const TitleComponent = ({ title }) => (
    <div className="nav_page_title">
      <p>{title}</p>
    </div>
  );

  useEffect(() => {
    let body = {
      name: user.name,
      phone: user.phone,
    };
    if (user.id && body.name && body.phone) {
      axios.post("/api/insurance/eventPart", body).then(res => {
        const eventData = res.data.data;
        setEventParticipation(eventData.exists);
        if (eventData.exists) {
          const createDate = new Date(`${eventData.created_at}`);
          const formattedCreateDate = new Intl.DateTimeFormat("ko-KR", {
            year: "numeric",
            month: "long",
            day: "numeric",
            timeZone: "UTC",
          }).format(createDate);
          const startDate = new Date(`${eventData.travel_start_date}`);
          const formattedStartDate = new Intl.DateTimeFormat("ko-KR", {
            year: "numeric",
            month: "long",
            day: "numeric",
            timeZone: "UTC",
          }).format(startDate);

          const endDate = new Date(`${eventData.travel_end_date}`);
          const formattedEndDate = new Intl.DateTimeFormat("ko-KR", {
            year: "numeric",
            month: "long",
            day: "numeric",
            timeZone: "UTC",
          }).format(endDate);

          setEventCreatedAt(formattedCreateDate);
          setEventInsuranceCompany(eventData.insurance_company);
          setEventStartDate(formattedStartDate);
          setEventEndDate(formattedEndDate);
          setEventCountry(eventData.travel_country);
        }
      });
    }
  }, [user]);

  const getPathComponent = path => {
    const pathComponents =
      device === "mobile"
        ? {
            "/SecureTravel": <TitleComponent title="안전여행" />,
            "/secureTravel": <TitleComponent title="안전여행" />,
            "/InfoOne": <TitleComponent title="항공/숙박" />,
            "/Coupon": <TitleComponent title="쿠폰" />,
            "/Event": <TitleComponent title="이벤트" />,
            "/event": <TitleComponent title="이벤트" />,
            "/MemoryComponent": <TitleComponent title="내 여행기" />,
            "/Newmypage": <TitleComponent title="My Link" />,
            "/NewMypage": <TitleComponent title="My Link" />,
            "/Mypage": <TitleComponent title="내 정보 수정하기" />,
            "/Login": <TitleComponent title="로그인" />,
            "/Cert": <TitleComponent title="회원가입" />,
            "/TermsPri": <TitleComponent title="개인정보처리방침" />,
            "/Terms": <TitleComponent title="이용약관" />,
            "/Announ": <TitleComponent title="공지사항" />,
            "/SignForm": <TitleComponent title="회원가입" />,
            "/Links": <TitleComponent title="안전여행" />,
            "/": <LogoComponent />,
          }
        : {
            "/SecureTravel": (
              <>
                <LogoComponent />
                <TitleComponent title="안전여행" />
              </>
            ),
            "/InfoOne": (
              <>
                <LogoComponent />
                <TitleComponent title="항공/숙박" />
              </>
            ),
            "/SecureTravel": (
              <>
                <LogoComponent />
                <TitleComponent title="안전여행" />
              </>
            ),
            "/secureTravel": (
              <>
                <LogoComponent />
                <TitleComponent title="안전여행" />
              </>
            ),
            "/Coupon": (
              <>
                <LogoComponent />
                <TitleComponent title="쿠폰" />
              </>
            ),
            "/Event": (
              <>
                <LogoComponent />
                <TitleComponent title="이벤트" />
              </>
            ),
            "/event": (
              <>
                <LogoComponent />
                <TitleComponent title="이벤트" />
              </>
            ),
            "/MemoryComponent": (
              <>
                <LogoComponent />
                <TitleComponent title="내 여행기" />
              </>
            ),
            "/Newmypage": (
              <>
                <LogoComponent />
                <TitleComponent title="My Link" />
              </>
            ),
            "/NewMypage": (
              <>
                <LogoComponent />
                <TitleComponent title="My Link" />
              </>
            ),
            "/Mypage": (
              <>
                <LogoComponent />
                <TitleComponent title="내 정보 수정하기" />
              </>
            ),
            "/Login": (
              <>
                <LogoComponent />
                <TitleComponent title="로그인" />
              </>
            ),
            "/Cert": (
              <>
                <LogoComponent />
                <TitleComponent title="회원가입" />
              </>
            ),
            "/TermsPri": (
              <>
                <LogoComponent />
                <TitleComponent title="개인정보처리방침" />
              </>
            ),
            "/Terms": (
              <>
                <LogoComponent />
                <TitleComponent title="이용약관" />
              </>
            ),
            "/Announ": (
              <>
                <LogoComponent />
                <TitleComponent title="공지사항" />
              </>
            ),
            "/SignForm": (
              <>
                <LogoComponent />
                <TitleComponent title="회원가입" />
              </>
            ),
            "/Links": (
              <>
                <LogoComponent />
                <TitleComponent title="안전여행" />
              </>
            ),
            "/": (
              <>
                <LogoComponent />
              </>
            ),
          };

    for (const [key, component] of Object.entries(pathComponents)) {
      if (path.includes(key)) {
        return component;
      }
    }
    return null;
  };

  const handleNoParticipation = () => {
    const isMoveToEvent = window.confirm(
      "이벤트 참여 내역이 없습니다.\n여행자 보험 무료 가입 이벤트로 이동하시겠습니까?"
    );
    if (isMoveToEvent) {
      window.location.href = "https://travellink.co.kr/Event/7";
    }
  };

  useEffect(() => {
    if (isInsuranceModalVisible && !eventParticipation) {
      handleNoParticipation();
    }
  }, [isInsuranceModalVisible, eventParticipation]);

  return (
    <>
      {isInsuranceModalVisible && eventParticipation && (
        <InsuranceCert
          ref={insuranceModalRef}
          showModal={showInsuranceModal}
          closeModal={hideInsuranceModal}
          eventCreatedAtProp={eventCreatedAt}
          eventInsuranceCompanyProp={eventInsuranceCompany}
          eventStartDateProp={eventStartDate}
          eventEndDateProp={eventEndDate}
          eventCountryProp={eventCountry}
        />
      )}
      {isModalVisible && (
        <div
          ref={modalRef}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
          onClick={hideModal}
        >
          <img
            src={convertS3toCloudFront(
              profileData[0].PROFILE_IMAGE
                ? profileData[0].PROFILE_IMAGE
                : "http://via.placeholder.com/50x50"
            )}
            alt=""
            style={{
              maxWidth: "80%",
              maxHeight: "80%",
            }}
          />
        </div>
      )}
      <header id="header">
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <div className="Sec_header">
          {window.location.pathname !== "/Login" && <BackButton />}
          {getPathComponent(window.location.pathname)}
          <div>
            <button className="more_button" ref={toggleButtonNode}>
              {isActive ? (
                <img
                  src={convertS3toCloudFront(menucloselogo)}
                  onClick={toggleMenu}
                  className="more_button_icon active"
                />
              ) : (
                <img
                  src={convertS3toCloudFront(menuLogo)}
                  onClick={toggleMenu}
                  className="more_button_icon"
                />
              )}
            </button>
          </div>

          <nav
            ref={navNode}
            className={
              device === "mobile" && isActive
                ? "main_navigation active"
                : "main_navigation"
            }
          >
            <div>
              <div className="toparea">
                {users?.user?.isAuth ? (
                  <>
                    {profileData.length === 0 ? (
                      <div className="nav_top_sec">
                        <div className="nav_top_sec_left">
                          <div className="nav_user_profilesec">
                            <img
                              className="nav_user_profile"
                              src={convertS3toCloudFront(travellogo)}
                              alt=""
                            />
                          </div>
                          <p className="nav_username">{user.name} 님</p>
                          <img
                            className="nav_setting"
                            onClick={() =>
                              onClickHandler("Newmypage", "mobile")
                            }
                            src={convertS3toCloudFront(navSettingIcon)}
                          ></img>
                        </div>
                        <span
                          className="nav_logout"
                          onClick={e => {
                            e.stopPropagation();
                            logoutHandler();
                          }}
                        >
                          로그아웃
                        </span>
                      </div>
                    ) : (
                      <div className="nav_top_sec">
                        <div className="nav_top_sec_left">
                          <div className="nav_user_profilesec">
                            <img
                              className="nav_user_profile"
                              src={convertS3toCloudFront(
                                profileData[0].PROFILE_IMAGE
                              )}
                              alt=""
                              onClick={showModal}
                            />
                          </div>
                          <p className="nav_username">
                            {profileData[0].NICKNAME}
                          </p>
                          <img
                            className="nav_setting"
                            onClick={() =>
                              onClickHandler("Newmypage", "mobile")
                            }
                            src={convertS3toCloudFront(navSettingIcon)}
                          ></img>
                        </div>
                        <span
                          className="nav_logout"
                          onClick={e => {
                            e.stopPropagation();
                            logoutHandler();
                          }}
                        >
                          로그아웃
                        </span>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="nav_username_guest_sec">
                      <span>안녕하세요. 트래블링크 입니다.</span>
                    </div>
                    <div className="nav_username_guest">
                      <span
                        onClick={() =>
                          onClickHandler(
                            ...(device === "mobile"
                              ? ["Login", "mobile"]
                              : ["Login"])
                          )
                        }
                      >
                        로그인
                      </span>
                      <span
                        onClick={() =>
                          onClickHandler(
                            ...(device === "mobile"
                              ? ["Cert", "mobile"]
                              : ["Cert"])
                          )
                        }
                      >
                        회원가입
                      </span>
                    </div>
                  </>
                )}
                <div className="nav_top_menu_sec">
                  <div
                    onClick={() =>
                      onClickHandler(
                        ...(device === "mobile"
                          ? ["MemoryComponent", "mobile"]
                          : ["MemoryComponent"])
                      )
                    }
                  >
                    <img
                      src={convertS3toCloudFront(
                        "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/RENEWAL/230808_mytravel.png"
                      )}
                      alt=""
                    />
                    <span>내 여행기</span>
                  </div>
                  <div
                    onClick={() =>
                      onClickHandler(
                        ...(device === "mobile"
                          ? ["Newmypage", "mobile"]
                          : ["Newmypage"])
                      )
                    }
                  >
                    <img
                      src={convertS3toCloudFront(
                        "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/RENEWAL/230808_mylink.png"
                      )}
                      alt=""
                    />
                    <span>My Link</span>
                  </div>
                </div>
              </div>
              <div className="nav_menu">
                <div onClick={() => onClickHandler("Event", "mobile")}>
                  <span className="Event">이벤트</span>
                  <img
                    src={convertS3toCloudFront(
                      "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/down_arrow.png"
                    )}
                  ></img>
                </div>
                <div onClick={() => onClickHandler("NewMypage", "mobile")}>
                  <span className="NewMypage">내 쿠폰함</span>
                  <img
                    src={convertS3toCloudFront(
                      "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/down_arrow.png"
                    )}
                  ></img>
                </div>
                <div
                  onClick={() => {
                    showInsuranceModal();
                  }}
                >
                  <span className="Mypage">보험</span>
                  <img
                    src={convertS3toCloudFront(
                      "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/down_arrow.png"
                    )}
                  ></img>
                </div>

                {/* <div
                  className="nav_menu_last"
                  onClick={() => onClickHandler("Newmypage", "mobile")}
                >
                  <span className="Newmypage">문의하기</span>
                  <img
                    src={convertS3toCloudFront(
                      "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/down_arrow.png"
                    )}
                  ></img>
                </div> */}
              </div>
            </div>
            {eventData[0] &&
              eventData[0].slice(0, 1).map((item, key) => {
                return item.login === "y" ? (
                  <div
                    className="nav_eventbanner"
                    key={item.seq}
                    onClick={() => {
                      needLoginClickHandler(item.source_url);
                    }}
                  >
                    <img
                      className="nav_event_img"
                      src={convertS3toCloudFront(item.banner_path)}
                      alt={item.alt}
                    />
                  </div>
                ) : (
                  <div
                    className="nav_eventbanner"
                    key={item.seq}
                    onClick={() => {
                      window.location.href = item.source_url;
                    }}
                  >
                    <img
                      className="nav_event_img"
                      src={convertS3toCloudFront(item.banner_path)}
                      alt={item.alt}
                    />
                  </div>
                );
              })}
            <div className="nav_terms_sec">
              <span onClick={() => onClickHandler("Terms", "mobile")}>
                이용약관
              </span>
              <span onClick={() => onClickHandler("TermsPri", "mobile")}>
                개인정보처리방침
              </span>
              <span onClick={() => onClickHandler("Announ", "mobile")}>
                공지사항
              </span>
            </div>
          </nav>
          {/* PCver */}
          <div className="pc_toparea">
            {users?.user.isAuth ? (
              <>
                <div className="pc_toparea_sec">
                  <div>
                    <span
                      onClick={() =>
                        onClickHandler(
                          ...(device === "mobile"
                            ? ["MemoryComponent", "mobile"]
                            : ["MemoryComponent"])
                        )
                      }
                    >
                      내 여행기
                    </span>
                  </div>
                  <div>
                    <span
                      onClick={() =>
                        onClickHandler(
                          ...(device === "mobile"
                            ? ["Newmypage", "mobile"]
                            : ["Newmypage"])
                        )
                      }
                    >
                      My Link
                    </span>
                  </div>
                  <div>
                    <span
                      onClick={e => {
                        e.stopPropagation();
                        logoutHandler();
                      }}
                    >
                      로그아웃
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="pc_toparea_sec">
                  <div>
                    <span
                      onClick={() =>
                        onClickHandler(
                          ...(device === "mobile"
                            ? ["Login", "mobile"]
                            : ["Login"])
                        )
                      }
                    >
                      로그인
                    </span>
                  </div>
                  <div>
                    <span
                      onClick={() =>
                        onClickHandler(
                          ...(device === "mobile"
                            ? ["Cert", "mobile"]
                            : ["Cert"])
                        )
                      }
                    >
                      회원가입
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {/* PCver */}
        {/* <div className="nav_pc_ver">
          <div className="nav_pc_menu">
            <div onClick={() => onClickHandler("InfoOne", "pc")}>
              <span className="InfoOne">항공/숙박</span>
            </div>
            <div onClick={() => onClickHandler("SecureTravel", "pc")}>
              <span className="SecureTravel">안전여행</span>
            </div>
            <div onClick={() => onClickHandler("Coupon", "pc")}>
              <span className="Coupon">쿠폰</span>
            </div>
            <div onClick={() => onClickHandler("Event", "pc")}>
              <span className="Event">이벤트</span>
            </div> */}
        {/* <div
              className="nav_pc_menu_last"
              onClick={() => onClickHandler("Question", "pc")}
            >
              <span className="Question">문의하기</span>
            </div> */}
        {/* </div>
        </div> */}
      </header>
    </>
  );
};

export default React.memo(Header);
