// 공통요소
export const flightIcon =
  "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/RENEWAL/230808_flight_icon.png";

export const travellogo =
  "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/RENEWAL/230808_default_profile.png";

export const navSettingIcon =
  "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/RENEWAL/230808_nav_setting.png";

export const triangleIcon =
  "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/RENEWAL/0814_down_triangle.png";
// 안전여행 api
export const openApiSecure =
  "https://apis.data.go.kr/1262000/TravelAlarmService2/getTravelAlarmList2?serviceKey=Gh1ytdN41v%2FD4Pq3dEbN1gyVYtRJiO5USOtJoTVLtjMejsoofu4ftKw%2Fg1zFC73SS%2BfPnISwyMgyO1U6a3fB7Q%3D%3D&numOfRows=200";

export const searchAlarm = `https://apis.data.go.kr/1262000/TravelAlarmService2/getTravelAlarmList2?serviceKey=Gh1ytdN41v%2FD4Pq3dEbN1gyVYtRJiO5USOtJoTVLtjMejsoofu4ftKw%2Fg1zFC73SS%2BfPnISwyMgyO1U6a3fB7Q%3D%3D&cond[country_nm::EQ]=`;

export const openApiEmb =
  "https://apis.data.go.kr/1262000/LocalContactService2/getLocalContactList2?serviceKey=Gh1ytdN41v%2FD4Pq3dEbN1gyVYtRJiO5USOtJoTVLtjMejsoofu4ftKw%2Fg1zFC73SS%2BfPnISwyMgyO1U6a3fB7Q%3D%3D&cond[country_nm::EQ]=";

export const securePc = `/api/Links/securepc/read`;
