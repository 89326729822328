import React, { useState, useEffect } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper/core";
import "swiper/swiper-bundle.min.css";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import Loader from "../loader/LoaderComponent";
import { shallowEqual, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDeviceScreen } from "../../assets/js/util/useDeviceScreen";

SwiperCore.use([Pagination, Navigation, Autoplay]);

const SwiperSlider = () => {
  const user = useSelector((state) => state.users.user, shallowEqual);

  const [slides, setSlides] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const device = useDeviceScreen();
  const navigate = useNavigate();

  useEffect(() => {
    fetchSlides();
  }, []);
  const fetchSlides = async () => {
    try {
      const response = await axios.get("/api/banner/adverRead");
      const fetchedSlides = response.data
        .map((item) => ({
          image: item.image_path,
          alt: item.seq,
          source_url: item.source_url,
          login: item.login,
        }))
        .reverse(); // 배열 순서를 반전 (최신순으로)
      setSlides(fetchedSlides);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching slides:", error);
    }
  };

  const urlMove = (source_url) => {
    window.location.href = source_url;
  };

  const needLoginClickHandler = (url) => {
    if (user && user.isAuth) {
      urlMove(url);
    } else {
      navigate("/Login");
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <Swiper
        spaceBetween={10}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        onSwiper={(swiper) => {}}
        onSlideChange={() => {}}
      >
        {slides &&
          slides.map((slide, index) => (
            <SwiperSlide key={index}>
              {slide.login === "y" ? (
                <img
                  src={convertS3toCloudFront(slide.image)}
                  alt={slide.alt}
                  style={{
                    width: !(device === "pc") ? `90%` : `50%`,
                    minWidth: !(device === "pc") ? `90%` : `950px`,
                    maxWidth: "1200px",
                    height: "auto",
                    margin: "5% auto 5% auto",
                    display: "flex",
                    cursor: "pointer",
                    borderRadius: "20px",
                  }}
                  onClick={() => needLoginClickHandler(slide.source_url)}
                />
              ) : (
                <img
                  src={convertS3toCloudFront(slide.image)}
                  alt={slide.alt}
                  style={{
                    width: !(device === "pc") ? `90%` : `50%`,
                    minWidth: !(device === "pc") ? `90%` : `950px`,
                    maxWidth: "1200px",
                    height: "auto",
                    margin: "5% auto 5% auto",
                    display: "flex",
                    cursor: "pointer",
                    borderRadius: "20px",
                  }}
                  onClick={() => urlMove(slide.source_url)}
                />
              )}
            </SwiperSlide>
          ))}
      </Swiper>
    </>
  );
};

export default SwiperSlider;
