import React from "react";
import { Routes, Route } from "react-router-dom";
import EventComponent from "./component/Event/Event";
import Event_detail from "./component/Event/Event_detail";
import Links_detail from "./component/Link/Links_detail";
import Login from "./component/login/Login";
import SignForm from "./component/login/SignForm";
import SignFormCert from "./component/login/SignForm_cert";
import Main from "./component/main/Main";
import MainLayout from "./component/main/MainLayout";
import MyPage from "./component/myPage/MyPage";
import Shop from "./component/Shop/Shop";
import Terms from "./component/footer/Terms";
import TermsPri from "./component/footer/TermsPri";
import Coupon from "./component/Coupon/TravelCoupon";
import CouponDetail from "./component/Coupon/TravelCoupon_detail";
import SignFormCert2 from "./component/login/SignForm_cert2";
import Announ from "./component/Announ/Announ";
import AnnounDetail from "./component/Announ/Announ_detail";
import SecureTravel from "./component/SecureTravel/SecureTravel";
import InfoOne from "./component/InfoOne/InfoOne";
import MemoryComponent from "./component/Memory/MemoryComponent";
import NewMyPage from "./component/NewMyPage/NewMyPage";
import AdPopCorn from "./component/AdPopCorn/AdPopCorn";
import AdPopCornSignForm from "./component/AdPopCorn/AdPopCornSignForm";
import NoEvent from "./component/Event/EventCustom/NoEvent";
import RecommendTravelComponent from "./component/RecommendTravel/RecommendTravelComponent";
import CustomCouponPage from "./component/CustomCouponPage/CustomCouponPage";
import EdisonSignForm from "./component/Edison/EdisonSignForm";
import Edison from "./component/Edison/Edison";

function App() {
  return (
    <>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<Main />} />
          <Route path="/Links/:eventId" element={<Links_detail />} />
          <Route path="/Shop" element={<Shop />} />
          <Route path="/Event" element={<EventComponent />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/SignForm" element={<SignForm />} />
          <Route path="/Cert" element={<SignFormCert />} />
          <Route path="/Cert2" element={<SignFormCert2 />} />
          <Route path="/Mypage" element={<MyPage />} />
          <Route path="/Newmypage" element={<NewMyPage />} />
          <Route path="/Event/:eventId" element={<Event_detail />} />
          <Route path="/Terms" element={<Terms />} />
          <Route path="/TermsPri" element={<TermsPri />} />
          <Route path="/Coupon" element={<Coupon />} />
          <Route path="/Coupon/:couponId" element={<CouponDetail />} />
          <Route path="/Announ" element={<Announ />} />
          <Route path="/Announ/:announId" element={<AnnounDetail />} />
          <Route path="/SecureTravel" element={<SecureTravel />} />
          <Route path="/InfoOne" element={<InfoOne />} />
          <Route path="/MemoryComponent" element={<MemoryComponent />} />
          <Route path="/AdPopCorn" element={<AdPopCorn />} />
          <Route path="/AdPopCornSignForm" element={<AdPopCornSignForm />} />
          <Route path="/Edison" element={<Edison />} />
          <Route path="/EdisonSignForm" element={<EdisonSignForm />} />
          <Route
            path="/CustomCouponPage/:couponId"
            element={<CustomCouponPage />}
          />
          <Route
            path="/RecommectTravel/:travelId"
            element={<RecommendTravelComponent />}
          />
          <Route path="*" element={<NoEvent />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
