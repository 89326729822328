import React, { useState } from "react";
import Modal from "react-modal";
import "../../assets/scss/DomSamSungWarranty.scss";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import { useDeviceScreen } from "../../assets/js/util/useDeviceScreen";

function DomModalCautionDetail() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const device = useDeviceScreen();
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      <div className="warranty_detail_sec">
        <button onClick={openModal}>보장내용 확인하기</button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        appElement={document.getElementById("root")}
        style={{
          content: {
            margin: device === "mobile" ? "10% auto 0 auto" : "0 auto",
          },
        }}
      >
        <div className="warranty_detail_all">
          <button className="warranty_detail_closebtn_sec" onClick={closeModal}>
            <img
              className="warranty_detail_closebtn"
              src={convertS3toCloudFront(
                "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/menu_close.svg"
              )}
            />
          </button>
          <div>
            <h1>보장내용</h1>
            <table>
              <tbody>
                <tr>
                  <td>보장명</td>
                  <td>지급사유</td>
                  <td>지급금액</td>
                </tr>
                <tr>
                  <td>여행 중 상해사망</td>
                  <td>국내여행 중 상해의 직접결과로써 사망한 경우</td>
                  <td>보험가입금액</td>
                </tr>
                <tr>
                  <td>여행 중 상해후유장해</td>
                  <td>
                    국내여행 중 상해의 직접결과로써 장해지급률에 해당하는
                    장해상태가 되었을 경우
                  </td>
                  <td>보험가입금액 X (3~100%)</td>
                </tr>
                <tr>
                  <td>여행 중 질병사망 및 질병80%이상 고도후유장해 특별약관</td>
                  <td>
                    국내여행 중 질병으로 인하여 사망하거나 장해지급률이 80%
                    이상에 해당하는 장해상태가 되었을 경우
                  </td>
                  <td>보험가입금액</td>
                </tr>
                <tr>
                  <td>여행 중 교통상해입원일당 특별약관</td>
                  <td>
                    국내여행 중 교통사고로 상해를 입고 병원 또는 의원에 입원하여
                    치료를 받은 경우
                  </td>
                  <td>1일 가입금액 X 180일 한도</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="warranty_detail_bottom_closebtn_sec">
            <button
              className="warranty_detail_bottom_closebtn"
              onClick={closeModal}
            >
              닫기
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default DomModalCautionDetail;
