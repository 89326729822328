import React, { useEffect, useState } from "react";
import axios from "axios";
import SamsungFireComponent from "./EventCustom/SamsungFire/SamsungFireComponent";
import NoEvent from "./EventCustom/NoEvent";
import { useParams } from "react-router-dom";
import { toChange } from "../../assets/js/util/util";
import Container from "@mui/material/Container";
import withAuth from "../../hoc/auth";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import Loader from "../loader/LoaderComponent";
import SamsungAsianaComponent from "./EventCustom/SamsungFire/SamsungAsianaComponent";
import SamsungJejuComponent from "./EventCustom/SamsungFire/SamsungJejuComponent";
import SamsungHarpangComponent from "./EventCustom/SamsungFire/SamsungHarpangComponent";
import SamsungRainbowComponent from "./EventCustom/SamsungFire/SamsungRainbowComponent";
import HelmetComponent from "../header/HelmetComponent";

const EventDetail = () => {
  const { eventId } = useParams();
  const [detailEventData, setDetailEventData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`/api/events/eventsDetail/${eventId}`)
      .then(res => {
        if (res.data.success) {
          setDetailEventData([res.data.data]);
        } else {
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const adminComponent = data => {
    let changeDate = toChange(data.created_at);
    return (
      <Container
        fixed
        style={{ marginBottom: "20%", paddingLeft: "7%", paddingRight: "7%" }}
      >
        <div>
          <h1>{data.title}</h1>
          <h2>{changeDate}</h2>
        </div>
        <div>
          <img src={convertS3toCloudFront(data.image_path)}></img>
        </div>
      </Container>
    );
  };

  const customComponent = data => {
    let component;
    switch (data.title) {
      case "삼성화재":
        component = <SamsungFireComponent data={data} />;
        break;
      case "트래블링크 X 아시아나항공":
        component = <SamsungAsianaComponent data={data} />;
        break;
      case "트래블링크 X 제주투어패스":
        component = <SamsungJejuComponent data={data} />;
        break;
      case "트래블링크 X 돌하루팡":
        component = <SamsungHarpangComponent data={data} />;
        break;
      case "트래블링크 X 무지개렌트카":
        component = <SamsungRainbowComponent data={data} />;
        break;
      default:
        component = <NoEvent />;
    }

    return <>{component}</>;
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <HelmetComponent title="Event 상세보기" />
      {!detailEventData[0] && (
        <>
          <NoEvent />
        </>
      )}
      {detailEventData &&
        detailEventData[0] &&
        (detailEventData[0].category === "admin"
          ? adminComponent(detailEventData[0])
          : customComponent(detailEventData[0]))}
    </>
  );
};

export default withAuth(EventDetail, null);
