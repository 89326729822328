import React from "react";
import "../../../assets/scss/NoEvent.scss";
import { convertS3toCloudFront } from "../../../assets/js/util/proxy";
import { useNavigate } from "react-router-dom";
const NoEvent = () => {
  const navigate = useNavigate();
  const onClickHandler = () => {
    navigate("/");
  };
  return (
    <>
      <div className="noevent_bg">
        <div style={{ position: "relative" }}>
          <div className="noevent_logo_sec">
            <img
              className="noevent_logo"
              src={convertS3toCloudFront(
                "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/RENEWAL/230822_mainlogo.png"
              )}
              onClick={() => onClickHandler()}
            />
          </div>
          <div className="noevent_text_sec">
            <p>해당 페이지를 찾지 못했습니다.</p>
            <p>URL이 잘못되었거나 더 이상 제공하지 않는 페이지입니다.</p>
            <p>
              아래 버튼 혹은 트래블링크 로고를 눌러 메인페이지로 이동해주세요!
            </p>
            <div className="noevent_button_sec">
              <button
                className="noevent_button"
                onClick={() => onClickHandler()}
              >
                메인페이지로 이동 --&gt;
              </button>
            </div>
            <img
              className="noevent_avocado"
              src={convertS3toCloudFront(
                "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/avocado-1113_512.gif"
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(NoEvent);
