import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { UserService } from "../service/UserService";

const withAuth = (Component, option) => {
  function AuthenticatedComponent(props) {
    const isLoggedIn = useSelector((state) => {
      return state.users.user.isAuth;
    });

    // null, 아무나 출입가능한 페이지 , 나머지 대시보드
    // false, 로그인한 유저만 출입가능 ,마이페이지
    // true, 로그인 안한 유저만 출입가능 , 회원가입, 로그인
    let navigate = useNavigate();
    const dispatch = useDispatch();
    // dispatch(UserService.getUserAuth()); // 여기서 보낸 데이터는payload로 들어감
    //그렇다면 비동기처리는?

    useEffect(() => {
      dispatch(UserService.getUserAuth()).then((res) => {
        // //로그인한 유저
        if (res.payload.isAuth) {
          //로그인 한 유저는 접근 할 수 없으니 메인페이지로 안내.
          if (option === true) {
            // alert(
            // "로그인한 유저는 접근 할 수없습니다. 메인페이지로 안내합니다."
            // );
            navigate("/");
          }
        } else {
          //로그인 안한 유저
          if (option === false) {
            //로그인 한 유저만 접근 할 수 있기에 로그인페이지로 안내
            // alert(
            // "로그인안 한 유저는 접근 할 수 없습니다. 로그인페이지로 안내합니다."
            // );
            navigate("/Login");
          }
        }
      });
    }, [isLoggedIn]);

    return <Component {...props} />;
  }
  return AuthenticatedComponent;
};
export default withAuth;
