import React, { useEffect, useState, useMemo } from "react";
import "../../assets/scss/Announ_table.scss";
import { useNavigate } from "react-router-dom";
import { toChange2 } from "../../assets/js/util/util";

function AnnounTable({ contentType, entireData }) {
  const [selectedType, setSelectedType] = useState("All");
  const navigate = useNavigate();
  const moveDetail = (id) => {
    navigate(`/Announ/${id}`);
  };
  useEffect(() => {
    if (contentType !== null) {
      setSelectedType(contentType);
    }
  }, [contentType]);

  const renderContent = useMemo(() => {
    if (entireData.length === 0) {
      return <p>공지사항이 없습니다</p>;
    }
    return (
      <table className="announ_table">
        <thead>
          <tr className="announ_tr">
            <th>NO</th>
            <th>분류</th>
            <th>공지사항</th>
            <th>공지일</th>
          </tr>
        </thead>
        <tbody>
          {entireData.map((item, key) => {
            return (
              <tr key={item.SEQ} className="announ_tr">
                <td>{item.PAGE_NO}</td>
                <td>
                  {item.CATEGORY === "0"
                    ? "서비스"
                    : item.CATEGORY === "1"
                    ? "이벤트"
                    : "기타"}
                </td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => moveDetail(item.SEQ)}
                >
                  {item.TITLE}
                </td>
                <td>{toChange2(item.created_at)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  });

  return <>{renderContent}</>;
}
export default AnnounTable;
