import React from "react";
import "../../assets/scss/MypageCouponComponentModal.scss";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";

function MainCouponComponentModal({
  isModalOpen,
  toggleModal,
  selectedCoupon,
}) {
  return (
    <>
      {isModalOpen && (
        <div className="mypage_coupon_barcode_modal">
          <p className="mypage_coupon_barcode_modal_ko">
            직원분께 아래의 쿠폰 이미지를 제시해주세요.
          </p>
          <p className="mypage_coupon_barcode_modal_en">
            Please present the coupon image{" "}
          </p>
          <p className="mypage_coupon_barcode_modal_en">
            below to the employee.
          </p>
          <div className="mypage_barcode_modal_sec">
            {selectedCoupon && (
              <div className="mypage_coupon_modal_sec">
                <img
                  className="mypage_coupon_original_img"
                  src={convertS3toCloudFront(
                    selectedCoupon.coupon_original_img
                  )}
                />
                {selectedCoupon.barcode && (
                  <>
                    <p className="mypage_coupon_barcode_modal_en2">
                      Barcode Image
                    </p>
                    <img
                      className="mypage_barcode_modal_img"
                      src={convertS3toCloudFront(selectedCoupon.barcode)}
                    />
                  </>
                )}
              </div>
            )}
          </div>
          <div className="mypage_coupon_modal_close">
            <button onClick={toggleModal}>닫기</button>
          </div>
        </div>
      )}
    </>
  );
}

export default React.memo(MainCouponComponentModal);
