import React, { useEffect, useState } from "react";
import axios from "axios";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import MainTitleComponent from "../main/MainTitleComponent";
import { urlMove } from "../../assets/js/util/navigation";
import "../../assets/scss/SecureTravel_Insurance.scss";

const SecureTravelInsurance = () => {
  const [activeContent, setActiveContent] = useState("DOM");
  const [secureData, setSecureData] = useState([]);

  const handleClick = (content) => {
    setActiveContent(content);
  };

  useEffect(() => {
    axios.get("/api/secure/readall").then((res) => {
      setSecureData(res.data);
    });
  }, []);

  return (
    <>
      <div className="securetravel_insurance_mainbanner">
        {secureData &&
          secureData
            .filter((item) => item.CATEGORY2 === "MAIN")
            .map((item, key) => (
              <img
                key={key}
                src={convertS3toCloudFront(item.IMAGE_PATH)}
                alt={convertS3toCloudFront(item.FILENAME)}
              />
            ))}
      </div>
      {/*  <button
      <div className="securetravel_insurance_category">
          className={`securetravel_insurance_category_btn ${
            activeContent === "DOM" ? "clicked" : ""
          }`}
          onClick={() => handleClick("DOM")}
        >
          국내
        </button>
         <button
          className={`securetravel_insurance_category_btn ${
            activeContent === "OVER" ? "clicked" : ""
          }`}
          onClick={() => handleClick("OVER")}
        >
          해외
        </button> 
      </div>*/}
      <div className="infoone_FL_sec">
        <MainTitleComponent
          title="보험 Link"
          divClass="infoone_FL_title_sec"
          pClass="infoone_FL_title"
        />
        <div className="secure_insurance_Link_sec_all">
          <div className="secure_insurance_Link_sec">
            {secureData &&
              secureData
                .filter(
                  (item) =>
                    item.CATEGORY1 === activeContent && item.CATEGORY2 === "SUB"
                )
                .map((item, key) => (
                  <React.Fragment key={key}>
                    <div className="secure_insurance_Link_sec_temp">
                      <div
                        className="secure_insurance_Link_left"
                        onClick={() => {
                          if (window.fbq) {
                            if (item.TITLE === "CHUBB") {
                              window.fbq(
                                "trackCustom",
                                "TvInsuSecureCHUBB",
                                {}
                              );
                            } else if (item.TITLE === "메리츠") {
                              window.fbq(
                                "trackCustom",
                                "TvInsuSecureMERITZ",
                                {}
                              );
                            }
                          }

                          if (window.gtag) {
                            if (item.TITLE === "CHUBB") {
                              window.gtag("event", "TvInsuSecureCHUBB");
                            } else if (item.TITLE === "메리츠") {
                              window.gtag("event", "TvInsuSecureMERITZ");
                            }
                          }
                          urlMove(item.SOURCE_URL);
                        }}
                      >
                        <img
                          className="secure_insurance_Link_img"
                          src={convertS3toCloudFront(item.IMAGE_PATH)}
                        />
                        <div className="secure_insurance_Link_text">
                          <div className="secure_insurance_Link_text1">
                            <p>{item.TITLE}</p>
                          </div>
                          <div className="secure_insurance_Link_text2">
                            <p>{item.DESCRIPTION}</p>
                          </div>
                          <div className="secure_insurance_Link_text3">
                            <p>{item.DESCRIPTION2}</p>
                          </div>
                        </div>
                      </div>
                      <div
                        onClick={() => urlMove(item.SOURCE_URL)}
                        className="secure_insurance_Link_Link"
                      >
                        <img
                          src={convertS3toCloudFront(
                            "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/RENEWAL/230812_link.png"
                          )}
                        />
                        <p>Link</p>
                      </div>
                    </div>
                    <hr />
                  </React.Fragment>
                ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(SecureTravelInsurance);
