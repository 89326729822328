import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import withAuth from "../../hoc/auth";
import HelmetComponent from "../header/HelmetComponent";
import { useSelector } from "react-redux";
import RecommendModal from "./RecommendModal";
import { convertS3toCloudFront } from "../../assets/js/util/proxy";
import "../../assets/scss/RecommendTravelComponent.scss";
import Button from "../customButton/Button";
import { useDeviceScreen } from "../../assets/js/util/useDeviceScreen";
import GiftImageSlider from "./GiftImageSlider";

const RecommendTravelComponent = () => {
  const { travelId } = useParams();
  const user = useSelector(state => state.users.user);
  const device = useDeviceScreen();

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [recommendData, setRecomendData] = useState([]);
  const [bottomData, setBottomData] = useState([]);

  function makeComma(price) {
    return price.toLocaleString("ko-KR") + "원";
  }
  // 해당 데이터 렌더링
  useEffect(() => {
    axios
      .get(`/api/recommendProduct/getRecommendProduct/${travelId}`)
      .then(res => {
        if (res.data.message === "데이터 조회실패") {
          alert("해당 상품은 없는 상품 페이지입니다.");
          navigate("/");
        }
        if (res.status === 200) {
          setRecomendData([res.data.data]);
          setBottomData([res.data.data.BOTTOM_IMG]);
        }
      })
      .catch(err => {
        console.error(err);
        alert("알수없는 에러가발생 했습니다. \n 관리자에게 문의해주세요.");
      });
  }, []);

  const closeHandler = () => {
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <RecommendModal
          user={user}
          title={recommendData[0].TITLE}
          seq={recommendData[0].SEQ}
          device={device}
          closeHandler={closeHandler}
          startDate={
            recommendData[0].START_DATE.length === 0
              ? null
              : recommendData[0].START_DATE
          }
          dueDate={recommendData[0].DUE_DATE}
        />
      )}
      <HelmetComponent title="추천여행 상세보기" />
      <div className="recommend_detail_sec">
        {recommendData[0] && (
          <div>
            <div>
              <div className="recommend_detail_up_sec">
                <GiftImageSlider data={recommendData[0].TOP_IMG} />
                <div className="recommend_detail_main_right">
                  <div
                    style={{
                      borderBottom: "1px solid #e5e5e5",
                      color: "gray",
                      marginBottom: "3%",
                      textAlign: "right",
                      paddingBottom: "3%",
                    }}
                  >
                    <span>{recommendData[0].COMPANY}</span>
                  </div>
                  <div>
                    <p className="recommend_detail_main_right_title">
                      {recommendData[0].TITLE}
                    </p>
                    <p className="recommend_detail_main_right_expl">
                      {recommendData[0].SUBTITLE}
                    </p>
                  </div>
                  <div>
                    <div style={{ marginBottom: "1%" }}>
                      {recommendData[0].DISCOUNT !== 0 && (
                        <span style={{ fontSize: "14px" }}>
                          {recommendData[0].DISCOUNT}%
                        </span>
                      )}
                      {recommendData[0].DISCOUNT_PRICE !== 0 && (
                        <span
                          style={{
                            textDecoration: "line-through",
                            color: "#888",
                          }}
                        >
                          {makeComma(recommendData[0].DISCOUNT_PRICE)}
                        </span>
                      )}
                    </div>
                    <div style={{ marginBottom: "3%" }}>
                      <strong
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {makeComma(recommendData[0].PRICE)} ~
                      </strong>
                    </div>
                  </div>
                  <div className="recommend_detail_use_btn_sec">
                    <Button
                      device={device}
                      onClick={() => setShowModal(!showModal)}
                      text="상담 하기"
                      style={{ width: "100%", height: "40px" }}
                      color="s"
                    />
                  </div>
                  <div>
                    <p className="recommend_detail_use_btn_sec_p">
                      버튼을 누르면 상담 예약을 진행할 수 있는 창이 나옵니다.
                    </p>
                  </div>
                </div>
              </div>
              <div className="recommend_detail_page_boundary">
                상품 상세 설명
              </div>
              <div className="recommend_detail_page_img">
                {bottomData[0].length !== 0
                  ? bottomData[0].map((item, key) => {
                      return (
                        <img
                          key={key}
                          src={convertS3toCloudFront(item.IMAGE_PATH)}
                        />
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default withAuth(RecommendTravelComponent, null);
