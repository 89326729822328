import React, { useEffect, useRef, useState } from "react";
import DomSamSungModal from "../../DomSamSungModal";
import "../../../../assets/scss/SamsungJejuComponent.scss";
import { toChange, toChange2 } from "../../../../assets/js/util/util";
import Container from "@mui/material/Container";
import { shallowEqual, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SamSungModalX from "../../SamSungModalX";
import { convertS3toCloudFront } from "../../../../assets/js/util/proxy";

const SamsungAsianaComponent = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDomModalOpen, setIsDomModalOpen] = useState(false);
  const navigate = useNavigate();

  const user = useSelector(state => state.users.user, shallowEqual);

  const [alreadyUser, setAlreadyUser] = useState(false);

  const [notAgreeInsurance, setNotAgreeInsurance] = useState([]);
  const [agreeStatus, setAgreeStatus] = useState(false);

  useEffect(() => {
    if (user.name && user.phone) {
      let body = {
        title: data.title,
        name: user.name,
        phone: user.phone,
      };

      axios.post("/api/insurance/alreadyUser", body).then(res => {
        if (alreadyUser !== res.data.success) {
          setAlreadyUser(res.data.success);
        }
      });
    }
  }, [user.name, user.phone, data.title]);
  useEffect(() => {
    if (user.isAuth && user.id) {
      try {
        axios.get("/api/insurance/readAllInsurance").then(res => {
          //데이터가있을때만
          const data = res.data.data;
          const notAgreeInsuranceArr = [];
          for (const key in data) {
            if (!data[key]) {
              notAgreeInsuranceArr.push(key);
            }
          }
          //다 동의했으면
          setNotAgreeInsurance(notAgreeInsuranceArr);
        });
      } catch (err) {
      } finally {
      }
    }
  }, [user, agreeStatus]);

  useEffect(() => {
    if (isOpen || isDomModalOpen) {
      window.scrollTo(0, 0);
    }
  }, [isOpen, isDomModalOpen]);

  const toggleDomesticModal = () => {
    const isAuth = user?.isAuth ?? false;
    if (isAuth) {
      if (!alreadyUser) {
        alert("이미 참여하셨습니다.");
      } else {
        setIsDomModalOpen(!isDomModalOpen);
      }
    } else {
      alert(`로그인한 유저만 접근가능합니다. 로그인 페이지로 이동합니다.`);
      navigate("/Login");
    }
  };

  let changeDate = toChange2(data.created_at);
  return (
    <>
      <Container
        fixed
        style={{ marginBottom: "20%", paddingLeft: "7%", paddingRight: "7%" }}
      >
        <div className="jeju_event_detail_title">
          <h1>{data.title}</h1>
          <h2>{changeDate}</h2>
        </div>
        <div style={{ position: "relative" }}>
          <img
            style={{ maxWidth: "100%", height: "auto" }}
            src={convertS3toCloudFront(data.image_path)}
          ></img>
          <div className="samsung_jeju_number">
            <p>삼성화재 여행자 보험 보상 관련 문의 : 1800-9010</p>
          </div>
          <div className="event_jeju_btnmes">
            <p>국내 여행자 보험은</p>
            <p> "당일, 여행지"</p>
            <p>에서도 가입이 가능합니다.</p>
          </div>
          <div>
            <div
              className="event_jeju_detail_dombtn"
              onClick={toggleDomesticModal}
            >
              <p>지금 신청하러 가기</p>
            </div>
          </div>
        </div>
      </Container>

      {isDomModalOpen &&
        (notAgreeInsurance.length > 0 ? (
          <SamSungModalX
            setAgreeStatus={setAgreeStatus}
            notAgreeInsurance={notAgreeInsurance}
            setIsOpen={setIsDomModalOpen}
          ></SamSungModalX>
        ) : (
          <DomSamSungModal
            user={user}
            title={data.title}
            className="Sec_dom_samsungmodal"
          >
            <div className="jeju_modal_close_btn">
              <button onClick={toggleDomesticModal}>
                <img
                  className="jeju_modal_close_img"
                  src={convertS3toCloudFront(
                    "https://s3-travellink.s3.ap-northeast-2.amazonaws.com/HOMPAGE_IMG_SOURCE/menu_close.svg"
                  )}
                />
              </button>
            </div>
          </DomSamSungModal>
        ))}
    </>
  );
};

export default React.memo(SamsungAsianaComponent);
