import { useEffect, useState } from "react";

export const useDeviceScreen = () => {
  const [device, setDevice] = useState("");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 430) {
        setDevice("mobile");
      } else if (width > 430 && width <= 1023) {
        setDevice("tablet");
      } else {
        setDevice("pc");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return device;
};
