import React from "react";
import InfoOneHeaderButton from "./InfoOneHeaderButton";
import "../../assets/scss/InfoOneHeader.scss";

const InfoOneHeader = ({ handleClick, activeContent }) => {
  return (
    <div className="infoone_maincategory_sec">
      <InfoOneHeaderButton
        title="항공"
        handleClick={handleClick}
        activeContent={activeContent}
        compareContent="flight"
      />
      <InfoOneHeaderButton
        title="숙박"
        handleClick={handleClick}
        activeContent={activeContent}
        compareContent="rodge"
      />
      <InfoOneHeaderButton
        title="패키지"
        handleClick={handleClick}
        activeContent={activeContent}
        compareContent="package"
      />
      <InfoOneHeaderButton
        title="환전"
        handleClick={handleClick}
        activeContent={activeContent}
        compareContent="exchange"
      />
    </div>
  );
};

export default React.memo(InfoOneHeader);
